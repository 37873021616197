import { findLastIndex } from "lodash";
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  GET_USERLIST,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_ERROR,
  DELETE_USER_BY_ID,
  DELETE_USER_BY_ID_SUCCESS,
  DELETE_USER_BY_ID_ERROR,
} from "../actions";

const INIT_STATE = {
  user: localStorage.getItem("user_id"),
  firstName: localStorage.getItem("firstName"),
  secondName: localStorage.getItem("secondName"),
  roles: JSON.parse(localStorage.getItem("roles") || "[]"),
  photo: localStorage.getItem("photo"),
  forgotUserMail: "",
  newPassword: "",
  resetPasswordCode: "",
  loading: false,
  userList: [],

  error: "",
  updatePasswordError: "",
  loadingUserList: true,
  loadingUserListError: false,
  updatePasswordSuccess: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.uid,
        firstName: action.payload.firstName,
        secondName: action.payload.secondName,
        roles: action.payload.roles,
        photo: action.payload.photo,
        error: "",
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: action.payload.message,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: "" };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: "",
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: "",
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: "" };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: "",
        error: "",
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: "",
        resetPasswordCode: "",
        error: action.payload.message,
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: "" };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.uid, error: "" };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: action.payload.message,
      };
    case LOGOUT_USER:
      return { ...state, user: null, error: "" };

    case UPDATE_USER:
      return { ...state, loading: true, error: "" };

    case UPDATE_USER_SUCCESS:
      if (action.payload.ownUser) {
        return {
          ...state,
          loading: false,
          user: action.payload.user.uid,
          firstName: action.payload.user.firstName,
          secondName: action.payload.user.secondName,
          roles: action.payload.user.roles,
          photo: action.payload.user.photo,
          error: "",
        };
      } else {
        return {
          ...state,
          error: "",
        };
      }
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case UPDATE_PASSWORD:
      return {
        ...state,
        loading: true,
        updatePasswordError: "",
        updatePasswordSuccess: "",
      };

    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePasswordSuccess: action.payload.message,
      };
    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        updatePasswordError: action.payload.message,
      };

    case GET_USERLIST:
      return {
        ...state,
        userList: [],
        loadingUserList: true,
        loadingUserListError: false,
      };
    case GET_USERLIST_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        loadingUserList: false,
      };

    case GET_USERLIST_ERROR:
      return {
        ...state,
        loadingUserList: false,
        loadingUserListError: action.payload.message,
      };

    case DELETE_USER_BY_ID:
      return {
        ...state,
      };
    case DELETE_USER_BY_ID_SUCCESS:
      let UserList = [...state.userList];
      const delIndex = UserList.findIndex(
        (element) => element.email === action.payload.id
      );
      UserList.splice(delIndex, 1);
      return {
        ...state,
        userList: UserList,
      };
    case DELETE_USER_BY_ID_ERROR:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};
