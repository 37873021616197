const Step = {
    instanceId: "",
    templateId: "32dbcd64676a4725a317c71faa7f554e",
    title: "Step",
    imageUrl: "http://159.69.37.15:5010/db/static/assets/uploads/placeholder.jpg",
    identifier: "StepTemplate",
    text: "TimeSpend",
    properties: [
      // 0
      {
        propertyName: "StepTemplate",
        dataType: "SINGLE_2babe4ed09564eec81669e9b72026150", // expects normal StepTemplate, but can also be SondernummernStepTemplate
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "StepTemplate",
        accessMode: "automaticw",
        index: "",
      },
      // 1
      {
        propertyName: "TimeSpend",
        dataType: "INTEGER",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 2
      {
        propertyName: "Arbeitszeiten",
        dataType: "SINGLE_2babe4ed09564eec81669e9b72026150",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 3
      {
        propertyName: "Status",
        dataType: "TEXT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "open",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 4
      {
        propertyName: "Abgeschlossen_am",
        dataType: "DATE",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 5
      {
        propertyName: "Factor",
        dataType: "INT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "1",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 6
      {
        propertyName: "estimatedtime",
        dataType: "INT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 7
      {
        propertyName: "istSondernummer",
        dataType: "BOOLEAN",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 8
      {
        propertyName: "Arbeitspaket",
        dataType: "INT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 9
      {
        propertyName: "Step",
        dataType: "INT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 10
      {
        propertyName: "Option",
        dataType: "TEXT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 11
      {
        propertyName: "Related_Manual",
        dataType: "TEXT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 12
      {
        propertyName: "Durchschnittl_Zeit",
        dataType: "TEXT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 13
      {
        propertyName: "Beschreibung",
        dataType: "TEXT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 14
      {
        propertyName: "TestProtocol",
        dataType: "TEXT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 15
      {
        propertyName: "PositionInArbeitspaket",
        dataType: "TEXT",
        propertyId: "32dbcd64676a4725a317c71faa7f554e",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
    ],
  };
  
  export default Step;
  