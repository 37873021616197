import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { auth } from "../../helpers/Firebase";
import * as api from "../../api";
import * as leicaServiceApi from "../../leicaServiceApi";
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER,
  UPDATE_PASSWORD,
  GET_USERLIST,
  DELETE_USER_BY_ID,
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  updateUserSuccess,
  updateUserError,
  updatePasswordSuccess,
  updatePasswordError,
  getUserListSuccess,
  getUserListError,
  deleteUserSuccess,
  deleteUserError,
} from "./actions";

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

/**
 * SAJ specific authentication.
 *
 * @param email
 * @param password
 * @returns {Promise<void>}
 */
const loginWithEmailPasswordAsync = async (email, password) => {
  // EVA backend login

  const tokenResponse = await api // leicaServiceApi // TO DO: replace with comment once issues with new login endpoint are fixed
    .postData("/users/login", {
      // .postData("/api/login", {
      username: email,
      password: password,
    })
    .then((res) => {
      return res.json();
    });

  if (tokenResponse.message) {
    return tokenResponse;
  }

  const access_token = tokenResponse["access_token"];
  const refresh_token = tokenResponse["refresh_token"];

  if (access_token && refresh_token) {
    localStorage.setItem("user", email);
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
  }

  const userInfo = await api
    .getData("/users/info", { token: access_token })
    .then((res) => {
      return res.status === 200 && res.json();
    });

  localStorage.setItem("firstName", userInfo.first_name);
  localStorage.setItem("secondName", userInfo.second_name);
  localStorage.setItem("roles", JSON.stringify(userInfo.roles));
  localStorage.setItem("photo", userInfo.image);

  return {
    user: {
      uid: email,
      firstName: userInfo.first_name,
      secondName: userInfo.second_name,
      roles: userInfo.roles,
      photo: userInfo.image,
    },
  };
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      localStorage.setItem("user_id", loginUser.user.uid);
      yield put(loginUserSuccess(loginUser.user));
      history.push("/");
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) => {
  // await auth.createUserWithEmailAndPassword(email, password)
  //   .then(authUser => authUser)
  //   .catch(error => error);
};

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      localStorage.setItem("user_id", registerUser.user.uid);
      yield put(registerUserSuccess(registerUser));
      history.push("/");
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  // await auth.signOut().then(authUser => authUser).catch(error => error);
  history.push("/");
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem("user_id");
  } catch (error) {}
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // return await auth.sendPasswordResetEmail(email)
  //   .then(user => user)
  //   .catch(error => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess("success"));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
  //   .then(user => user)
  //   .catch(error => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

function* updateUser(userData) {
  let userRoles = [];
  Object.keys(userData.payload.user.roles).forEach((element) => {
    if (userData.payload.user.roles[element]) {
      userRoles.push(element);
    }
  });

  userData.payload.user.roles = userRoles;

  //build UserData Object
  const putData = {
    userId: userData.payload.user.email,
    first_name: userData.payload.user.firstName,
    second_name: userData.payload.user.secondName,
    photo: userData.payload.user.photo,
    roles: userData.payload.user.roles,
  };

  const putUserDataStatus = yield api
    .putData("/users/", putData)
    .then((res) => {
      return res.status;
    });
  if (putUserDataStatus === 204) {
    if (userData.payload.user.email === localStorage.getItem("user")) {
      localStorage.setItem("user", userData.payload.user.email);
      localStorage.setItem("firstName", userData.payload.user.firstName);
      localStorage.setItem("secondName", userData.payload.user.secondName);
      localStorage.setItem(
        "roles",
        JSON.stringify(userData.payload.user.roles)
      );
      localStorage.setItem("photo", userData.payload.user.photo);

      yield put(
        updateUserSuccess({
          ownUser: true,
          user: {
            uid: userData.payload.user.email,
            firstName: userData.payload.user.firstName,
            secondName: userData.payload.user.secondName,
            roles: userData.payload.user.roles,
            photo: userData.payload.user.photo,
          },
        })
      );
    } else {
      updateUserSuccess({
        ownUser: false,
      });
    }
  } else {
    yield put(updateUserError({ message: "Couldnt Update User" }));
  }
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePassword);
}

function* updatePassword(payload) {
  let user = localStorage.getItem("user");
  const response = yield api
    .postData("/users/update_password", {
      email: user,
      old_password: payload.payload.currentpassword,
      new_password: payload.payload.newpassword,
    })
    .then((res) => {
      if (res.status !== 201) {
        return res.json();
      }
    });

  if (typeof response === "undefined") {
    const loginUser = yield call(
      loginWithEmailPasswordAsync,
      user,
      payload.payload.newpassword
    );
    if (!loginUser.message) {
      localStorage.setItem("user_id", loginUser.user.uid);
      yield put(updatePasswordSuccess("Password Successfully Updated"));
    }
  } else {
    yield put(updatePasswordError(response.message));
  }
}

export function* watchGetUserList() {
  yield takeEvery(GET_USERLIST, getUserList);
}

function* getUserList() {
  const userlist = yield api.getData("/users/").then((res) => {
    if (res.status === 200) {
      return res.json();
    }
  });
  if (typeof userlist === "undefined") {
    yield put(getUserListError("Cant retrieve Users"));
  } else {
    let botIndex = userlist.findIndex(
      (element) => element.email === "bot@susiandjames.com"
    );
    userlist.splice(botIndex, 1);
    yield put(getUserListSuccess(userlist));
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER_BY_ID, deleteUser);
}

function* deleteUser(payload) {
  const response = api
    .getData("/users/delete/" + payload.payload.userId)
    .then((res) => {
      if (res.status === 204) {
        return true;
      }
    });
  if (response) {
    yield put(deleteUserSuccess(payload.payload.userId));
  } else {
    yield put(deleteUserError());
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchUpdateUser),
    fork(watchUpdatePassword),
    fork(watchGetUserList),
    fork(watchDeleteUser),
  ]);
}
