const ArbeitszeitTemplate = {
  instanceId: "",
  templateId: "097deee8bfbf4b8abea13f3f09284174",
  imageUrl: "http://159.69.37.15:5010/db/static/assets/uploads/placeholder.jpg",
  title: "Arbeitszeiten",
  identifier: "Startdatum",
  text: "Enddatum",
  properties: [
    {
      propertyId: "96c0ebe841fb44278712603e24620d13",
      propertyName: "Startdatum",
      propertyValue: "",
      dataType: "DATE",
      formula: "",
      displayName: "",
      index: "",
      accessMode: "automaticw",
    },
    {
      propertyId: "6a8c7a3eaf06463084e3b89d1ecc978c",
      propertyName: "Enddatum",
      propertyValue: "",
      dataType: "DATE",
      formula: "",
      displayName: "",
      index: "",
      accessMode: "automaticw",
    },
    {
      propertyId: "679f3db496b743fc9feadb9f6d4d41f9",
      propertyName: "Arbeitszeit",
      propertyValue: "",
      dataType: "INTEGER",
      formula: "",
      displayName: "",
      index: "",
      accessMode: "automaticw",
    },
  ],
};
export default ArbeitszeitTemplate;
