const checklistTemplate = {
    instanceId: "",
    templateId: "021a22690f044896b9243e62a5c2e15a",
    title: "Checklist",
    imageUrl: "http://159.69.37.15:5010/db/static/assets/uploads/placeholder.jpg",
    identifier: "Workshares",
    text: "Startdatum",
    properties: [
      {
        propertyName: "Startdatum",
        dataType: "DATE",
        propertyId: "021a22690f044896b9243e62a5c2e15a",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "Steps",
        dataType: "LIST_OF_32dbcd64676a4725a317c71faa7f554e",
        propertyId: "021a22690f044896b9243e62a5c2e15a",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "HoldTimes",
        dataType: "LIST_OF_300c7bda0d7e42bfa59adcdc780aa6b8",
        propertyId: "021a22690f044896b9243e62a5c2e15a",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "Stoerzeiten",
        dataType: "LIST_OF_c0ab1c843ca9473ead4554e246779060",
        propertyId: "021a22690f044896b9243e62a5c2e15a",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // {
      //   propertyName: "CustomerExpectanceDate",
      //   dataType: "DATE",
      //   propertyId: "021a22690f044896b9243e62a5c2e15a",
      //   propertyValue: "",
      //   formula: "",
      //   displayName: "",
      //   accessMode: "automaticw",
      //   index: "",
      // },
      // {
      //   propertyName: "LatestPossibleCompletionDate",
      //   dataType: "DATE",
      //   propertyId: "021a22690f044896b9243e62a5c2e15a",
      //   propertyValue: "",
      //   formula: "",
      //   displayName: "",
      //   accessMode: "automaticw",
      //   index: "",
      // },
      {
        propertyName: "LastCompletedWorkpackage",
        dataType: "INTEGER",
        propertyId: "021a22690f044896b9243e62a5c2e15a",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "Workshares",
        dataType: "TEXT",
        propertyId: "021a22690f044896b9243e62a5c2e15a",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "Workstation",
        dataType: "SINGLE_1c10a7e19ce14d1fbaf3ae93ff0cec56",
        propertyId: "021a22690f044896b9243e62a5c2e15a",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "Enddatum",
        dataType: "DATE",
        propertyId: "021a22690f044896b9243e62a5c2e15a",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "AssignedEmployee",
        dataType: "SINGLE_7af105c5b5644688941283db3e54b117",
        propertyId: "f198fc4592c54a8e8879e315c4360b52",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
    ]
  };
  
  export default checklistTemplate;
  