const factoryboardBasicColors = {
  // same color as used in the queue per default (which cannot be changed at this point in time)
  blue: "rgb(16, 102, 168)",
  // copied from gogostyle css sheet and saj theme sheet
  green: "#576a3d", // theme-color-green
  orange: "#f9a550", // gradient-color-3
  red: "#e7523e", // theme-color-2
  // lightened versions of above colors for progess bar
  lightgreen: "#91ab6d",
  lightorange: "#fcd9b5",
  grey: "#A9A9A9",
  midlightgrey: "#e6e6e6",
  lightgrey: "#eeeeee",
  verylightgrey: "#f5f5f5",
  offwhite: "#f9f9f9",
  coral: "#F98A8A",
  pink: "pink",
  lightpink: "#ffeaed"
};

const factoryboardColors = {
  ...factoryboardBasicColors,
  checklistNotStarted: factoryboardBasicColors.blue,
  checklistConflicting: factoryboardBasicColors.red,
  checklistOnSchedule: factoryboardBasicColors.lightgreen,
  checklistDelayed: factoryboardBasicColors.orange,
  geparkt: factoryboardBasicColors.grey,
  hold: factoryboardBasicColors.grey,
  parentRow: factoryboardBasicColors.midlightgrey,
  absence: factoryboardBasicColors.coral,
  today: factoryboardBasicColors.pink,
  thisWeek: factoryboardBasicColors.lightpink,
  weekend: factoryboardBasicColors.verylightgrey,
  unevenRowIdx: factoryboardBasicColors.offwhite,

};

export default factoryboardColors;
