import {
  LEICA_GET_ADMINDASHBOARD_SETTINGS,
  LEICA_GET_ADMINDASHBOARD_SETTINGS_ERROR,
  LEICA_GET_ADMINDASHBOARD_SETTINGS_SUCCESS,
  LEICA_SET_ADMINDASHBOARD_SETTINGS,
  LEICA_SET_ADMINDASHBOARD_SETTINGS_ERROR,
  LEICA_SET_ADMINDASHBOARD_SETTINGS_SUCCESS,
  LEICA_GET_PARTTEMPLATES,
  LEICA_GET_PARTTEMPLATES_ERROR,
  LEICA_GET_PARTTEMPLATES_SUCCESS,
  LEICA_CREATE_OR_UPDATE_PARTTEMPLATES,
  LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_ERROR,
  LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_SUCCESS,
  LEICA_DELETE_PARTTEMPLATES,
  LEICA_DELETE_PARTTEMPLATES_ERROR,
  LEICA_DELETE_PARTTEMPLATES_SUCCESS,
  LEICA_GET_SYSTEMS_FOR_SYSTEM_DB,
  LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_SUCCESS,
  LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_ERROR,
  LEICA_GET_RESOURCES,
  LEICA_GET_RESOURCES_SUCCESS,
  LEICA_GET_RESOURCES_ERROR,
  LEICA_GET_EMPLOYEES_ERROR,
  LEICA_GET_EMPLOYEES_SUCCESS,
  LEICA_GET_EMPLOYEES,
  LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA,
  LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_SUCCESS,
  LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_ERROR,
  LEICA_GET_FACTORYBOARD_DATA,
  LEICA_GET_FACTORYBOARD_DATA_ERROR,
  LEICA_GET_FACTORYBOARD_DATA_SUCCESS,
  LEICA_GET_DEPARTUREBOARD_DATA,
  LEICA_GET_DEPARTUREBOARD_DATA_ERROR,
  LEICA_GET_DEPARTUREBOARD_DATA_SUCCESS,
  LEICA_GET_SYSTEMLOADOUT,
  LEICA_GET_SYSTEMLOADOUT_SUCCESS,
  LEICA_GET_SYSTEMLOADOUT_ERROR,
  LEICA_GET_OVERVIEW,
  LEICA_GET_OVERVIEW_SUCCESS,
  LEICA_GET_OVERVIEW_ERROR,
  LEICA_GET_ISSUES_BY_ID,
  LEICA_GET_ISSUES_BY_ID_SUCCESS,
  LEICA_GET_ISSUES_BY_ID_ERROR,
  LEICA_GET_CHECKLIST_BY_STEPIDS,
  LEICA_GET_CHECKLIST_BY_STEPIDS_SUCCESS,
  LEICA_GET_CHECKLIST_BY_STEPIDS_ERROR,
  LEICA_AUTOSCHEDULE_NEW_SYSTEM,
  LEICA_AUTOSCHEDULE_NEW_SYSTEM_SUCCESS,
  LEICA_AUTOSCHEDULE_NEW_SYSTEM_ERROR,
  LEICA_SET_STEPCOMPLETE,
  LEICA_SET_STEPCOMPLETE_SUCCESS,
  LEICA_SET_STEPCOMPLETE_ERROR,
  LEICA_TOGGLE_WORKSTATUS,
  LEICA_TOGGLE_WORKSTATUS_SUCCESS,
  LEICA_TOGGLE_WORKSTATUS_ERROR,
  TEST_CHANGE_TOGGLE,
  TEST_STEP_COMPLETE,
  LEICA_PUT_ISSUE,
  LEICA_PUT_ISSUE_SUCCESS,
  LEICA_PUT_ISSUE_ERROR,
  LEICA_DELETE_SYSTEM,
  LEICA_DELETE_SYSTEM_ERROR,
  LEICA_DELETE_SYSTEM_SUCCESS,
  LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS,
  LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_SUCCESS,
  LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_ERROR,
  LEICA_SUBMIT_NEW_SYSTEM,
  LEICA_SUBMIT_NEW_SYSTEM_SUCCESS,
  LEICA_SUBMIT_NEW_SYSTEM_ERROR,
  LEICA_UPDATE_SYSTEM_CONFIG,
  LEICA_UPDATE_SYSTEM_CONFIG_SUCCESS,
  LEICA_UPDATE_SYSTEM_CONFIG_ERROR,
  LEICA_GET_DOWNTIMEKPI,
  LEICA_GET_DOWNTIMEKPI_SUCCESS,
  LEICA_GET_DOWNTIMEKPI_ERROR,
  LEICA_PUT_SONDERNUMMERN,
  LEICA_PUT_SONDERNUMMERN_SUCCESS,
  LEICA_PUT_SONDERNUMMERN_ERROR,
  LEICA_RESCHEDULE_SYSTEMS,
  LEICA_RESCHEDULE_SYSTEMS_SUCCESS,
  LEICA_RESCHEDULE_SYSTEMS_ERROR,
  LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD,
  LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_ERROR,
  LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_SUCCESS,
  LEICA_PUT_RESOURCE_ABSENCE_TIMES,
  LEICA_PUT_RESOURCE_ABSENCE_TIMES_SUCCESS,
  LEICA_PUT_RESOURCE_ABSENCE_TIMES_ERROR,
  // LEICA_PUT_HOLD_TIME,
  // LEICA_PUT_HOLD_TIME_ERROR,
  // LEICA_PUT_HOLD_TIME_SUCCESS,
  // LEICA_DELETE_HOLD_TIME,
  // LEICA_DELETE_HOLD_TIME_ERROR,
  // LEICA_DELETE_HOLD_TIME_SUCCESS,
  LEICA_GET_HOLD_TIMES,
  LEICA_GET_HOLD_TIMES_SUCCESS,
  LEICA_GET_HOLD_TIMES_ERROR,
} from "../actions";
import { getLeicaSystemsWithChecklistData } from "./actions";

const INIT_STATE = {
  systems: [],
  overview: [],
  checklistIsScheduled: false,
  sonderraumChecklistIsScheduled: false,
  systemsWithChecklistData: [],
  resources: [],
  employees: {},
  systemLoadout: [],
  issues: [],
  departures: [],
  checklist: [],
  sondernummern: [],
  downtimeKPI: { SIT: null, Final: null, Total: null, ModulBau: null },
  factoryBoardKey: Date.now().toString(),
  factoryboardData: [],
  departureboardData: [],

  adminDashboardSettings: null,
  issueCreated: false,
  currentWorkState: null,
  currentWorkPackage: null,
  currentWorkStep: null,
  currentWorkStepId: null,
  currentWorkTimeId: null,
  systemstatus: null,
  systemId: null,
  checklistId: null,
  excelHeaderResubmitResponse: null,
  submitNewSystemError: null,

  loadingSystems: true,
  loadingAdminDashboardSettings: true,
  loadingResources: true,
  loadingEmployees: true,
  loadingSystemsWithChecklistData: true,
  loadingFactoryboardData: true,
  loadingDepartureboardData: true,
  loadingSystemloadout: true,
  loadingOverview: true,
  loadingIssuesById: true,
  loadingDepartures: true,
  loadingChecklist: true,
  loadingChecklistByStepIds: true,
  loadingHoldTimes: true,
  loadingSonderraumHoldTimes: true,
  loadingSetStepComplete: true,
  loadingPutIssue: true,
  loadingToggleWorkstatus: true,
  loadingDowntimeKPI: true,

  creatingNewSystem: false,
  creatingNewSystemError: false,

  autoscheduling: false,
  submittingSondernummern: false,

  settingStepComplete: false,

  loadingSystemsError: false,
  loadingSystemsWithChecklistDataError: false,
  loadingResourcesError: false,
  loadingEmployeesError: false,
  loadingSystemloadoutError: false,
  loadingOverviewError: false,
  loadingIssuesByIdError: false,
  loadingChecklistByStepIdsError: false,
  loadingSonderraumHoldTimesError: false,
  loadingHoldTimesError: false,
  loadingDeparturesError: false,
  loadingChecklistError: false,
  loadingSetStepCompleteError: false,
  loadingToggleWorkstatusError: false,
  loadingPutIssueError: false,
  loadingDowntimeKPIError: false,
  putSondernummernError: false,
  autoscheduleNewSystemError: false,
  rescheduleSystemsError: false,
};

export default (state = INIT_STATE, action) => {
  function resetState() {
    return {
      ...state,
      systemLoadout: [],
      overview: [],
      checklistIsScheduled: false,
      sonderraumChecklistIsScheduled: false,
      systemsWithChecklistData: [],
      issues: [],
      checklist: [],
      issueCreated: false,
      currentWorkState: null,
      currentWorkPackage: null,
      currentWorkStep: null,
      currentWorkStepId: null,
      currentWorkTimeId: null,
      systemstatus: null,
      systemId: null,
      checklistId: null,
      excelHeaderResubmitResponse: null,
    };
  }

  function updateChecklist(workpackage, stepId, stepState, timespend) {
    let checklistTemplate = [...state.checklist];

    const WorkPackageIndex = checklistTemplate.findIndex(
      (element) => element.id === workpackage
    );
    let Workpackage = { ...checklistTemplate[WorkPackageIndex] };
    let WorkpackageData = [...Workpackage.data];

    const StepId = checklistTemplate[WorkPackageIndex].data.findIndex(
      (element) => element.instanceId === stepId
    );

    let Step = { ...WorkpackageData[StepId] };
    let StepStatus = { ...Step.status };

    StepStatus.state = stepState ? stepState : StepStatus.state;
    StepStatus.timespend = timespend ? timespend : StepStatus.timespend;

    Step.status = StepStatus;
    WorkpackageData[StepId] = Step;
    Workpackage.data = WorkpackageData;
    checklistTemplate[WorkPackageIndex] = Workpackage;

    state.checklist = [...checklistTemplate];
  }

  switch (action.type) {
    case LEICA_GET_ADMINDASHBOARD_SETTINGS:
      return {
        ...state,
        loadingAdminDashboardSettings: true,
      };

    case LEICA_GET_ADMINDASHBOARD_SETTINGS_SUCCESS:
      return {
        ...state,
        adminDashboardSettings: action.payload,
        loadingAdminDashboardSettings: false,
      };

    case LEICA_GET_ADMINDASHBOARD_SETTINGS_ERROR:
      return {
        ...state,
        adminDashboardSettingsError: action.payload,
        loadingAdminDashboardSettings: false,
      };

    case LEICA_SET_ADMINDASHBOARD_SETTINGS:
      return {
        ...state,
        settingAdminDashboardSettings: true,
      };

    case LEICA_SET_ADMINDASHBOARD_SETTINGS_SUCCESS:
      return {
        ...state,
        adminDashboardSettings: action.payload,
        settingAdminDashboardSettings: false,
      };

    case LEICA_SET_ADMINDASHBOARD_SETTINGS_ERROR:
      return {
        ...state,
        settingAdminDashboardSettingsError: action.payload,
        settingAdminDashboardSettings: false,
      };

    case LEICA_GET_PARTTEMPLATES:
      return {
        ...state,
        loadingPartTemplates: true,
      };

    case LEICA_GET_PARTTEMPLATES_SUCCESS:
      return {
        ...state,
        partTemplates: action.payload,
        loadingPartTemplates: false,
      };

    case LEICA_GET_PARTTEMPLATES_ERROR:
      return {
        ...state,
        loadingPartTemplatesError: action.payload,
        loadingPartTemplates: false,
      };

    case LEICA_CREATE_OR_UPDATE_PARTTEMPLATES:
      return {
        ...state,
        updatingPartTemplates: true,
      };

    case LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_SUCCESS:
      return {
        ...state,
        updatingPartTemplates: false,
      };

    case LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_ERROR:
      return {
        ...state,
        updatingPartTemplatesError: action.payload,
        updatingPartTemplates: false,
      };

    case LEICA_DELETE_PARTTEMPLATES:
      return {
        ...state,
        deletingPartTemplates: true,
      };

    case LEICA_DELETE_PARTTEMPLATES_SUCCESS:
      return {
        ...state,
        deletingPartTemplates: false,
      };

    case LEICA_DELETE_PARTTEMPLATES_ERROR:
      return {
        ...state,
        deletingPartTemplatesError: action.payload,
        deletingPartTemplates: false,
      };

    case LEICA_GET_SYSTEMS_FOR_SYSTEM_DB:
      let resetdata = resetState();
      return {
        ...state,
        ...resetdata,
        loadingSystems: true,
      };

    case LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_SUCCESS:
      return {
        ...state,
        systems: action.payload,
        loadingSystemloadout: true,
        loadingOverview: true,
        loadingIssuesById: true,
        loadingDepartures: true,
        loadingChecklist: true,
        loadingChecklistByStepIds: true,
        loadingHoldTimes: true,
        loadingSonderraumHoldTimes: true,
        loadingSetStepComplete: true,
        loadingPutIssue: true,
        loadingToggleWorkstatus: true,
        loadingSystems: false,
      };

    case LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_ERROR:
      return {
        ...state,
        loadingSystemsError: action.payload.error,
        loadingSystems: false,
      };

    case LEICA_GET_RESOURCES:
      return {
        ...state,
        loadingResources: true,
        loadingResourcesError: false,
        resources: [],
      };

    case LEICA_GET_RESOURCES_SUCCESS:
      return {
        ...state,
        loadingResources: false,
        resources: action.payload,
      };

    case LEICA_GET_RESOURCES_ERROR:
      return {
        ...state,
        loadingResources: false,
        loadingResourcesError: action.payload,
      };

    case LEICA_GET_EMPLOYEES:
      return {
        ...state,
        loadingEmployees: true,
        loadingEmployeesError: false,
        employees: [],
      };

    case LEICA_GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loadingEmployees: false,
        employees: action.payload,
      };

    case LEICA_GET_EMPLOYEES_ERROR:
      return {
        ...state,
        loadingEmployees: false,
        loadingEmployeesError: action.payload,
      };

    case LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA:
      let resetdataSystemWithChecklistLoader = resetState();
      return {
        ...state,
        ...resetdataSystemWithChecklistLoader,
        loadingSystemsWithChecklistData: true,
      };

    case LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_SUCCESS:
      return {
        ...state,
        systemsWithChecklistData: action.payload,
        loadingSystemsWithChecklistData: false,
        factoryBoardKey: Date.now().toString(),
      };

    case LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_ERROR:
      return {
        ...state,
        loadingSystemsWithChecklistDataError: action.payload.error,
        loadingSystemsWithChecklistData: false,
      };

    case LEICA_GET_FACTORYBOARD_DATA:
      return {
        ...state,
        loadingFactoryboardData: true,
      };

    case LEICA_GET_FACTORYBOARD_DATA_SUCCESS:
      return {
        ...state,
        factoryboardData: { ...action.payload },
        loadingFactoryboardData: false,
      };

    case LEICA_GET_FACTORYBOARD_DATA_ERROR:
      return {
        ...state,
        loadingFactoryboardData: false,
        loadingFactoryboardDataError: action.payload.error,
      };

    case LEICA_GET_DEPARTUREBOARD_DATA:
      return {
        ...state,
        loadingDepartureboardData: true,
      };

    case LEICA_GET_DEPARTUREBOARD_DATA_SUCCESS:
      return {
        ...state,
        departureboardData: action.payload.departureboardData,
        loadingDepartureboardData: false,
      };

    case LEICA_GET_DEPARTUREBOARD_DATA_ERROR:
      return {
        ...state,
        loadingDepartureboardData: false,
        loadingDepartureboardDataError: action.payload.error,
      };

    case LEICA_GET_SYSTEMLOADOUT:
      return { ...state };

    case LEICA_GET_SYSTEMLOADOUT_SUCCESS:
      return {
        ...state,
        systems: action.payload.systemLoadout,
        loadingSystemloadout: false,
      };

    case LEICA_GET_SYSTEMLOADOUT_ERROR:
      return {
        ...state,
        loadingSystemloadoutError: action.payload.error,
        loadingSystemloadout: false,
      };

    case LEICA_GET_OVERVIEW:
      return {
        ...state,
        holdTimes: {},
        loadingHoldTimes: true,
        loadingSonderraumHoldTimes: true,
        loadingSonderraumHoldTimesError: false,
        loadingHoldTimesError: false,
        loadingOverview: true,
      };

    case LEICA_GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        systemstatus: action.payload.overview.systemstatus,
        checklistIsScheduled: action.payload.overview.isScheduled,
        sonderraumChecklistIsScheduled:
          action.payload.overview.sonderraumIsScheduled,
        overview: action.payload.overview,
        systemId: action.payload.overview.systemId,
        checklistId: action.payload.overview.checklistId,
        loadingOverview: false,
      };

    case LEICA_GET_OVERVIEW_ERROR:
      return {
        ...state,
        loadingOverviewError: action.payload,
        loadingOverview: false,
      };

    case LEICA_GET_ISSUES_BY_ID:
      return { ...state };

    case LEICA_GET_ISSUES_BY_ID_SUCCESS:
      return {
        ...state,
        issues: action.payload.issues,
        loadingIssuesById: false,
      };

    case LEICA_GET_ISSUES_BY_ID_ERROR:
      return {
        ...state,
        loadingIssuesByIdError: action.payload,
        loadingIssuesById: false,
      };

    case LEICA_GET_CHECKLIST_BY_STEPIDS:
      return { ...state };

    case LEICA_GET_CHECKLIST_BY_STEPIDS_SUCCESS:
      return {
        ...state,
        checklist: action.payload.checklist.checklist,
        currentWorkState: action.payload.checklist.currentWorkState,
        currentWorkPackage: action.payload.checklist.currentWorkPackage,
        currentWorkTimeId: action.payload.checklist.currentWorkTimeId,
        currentWorkStep: action.payload.checklist.currentWorkStep,
        currentWorkStepId: action.payload.checklist.currentWorkStepId,
        loadingChecklistByStepIds: false,
      };

    case LEICA_GET_CHECKLIST_BY_STEPIDS_ERROR:
      return {
        ...state,
        loadingChecklistByStepIdsError: action.payload,
        loadingChecklistByStepIds: false,
      };

    case LEICA_GET_HOLD_TIMES:
      return { ...state };

    case LEICA_GET_HOLD_TIMES_SUCCESS:
      if (action.payload.isSonderraum) {
        return {
          ...state,
          holdTimes: {
            ...state.holdTimes,
            ...action.payload.holdTimes,
          },
          loadingSonderraumHoldTimes: false,
        };
      } else {
        return {
          ...state,
          holdTimes: {
            ...state.holdTimes,
            ...action.payload.holdTimes,
          },
          loadingHoldTimes: false,
        };
      }

    case LEICA_GET_HOLD_TIMES_ERROR:
      if (action.payload.isSonderraum) {
        return {
          ...state,
          loadingHoldTimesError: action.payload,
          loadingSonderraumHoldTimes: false,
        };
      } else {
        return {
          ...state,
          loadingHoldTimesError: action.payload,
          loadingHoldTimes: false,
        };
      }

    case LEICA_PUT_SONDERNUMMERN:
      return { ...state, submittingSondernummern: true };

    case LEICA_PUT_SONDERNUMMERN_SUCCESS:
      return { ...state, submittingSondernummern: false };

    case LEICA_PUT_SONDERNUMMERN_ERROR:
      return {
        ...state,
        submittingSondernummern: false,
        putSondernummernError: action.payload,
      };

    case LEICA_AUTOSCHEDULE_NEW_SYSTEM:
      return { ...state, autoscheduling: true };

    case LEICA_AUTOSCHEDULE_NEW_SYSTEM_SUCCESS:
      return { ...state, autoscheduling: false };

    case LEICA_AUTOSCHEDULE_NEW_SYSTEM_ERROR:
      return {
        ...state,
        autoscheduling: false,
        autoscheduleNewSystemError: action.payload.error,
      };

    case LEICA_RESCHEDULE_SYSTEMS:
      return { ...state };

    case LEICA_RESCHEDULE_SYSTEMS_SUCCESS:
      return { ...state };

    case LEICA_RESCHEDULE_SYSTEMS_ERROR:
      return { ...state, rescheduleSystemsError: action.payload.error };

    case LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD:
      return { ...state, autoOrganizingSystemsOnFactoryboard: true };

    case LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_SUCCESS:
      return {
        ...state,
        autoOrganizingSystemsOnFactoryboard: false,
        factoryBoardKey: Date.now().toString(),
      };

    case LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_ERROR:
      return {
        ...state,
        autoOrganizingSystemsOnFactoryboard: false,
        autoorganizeSystemsError: action.payload.error,
        factoryBoardKey: Date.now().toString(),
      };

    case LEICA_SET_STEPCOMPLETE:
      return { ...state, settingStepComplete: true };

    case LEICA_SET_STEPCOMPLETE_SUCCESS:
      if (
        action.payload.prevStep !== "" &&
        Object.keys(action.payload.prevStep).length !== 0
      ) {
        updateChecklist(
          action.payload.prevStep.workpackage,
          action.payload.prevStep.stepId,
          "completed",
          action.payload.prevStep.timeSpent
        );
      }
      let workpack = false;
      if (
        Object.keys(action.payload.nextStep).length !== 0 &&
        action.payload.nextStep !== ""
      ) {
        workpack = action.payload.nextStep.workpackage;
        updateChecklist(
          action.payload.nextStep.workpackage,
          action.payload.nextStep.stepId,
          "inproduction",
          0
        );
      }
      return {
        ...state,
        currentWorkPackage: workpack ? workpack : state.currentWorkPackage,
        currentWorkState: true,
        systemstatus: action.payload.systemstatus,
        currentWorkStepId: action.payload.nextStep.stepId,
        currentWorkStep: action.payload.nextStep.step,
        loadingSetStepComplete: true,
        settingStepComplete: false,
      };

    case LEICA_SET_STEPCOMPLETE_ERROR:
      return {
        ...state,
        loadingSetStepCompleteError: action.payload.error,
        loadingSetStepComplete: false,
        settingStepComplete: false,
      };

    case LEICA_TOGGLE_WORKSTATUS:
      return { ...state };

    case LEICA_TOGGLE_WORKSTATUS_SUCCESS:
      if (action.payload.workStatus.timeSpend) {
        updateChecklist(
          state.currentWorkPackage,
          state.currentWorkStepId,
          null,
          action.payload.workStatus.timeSpend
        );
      }
      return {
        ...state,
        currentWorkState: action.payload.workStatus.toggleResponse,
        loadingToggleWorkstatus: false,
      };

    case LEICA_TOGGLE_WORKSTATUS_ERROR:
      return {
        ...state,
        loadingToggleWorkstatusError: action.payload.error,
        loadingToggleWorkstatus: false,
      };

    case LEICA_PUT_ISSUE:
      return { ...state, loadingPutIssue: false, issueCreated: false };

    case LEICA_PUT_ISSUE_SUCCESS:
      return {
        ...state,
        issueCreated: true,
        loadingPutIssue: false,
      };

    case LEICA_PUT_ISSUE_ERROR:
      return {
        ...state,
        loadingPutIssueError: action.payload.error,
        loadingPutIssue: false,
      };

    case LEICA_PUT_RESOURCE_ABSENCE_TIMES:
      return {
        ...state,
        updatingAbsenceTimes: true,
        updatingAbsenceTimesError: false,
      };

    case LEICA_PUT_RESOURCE_ABSENCE_TIMES_SUCCESS:
      // update resources in shopfloor
      let resources = state.resources;
      if (action.payload) {
        resources = state.resources.map(
          (resource) =>
            [action.payload].find(
              (updatedResource) => updatedResource.id === resource.id
            ) || resource
        );
      }
      return {
        ...state,
        updatingAbsenceTimes: false,
        resources: resources,
      };

    case LEICA_PUT_RESOURCE_ABSENCE_TIMES_ERROR:
      return {
        ...state,
        updatingAbsenceTimes: false,
        updatingAbsenceTimesError: action.payload.error,
      };

    // case LEICA_PUT_HOLD_TIME:
    //   return { ...state, updatingHoldTime: true, updatingHoldTimeError: false };

    // case LEICA_PUT_HOLD_TIME_SUCCESS:
    //   const updatedSystemIdx = state.systems.findIndex(
    //     (system) =>
    //       system.systemId === action.payload.inputPayload.system.systemId
    //   );
    //   const updatedSystems = [...state.systems];
    //   if (updatedSystemIdx !== -1) {
    //     updatedSystems[updatedSystemIdx].holdTimes[0] = {
    //       start: action.payload.inputPayload.newHoldTime.start,
    //       end: action.payload.inputPayload.newHoldTime.end,
    //       id: action.payload.newHoldTimeId,
    //     };
    //   }
    //   return {
    //     ...state,
    //     systems: updatedSystems,
    //     updatingHoldTime: false,
    //   };

    // case LEICA_PUT_HOLD_TIME_ERROR:
    //   return {
    //     ...state,
    //     updatingHoldTime: false,
    //     updatingHoldTimeError: action.payload.error,
    //   };

    // case LEICA_DELETE_HOLD_TIME:
    //   return { ...state, deletingHoldTime: true, deletingHoldTimeError: false };

    // case LEICA_DELETE_HOLD_TIME_SUCCESS:
    //   return {
    //     ...state,
    //     // systems: updatedSystems,
    //     deletingHoldTime: false,
    //   };

    // case LEICA_DELETE_HOLD_TIME_ERROR:
    //   return {
    //     ...state,
    //     deletingHoldTime: false,
    //     deletingHoldTimeError: action.payload.error,
    //   };

    case TEST_CHANGE_TOGGLE:
      return {
        ...state,
        currentWorkState: !state.currentWorkState,
      };

    case TEST_STEP_COMPLETE:
      let nonMutatedChecklist = [...state.checklist];

      let prevItem = { ...nonMutatedChecklist[1].data[0] };
      prevItem.status.state = "completed";
      prevItem.status.timespend = "10.0";

      let nextItem = { ...nonMutatedChecklist[1].data[1] };
      nextItem.status.state = "inproduction";

      let changedWorkpackage = { ...nonMutatedChecklist[1] };
      let changedData = [...nonMutatedChecklist[1].data];
      changedData[0] = prevItem;
      changedData[1] = nextItem;

      changedWorkpackage.data = changedData;

      delete nonMutatedChecklist[1];
      nonMutatedChecklist[1] = changedWorkpackage;

      return {
        ...state,
        checklist: nonMutatedChecklist,
      };

    case LEICA_DELETE_SYSTEM:
      return {
        ...state,
      };

    case LEICA_DELETE_SYSTEM_SUCCESS:
      let nonMutatedSystemList = [...state.systems];
      nonMutatedSystemList = nonMutatedSystemList.filter(
        (system) => system.systemId !== action.payload.systemId
      );
      return { ...state, systems: nonMutatedSystemList };

    case LEICA_DELETE_SYSTEM_ERROR:
      return { ...state, deleteSystemError: action.payload.error };

    case LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS:
      return { ...state };

    case LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_SUCCESS:
      return { ...state, excelHeaderResubmitResponse: action.payload.response };

    case LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_ERROR:
      return { ...state, submitExcelWithMappingError: action.payload.error };

    case LEICA_SUBMIT_NEW_SYSTEM:
      return {
        ...state,
        creatingNewSystem: true,
        creatingNewSystemError: false,
      };

    case LEICA_SUBMIT_NEW_SYSTEM_SUCCESS:
      return {
        ...state,
        creatingNewSystem: false,
        sondernummern: action.payload.sondernummern,
      };

    case LEICA_SUBMIT_NEW_SYSTEM_ERROR:
      return {
        ...state,
        creatingNewSystem: false,
        creatingNewSystemError: true,
        submitNewSystemError: action.payload.error,
      };

    case LEICA_UPDATE_SYSTEM_CONFIG:
      return {
        ...state,
        creatingNewSystem: true,
        creatingNewSystemError: false,
      };

    case LEICA_UPDATE_SYSTEM_CONFIG_SUCCESS:
      return { ...state, creatingNewSystem: false };

    case LEICA_UPDATE_SYSTEM_CONFIG_ERROR:
      return {
        ...state,
        creatingNewSystem: false,
        updateSystemConfigError: action.payload.error,
      };

    case LEICA_GET_DOWNTIMEKPI:
      return {
        ...state,
        DowntimeKPI: {},
        loadingDowntimeKPI: true,
      };
    case LEICA_GET_DOWNTIMEKPI_SUCCESS:
      return {
        ...state,
        downtimeKPI: { ...action.payload },
        loadingDowntimeKPI: false,
      };
    case LEICA_GET_DOWNTIMEKPI_ERROR:
      return {
        ...state,
        loadingDowntimeKPIError: action.payload.error,
        loadingDowntimeKPI: false,
      };

    default:
      return state;
  }
};
