import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import leicaSaga from "./leica/sagas";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    leicaSaga(),
  ]);
}
