import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

//Setup Global connection to Database
const httpsLink = new HttpLink({
  uri: `${process.env.REACT_APP_HASURA_URI}`,
  headers: {
    "x-hasura-admin-secret": `${process.env.REACT_APP_HASURA_ADMIN_SECRET}`,
  },
});
export const client = new ApolloClient({
  link: httpsLink,
  cache: new InMemoryCache({ resultCacheMaxSize: 10000 }),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
});

// execute garbage collection on apollo client cache every 60 sec to keep ram usage low
setInterval(() => client.cache.gc(), 60000);

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <DndProvider backend={HTML5Backend}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </DndProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
