const SondernummernStepTemplate = {
    instanceId: "",
    templateId: "97bab0ecf13246548c86c40241128b4d",
    title: "SondernummernStepTemplates",
    imageUrl: "http://159.69.37.15:5010/db/static/assets/uploads/placeholder.jpg",
    identifier: "VK-Nummer",
    text: "Name",
    properties: [
      // 0
      {
        propertyName: "Arbeitspaket",
        dataType: "TEXT",
        propertyId: "97bab0ecf13246548c86c40241128b4d",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 1
      {
        propertyName: "Step",
        dataType: "TEXT",
        propertyId: "97bab0ecf13246548c86c40241128b4d",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 2
      {
        propertyName: "Option",
        dataType: "TEXT",
        propertyId: "97bab0ecf13246548c86c40241128b4d",
        propertyValue: "SpecialPartsRequest",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 3
      {
        propertyName: "Related_Manual",
        dataType: "TEXT",
        propertyId: "97bab0ecf13246548c86c40241128b4d",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 4
      {
        propertyName: "Durchschnittl_Zeit",
        dataType: "TEXT",
        propertyId: "97bab0ecf13246548c86c40241128b4d",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 5
      {
        propertyName: "Beschreibung",
        dataType: "TEXT",
        propertyId: "97bab0ecf13246548c86c40241128b4d",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 6
      {
        propertyName: "TestProtocol",
        dataType: "TEXT",
        propertyId: "97bab0ecf13246548c86c40241128b4d",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      // 7
      {
        propertyName: "PositionInArbeitspaket",
        dataType: "TEXT",
        propertyId: "97bab0ecf13246548c86c40241128b4d",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
    ],
  };
  
  export default SondernummernStepTemplate;
  