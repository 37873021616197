const ResourceTemplate = {
  instanceId: "",
  templateId: "1c10a7e19ce14d1fbaf3ae93ff0cec56",
  imageUrl: "http://159.69.37.15:5010/db/static/assets/uploads/placeholder.jpg",
  title: "Resource",
  identifier: "Name",
  text: "Name",
  properties: [
    {
      propertyId: "1c10a7e19ce14d1fbaf3ae93ff0cec56",
      propertyName: "Name",
      propertyValue: "",
      dataType: "TEXT",
      formula: "",
      displayName: "",
      index: "",
      accessMode: "automaticw",
    },
    {
      propertyId: "1c10a7e19ce14d1fbaf3ae93ff0cec56",
      propertyName: "Type",
      propertyValue: "",
      dataType: "TEXT",
      formula: "",
      displayName: "",
      index: "",
      accessMode: "automaticw",
    },
    {
      propertyId: "1c10a7e19ce14d1fbaf3ae93ff0cec56",
      propertyName: "AbsenceTimes",
      propertyValue: "",
      dataType: "TEXT",
      formula: "",
      displayName: "",
      index: "",
      accessMode: "automaticw",
    },
  ],
};
export default ResourceTemplate;
