import { DayPilot } from "daypilot-pro-react";
import { SYSTEM_STATUS_MAPPING } from "./microscopeSystemStatusMapping";
import { STEP_STATUS_MAPPING } from "./stepStatusMapping";

// ====================================================================================================
// DATA FORMATTING
// ====================================================================================================

export function checkIsNonEmptyValue(value) {
  // checks if the input value (usually a property value from DB) is an actual value or empty
  // normalizes all kinds of empty values to be expected throughout data fetching to undefined
  if (["", null, undefined, [], {}, "None", NaN].includes(value)) {
    return undefined;
  } else {
    return value;
  }
}

export function normalizeAllEmptyValuesInObject(object) {
  // loops over all key-value pairs in object and normalizes different types of empty values to undefined
  // handles nested objects via recursion, treats array values like any normal single datapoint value
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === "object" && Object.keys(value).length) {
      // recursively call this function on nested objects
      object[key] = normalizeAllEmptyValuesInObject(value);
    } else {
      // handle normal values that are not nested objects
      object[key] = checkIsNonEmptyValue(value);
    }
  });
  return object;
}

export function normalizeWorkshares(workshares) {
  if (!workshares || workshares === "") {
    throw new Error("Workshares are empty!");
  } else if (typeof workshares === "string") {
    workshares = JSON.parse(workshares);
  }
  Object.keys(workshares).forEach(
    (id) => (workshares[id] = parseFloat(workshares[id]))
  );
  return workshares;
}

export function formatHoldTime(holdTime) {
  return {
    start: holdTime.start_date,
    end: holdTime.end_date,
    resourceDuringHold: holdTime.workstation_during_hold_id,
    resourceAfterHold: holdTime.workstation_after_hold_id,
    id: holdTime.id,
  };
}

// ====================================================================================================
// CURRENT WORKPACKAGE
// ====================================================================================================

export function getCurrentWorkpackage(workshares, lastCompletedWorkpackage) {
  // check if any workpackage has been completed yet
  // (otherwise, lastCompletedWorkpackage would be undefined and hence not in the workshares keys)
  const workshareArray = Object.keys(workshares);
  if (workshareArray.includes(lastCompletedWorkpackage)) {
    // if yes, get the next workpackage after the given last completed one
    const lastWpIdx = workshareArray.indexOf(lastCompletedWorkpackage);
    return workshareArray[lastWpIdx + 1];
  } else {
    // if no, we must be working on the very first workpackage now
    return workshareArray[0];
  }
}

// ====================================================================================================
// STARTDATUM OF SIT PRODUCTION ON WORKSTATION (= inproduction status)
// ====================================================================================================

export function getWorkstationStartdatum(
  lastCompletedWorkpackage,
  workshares,
  checklistStartdatum,
  sitStartdatum,
  leicaWorkHoursPerDay
) {
  // for SIT checklists, we want to set the workstation startdate to the (estimated or real) completion date of WP 121
  // because the start date of the factoryboard bar should be the start of WP 122
  // we will also only consider WP 122 - end for deviation from original duration estimate
  if (Object.keys(workshares)[0] !== "101") {
    // for SGR checklists, the workstation startdate and checklist startdate are identical
    return checklistStartdatum;
  } else if (sitStartdatum) {
    // if this is an SIT checklist, the start date of SIT workstation production is set in a dedicated field sit_start_date in db
    return sitStartdatum
  // } else if (checklistStartdatum) {
  //   // if this is an SIT checklist and the WP 121 has NOT been completed yet, estimate the start date of WP 122
  //   // this happens by adding the duration of WP 101-122 to the checklist startdate
  //   // if the checklist startdate is not yet set, we'll just set undefined for both and calculate it when scheduling happens
  //   // NB: this does not take into account the actual amount of time already tracked on those WPs, it only looks at workshares
  //   // additionally, skip weekends
  //   // NB: currently ignoring holds/absence times because the system shouldn't be able to encounter any while before WP 122
  //   // get back to this if it causes any problems!
  //   const estimatedWorkHoursUntilWP122 = Object.entries(workshares)
  //     .filter(([wp, duration]) => parseInt(wp) < 122) // only get WPs before 122
  //     .map(([wp, duration]) => duration) // get the duration of relevant WPs
  //     .reduce((a, b) => a + b, 0); // sum up duration of all WPs before 122
  //   // calculate the workstation start date from checklist start date + estimated time before WP 122
  //   if (estimatedWorkHoursUntilWP122 <= leicaWorkHoursPerDay) {
  //     return checklistStartdatum;
  //   } else {
  //     let workDaysToAdd = Math.ceil(
  //       estimatedWorkHoursUntilWP122 / leicaWorkHoursPerDay
  //     );
  //     const workstationStartdatum = new DayPilot.Date(checklistStartdatum);
  //     while (workDaysToAdd > 0) {
  //       // add work days difference to checklist startdatum
  //       workstationStartdatum.addDays(1);
  //       if (![0, 6].includes(workstationStartdatum.getDayOfWeek)) {
  //         // add additional days for weekends, i.e. don't subtract those from remaining days to be added
  //         workDaysToAdd--;
  //       }
  //       return workstationStartdatum.toString("yyyy-MM-dd");
  //     }
  //   }
  } else {
    // this should be the case when the startdatum for SIT checklist is not set,
    // in which case we'll set undefined for the workstation/WP 122 startdatum
    return undefined;
  }
}

// ====================================================================================================
// WORK TIME CALCULATIONS
// ====================================================================================================

export function calculateOriginallyExpectedWorkHoursFromWorkshares(
  workshares,
  startFromWP = 101 // according to customer wish we should be able to start from first SIT workpackage 122 as well
) {
  // sums up the workshares to get the expected duration the whole checklist should take (in hours)
  // only includes actual work time, not holds/absences/weekends
  // the optional parameter "startFromWP" specifies that all workshares before this one should be ignored in calculation
  const originallyExpectedWorkHours = Object.entries(workshares)
    .filter(([wp, duration]) => {
      return (
        isNaN(parseInt(wp)) || // this first condition should be the case for all workpackages of sr checklists (and no other ones)
        parseInt(wp) >= startFromWP
      );
    })
    .map(([wp, duration]) => duration)
    .reduce((a, b) => a + b, 0); // sum up all workshare durations that were found for the range starting from specified wp
  return originallyExpectedWorkHours;
}

export function countWorkDaysOnChecklistInTimeframe(
  startDate,
  endDate,
  checklistInitialResource,
  checklistHoldTime,
  resourceAbsenceDays
) {
  // count days that the checklist would have been worked on between given start date (incl) and end date (excl)
  // TO DO: currently start date must be checklist start date or later since we're not checking explicitly if the checklist had already been started at startdate
  // skip days in count that are weekends, absences on respective resource, or holds
  let workDaysCount = 0;
  let currentDate = new DayPilot.Date(startDate);
  let finalDate = new DayPilot.Date(endDate);
  while (currentDate < finalDate) {
    // check if one of the "skip during count" criteria is fulfilled
    // check if weekend
    const isWeekend = [0, 6].includes(currentDate.getDayOfWeek());
    // check if hold
    const holdStart =
      checklistHoldTime && new DayPilot.Date(checklistHoldTime.start);
    const holdEnd =
      checklistHoldTime && new DayPilot.Date(checklistHoldTime?.end);
    const isHold =
      checklistHoldTime && currentDate > holdStart && currentDate < holdEnd;
    // check if absence
    // find out which resource we are on to check if the current date is an absence day there
    let currentResource = undefined;
    if (!checklistHoldTime || currentDate < holdStart) {
      currentResource = checklistInitialResource;
    } else if (isHold) {
      currentResource = checklistHoldTime.resourceDuringHold;
    } else {
      currentResource = checklistHoldTime.resourceAfterHold;
    }
    // compare current date with list of absence days for current resource
    const isAbsence =
      currentResource in resourceAbsenceDays &&
      resourceAbsenceDays[currentResource].includes(currentDate);

    // if no "skip during count" criteria are fulfilled, count as working day (i. e., day that the checklist has been worked on)
    if (!isWeekend && !isHold && !isAbsence) {
      workDaysCount++;
    }

    // increment current date to look at for next iteration
    currentDate = currentDate.addDays(1);
  }
  return workDaysCount;
}

export function calculateWorkDaysSpentOnChecklistBeforeToday(
  checklistStartDate, // pass the date that a workpackage was completed to start calculating from there instead
  checklistInitialResource,
  checklistHoldTime,
  resourceAbsenceDays,
  checklistStatus
) {
  if (
    checklistStatus === "notstarted" ||
    !checklistStartDate ||
    !checklistInitialResource
  ) {
    // skip calculation if checklist not yet started or no start date and resource are set,
    // in which case no steps could have been completed yet
    return 0.0;
  } else {
    const workDaysSpentOnChecklistBeforeToday =
      countWorkDaysOnChecklistInTimeframe(
        checklistStartDate,
        DayPilot.Date.today(),
        checklistInitialResource,
        checklistHoldTime,
        resourceAbsenceDays
      );
    return workDaysSpentOnChecklistBeforeToday;
  }
}

export function calculateMinsSpentOnChecklistToday(
  stepsCompletedToday,
  leicaWorkHoursPerDay
) {
  // check if any steps were completed today
  // if so, sum up their respective timespends to get total time spent today
  // TO DO: clarify how to deal with steps that have been in production since before today
  // as these could potentially screw up calculation, but would only be minimally more precise anyway, ignore for now
  const minutesTrackedToday = stepsCompletedToday
    .map(
      (step) => parseFloat(step.time_spent_in_minutes || 0.0) // fallback to adding 0 mins if time spent is NaN
    )
    .reduce((a, b) => {
      return a + b; // add up all individual step time spents
    }, 0.0); // fallback to 0 if no applicable steps found

  // in case the last completed step ran overnight, the calculated minutes spent today may go up to more than 24h
  // to catch this and break it down to a realistic approximated value,
  // replace with the standard work mins per day or mins the day had yet, as needed (pick the smallest out of these three)
  const leicaWorkMinutesPerDay = leicaWorkHoursPerDay * 60;
  const minutesSinceMidnight = new DayPilot.Duration(
    DayPilot.Date.today(),
    DayPilot.Date.now()
  ).totalMinutes();
  const minutesSpentToday = Math.min(
    minutesTrackedToday,
    leicaWorkMinutesPerDay,
    minutesSinceMidnight
  );

  return minutesSpentToday;
}

export function calculateMinsRemainingInCurrentWP(
  stepsCompletedTodayOrInActiveWP
) {
  // sum up the estimated required times for all steps in the current WP that are still open
  const minutesRemainingInCurrentWP = stepsCompletedTodayOrInActiveWP
    .map(
      (step) =>
        parseFloat(step?.properties?.estimatedtime?.propertyValue || 0.0) // fallback to adding 0 mins if estimated time is NaN
    )
    .reduce((a, b) => {
      return a + b; // add up all individual step time estimates
    }, 0.0); // fallback to 0 if no applicable steps found

  return minutesRemainingInCurrentWP;
}

export function calculateWorkHoursRemainingAfterCurrentWP(
  workshares,
  currentWorkPackage,
  startFromWP = 101
) {
  const idxOfCurrentWorkPackage =
    Object.keys(workshares).indexOf(currentWorkPackage);

  // get the duration of all workshares that are after the current one and after the specified one, and sum those up
  const remainingWorkHours = Object.entries(workshares)
    .filter(
      ([wp, duration], idx) =>
        idx > idxOfCurrentWorkPackage &&
        (isNaN(parseInt(wp)) || // this condition should be the case for all workpackages of sr checklists (and no other ones)
          parseInt(wp) >= startFromWP)
    )
    .map(([wp, duration]) => {
      return duration;
    })
    .reduce((a, b) => a + b, 0); // sum up all remaining workshare durations that were found starting from current wp + above specified start

  return remainingWorkHours;
}

export function calculateCurrentlyExpectedTotalWorkHours(
  leicaWorkHoursPerDay,
  workDaysSpentBeforeToday,
  minutesSpentToday,
  minutesRemainingInCurrentWP,
  workHoursRemainingAfterCurrentWP
) {
  // convert all values to same unit of measurement (i.e., hours)
  const workHoursSpentBeforeToday =
    workDaysSpentBeforeToday * leicaWorkHoursPerDay;
  const workHoursSpentToday = minutesSpentToday / 60;
  const workHoursRemainingInCurrentWP = minutesRemainingInCurrentWP / 60;

  // get sum of all time blocks related to live tracking to get current prognosis of total production time
  return (
    workHoursSpentBeforeToday +
    workHoursSpentToday +
    workHoursRemainingInCurrentWP +
    workHoursRemainingAfterCurrentWP
  );
}

export function calculateDeviationFromChecklistScheduleInHours(
  originallyExpectedWorkHours,
  currentlyExpectedWorkHours
) {
  // gets the difference in hours between (a) originally expected production time for this checklist
  // and (b) current prognosis including already completed times
  // get difference to original estimate
  const deviationInWorkHours =
    Math.round(
      // can be very small values due to rounding errors: catch by rounding to 5 digits=min. 1s precision
      (currentlyExpectedWorkHours - originallyExpectedWorkHours) * 100000
    ) / 100000;

  // returns a positive value (+ x hours) if production is delayed, and a negative (- x hours) if production is faster
  return deviationInWorkHours;
}

// ====================================================================================================
// CHECKLIST STATUS
// ====================================================================================================

export function getChecklistStatusBySystemStatus(systemStatus, checklistType) {
  // checklist type can be "SIT" or "SGR"
  // returns checklist status "notstarted", "started" or "finished" respectively
  if (!["SIT", "SGR"].includes(checklistType)) {
    throw new Error("Unknown checklist type passed to function!");
  }
  if (["newsystem", "started", "transitiontosit"].includes(systemStatus)) {
    return "notstarted"; // treat SIT checklist as not started with systemstatus started so it behaves like new system on factoryboard
  } else if (systemStatus === "inproduction") {
    return checklistType === "SIT" ? "started" : "notstarted";
  } else if (systemStatus === "transitiontosgr") {
    return checklistType === "SIT" ? "finished" : "notstarted";
  } else if (systemStatus === "sgrinproduction") {
    return checklistType === "SIT" ? "finished" : "started";
  } else if (systemStatus === "completed") {
    return "finished";
  } else {
    throw new Error("Unknown system status passed to function!");
  }
}

export function getDatesInTimeRange(start, end) {
  // expects start and end to be daypilot dates
  // returns an array of all dates from start (incl) to end (incl)
  const dates = [];
  let currentDate = new DayPilot.Date(start);
  while (currentDate <= end) {
    // break
    dates.push(currentDate);
    currentDate = currentDate.addDays(1);
  }
  return dates;
}

export function getResourceAbsenceDatesMapping(resources) {
  // separate resource to absence times mapping for faster access
  const resourceAbsenceDays = {};
  resources.forEach((resource) => {
    const formattedAbsenceDates = [];
    resource.absence_times.forEach((timespan) => {
      formattedAbsenceDates.push(
        // get dates in timerange works inclusive, so add a day to enddate to get end of absence
        ...getDatesInTimeRange(
          new DayPilot.Date(timespan.start_date),
          new DayPilot.Date(timespan.end_date)
        )
      );
    });
    resourceAbsenceDays[resource.id] = formattedAbsenceDates;
  });
  return resourceAbsenceDays;
}

// ====================================================================================================
// TOP LEVEL MANAGEMENT OF DATA FORMATTING & CALCULATIONS
// ====================================================================================================

export function processStaticChecklistData(
  system,
  checklist,
  resourceAbsenceDays,
  leicaWorkHoursPerDay
) {
  // this generates the checklist objects to be nested in the systemsWithChecklistData object
  // performs all neccessary and possible calculations based off static db information
  // before returning them as part of the checklist data

  // unpack incoming system query object for easier access, and format for downstream usage as needed
  const checklistType = checklist.is_sgr_checklist ? "SGR" : "SIT";
  const formattedHoldTime = checklist.hold_date
    ? formatHoldTime(checklist.hold_date)
    : undefined;
  const checklistStatus = getChecklistStatusBySystemStatus(
    SYSTEM_STATUS_MAPPING[system.status],
    checklistType
  );
  const normalizedWorkshares = normalizeWorkshares(checklist.workshares);
  const currentWorkPackage = getCurrentWorkpackage(
    normalizedWorkshares,
    checklist.last_completed_workpackage
  );
  const steps = checklist.steps;

  // calculate expected/remaining work times starting from the very first WP 101
  // (these are pure work time excluding holds, weekends etc, therefore constant as long as no steps are completed)
  const originallyExpectedWorkHoursTotal =
    calculateOriginallyExpectedWorkHoursFromWorkshares(normalizedWorkshares);

  const workDaysSpentBeforeToday = calculateWorkDaysSpentOnChecklistBeforeToday(
    checklist.start_date,
    checklist.initial_workstation_id,
    formattedHoldTime,
    resourceAbsenceDays,
    checklistStatus
  );

  const minutesSpentToday = calculateMinsSpentOnChecklistToday(
    // only look at steps that were completed today
    steps.filter(
      (step) =>
        step.completion_date === new Date().toISOString().substring(0, 10)
    ),
    leicaWorkHoursPerDay
  );
  const minutesRemainingInCurrentWP = calculateMinsRemainingInCurrentWP(
    // only look at steps that are open and belong to the current workpackage
    steps.filter(
      (step) =>
        step.workpackage === currentWorkPackage &&
        ["open", "inproduction"].includes(STEP_STATUS_MAPPING[step.status])
    )
  );

  const workHoursRemainingAfterCurrentWP =
    calculateWorkHoursRemainingAfterCurrentWP(
      normalizedWorkshares,
      currentWorkPackage
    );

  const currentlyExpectedWorkHoursTotal =
    checklistStatus === "notstarted"
      ? originallyExpectedWorkHoursTotal // if checklist not started, the originally expected work time is the current assumption
      : calculateCurrentlyExpectedTotalWorkHours(
          leicaWorkHoursPerDay,
          workDaysSpentBeforeToday,
          minutesSpentToday,
          minutesRemainingInCurrentWP,
          workHoursRemainingAfterCurrentWP
        );

  // the calculation above includes all work hours from startdate, so all time spent before WP 122/SIT production starts
  // to calculate the duration the system will spend (prospectively) in actual workstation production
  // (i. e., full SGR production, but SIT only after WP 122/system status inproduction),
  // repeat duration calculation for only the steps after WP 121 - this is important for bar duration on factoryboard
  // and deviation only within SIT production phase

  // get the date where production at the actual workstation is scheduled to start
  // for SIT checklists that are already scheduled, this is the date set in the "sit_start_date" property, 
  // which is updated on factoryboard edits and when WP 122 is started in checklist 
  // for SGR checklists, workstation start date equals the start of the first WP
  const workstationStartdatum = getWorkstationStartdatum(
    checklist.last_completed_workpackage,
    normalizedWorkshares,
    checklist.start_date,
    checklist.sit_start_date,
    leicaWorkHoursPerDay
  );
  const originallyExpectedWorkHoursAtWorkstation = checklist.is_sgr_checklist
    ? originallyExpectedWorkHoursTotal
    : calculateOriginallyExpectedWorkHoursFromWorkshares(
        normalizedWorkshares,
        122 // sit production starts at workpackage 122, start from here if we only want the time at the workstation itself
      );
  // only look at steps that actually happen *at the workstation*, not before wp 122
  const workstationSteps = checklist.is_sgr_checklist
    ? steps
    : steps.filter((step) => parseInt(step.workpackage) >= 122);
  const workDaysSpentBeforeTodayAtWorkstation = checklist.is_sgr_checklist
    ? workDaysSpentBeforeToday
    : calculateWorkDaysSpentOnChecklistBeforeToday(
        workstationStartdatum,

        checklist.initial_workstation_id,
        formattedHoldTime,
        resourceAbsenceDays,
        checklistStatus
      );

  const minutesSpentTodayAtWorkstation = checklist.is_sgr_checklist
    ? minutesSpentToday
    : calculateMinsSpentOnChecklistToday(
        // only look at steps that were completed today
        workstationSteps.filter(
          (step) =>
            step.completion_date === new Date().toISOString().substring(0, 10)
        ),
        leicaWorkHoursPerDay
      );

  const minutesRemainingInCurrentWPAtWorkstation = checklist.is_sgr_checklist
    ? minutesRemainingInCurrentWP
    : calculateMinsRemainingInCurrentWP(
        // only look at steps that are open and belong to the current workpackage
        workstationSteps.filter(
          (step) =>
            step.workpackage === currentWorkPackage &&
            ["open", "inproduction"].includes(STEP_STATUS_MAPPING[step.status])
        )
      );

  const workHoursRemainingAfterCurrentWPAtWorkstation =
    checklist.is_sgr_checklist
      ? workHoursRemainingAfterCurrentWP
      : calculateWorkHoursRemainingAfterCurrentWP(
          normalizedWorkshares,
          // pass the bigger one out of current WP and WP 122 to ensure only considering times after that
          Math.max(parseInt(currentWorkPackage), 122).toString()
        );

  const currentlyExpectedWorkHoursInWorkstation = checklist.is_sgr_checklist
    ? currentlyExpectedWorkHoursTotal
    : checklistStatus === "notstarted"
    ? originallyExpectedWorkHoursAtWorkstation // if checklist not started, the originally expected work time is the current assumption
    : calculateCurrentlyExpectedTotalWorkHours(
        leicaWorkHoursPerDay,
        workDaysSpentBeforeTodayAtWorkstation,
        minutesSpentTodayAtWorkstation,
        minutesRemainingInCurrentWPAtWorkstation,
        workHoursRemainingAfterCurrentWPAtWorkstation
      );

  // get the deviation from expected work time; for SIT, this only considers times starting with WP 122
  const deviationFromScheduleInWorkstationInWorkHours =
    checklistStatus === "started" // if the checklist was started, deviation can be calculated
      ? calculateDeviationFromChecklistScheduleInHours(
          originallyExpectedWorkHoursAtWorkstation,
          currentlyExpectedWorkHoursInWorkstation
        )
      : 0; // if checklist is not started, it's fine to just assume a deviation of 0

  const checklistData = {
    status: checklistStatus,
    isSrChecklist: checklist.is_sgr_checklist,
    checklistId: checklist.id,
    checklistStartdatum: checklist.start_date,
    workstationStartdatum: workstationStartdatum,
    enddatum: checklist.end_date,
    holdTime: formattedHoldTime,
    note: checklist.note,
    lastCompletedWorkpackage: checklist.last_completed_workpackage,
    currentWorkPackage: currentWorkPackage,
    workstation: checklist.initial_workstation_id,
    assignedEmployee: checklist.employee?.id,
    workshares: normalizedWorkshares,
    originallyExpectedWorkHoursTotal: originallyExpectedWorkHoursTotal,
    originallyExpectedWorkDaysTotal:
      originallyExpectedWorkHoursTotal / leicaWorkHoursPerDay,
    minutesSpentToday: minutesSpentToday,
    minutesRemainingInCurrentWP: minutesRemainingInCurrentWP,
    workHoursRemainingAfterCurrentWP: workHoursRemainingAfterCurrentWP,
    workDaysSpentBeforeToday: workDaysSpentBeforeToday,
    currentlyExpectedWorkHoursTotal: currentlyExpectedWorkHoursTotal,
    deviationFromScheduleInWorkHours:
      deviationFromScheduleInWorkstationInWorkHours,
    // times spent specifically at the workstation (i. e., after WP 121)
    originallyExpectedWorkHoursAtWorkstation:
      originallyExpectedWorkHoursAtWorkstation,
    originallyExpectedWorkDaysAtWorkstation:
      originallyExpectedWorkHoursAtWorkstation / leicaWorkHoursPerDay,
    workDaysSpentBeforeTodayAtWorkstation:
      workDaysSpentBeforeTodayAtWorkstation,
    minutesSpentTodayAtWorkstation: minutesSpentTodayAtWorkstation,
    minutesRemainingInCurrentWPAtWorkstation:
      minutesRemainingInCurrentWPAtWorkstation,
    workHoursRemainingAfterCurrentWPAtWorkstation:
      workHoursRemainingAfterCurrentWPAtWorkstation,
    currentlyExpectedWorkHoursInWorkstation:
      currentlyExpectedWorkHoursInWorkstation,
  };
  return checklistData;
}
