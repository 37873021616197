import {
  all,
  fork,
  put,
  call,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import {
  LEICA_GET_ADMINDASHBOARD_SETTINGS,
  getAdminDashboardSettingsSuccess,
  getAdminDashboardSettingsError,
  LEICA_SET_ADMINDASHBOARD_SETTINGS,
  setAdminDashboardSettingsSuccess,
  setAdminDashboardSettingsError,
  LEICA_GET_PARTTEMPLATES,
  getLeicaPartTemplatesSuccess,
  getLeicaPartTemplatesError,
  LEICA_CREATE_OR_UPDATE_PARTTEMPLATES,
  createOrUpdateLeicaPartTemplatesSuccess,
  createOrUpdateLeicaPartTemplatesError,
  LEICA_DELETE_PARTTEMPLATES,
  deleteLeicaPartTemplatesError,
  deleteLeicaPartTemplatesSuccess,
  LEICA_GET_SYSTEMS_FOR_SYSTEM_DB,
  getLeicaSystemsForSystemDBSuccess,
  getLeicaSystemsForSystemDBError,
  LEICA_GET_RESOURCES,
  getLeicaResourcesSuccess,
  getLeicaResourcesError,
  LEICA_GET_EMPLOYEES,
  getLeicaEmployeesSuccess,
  getLeicaEmployeesError,
  LEICA_GET_OVERVIEW,
  getLeicaSystemOverviewSuccess,
  getLeicaSystemOverviewError,
  LEICA_SET_STEPCOMPLETE,
  setLeicaStepCompleteSuccess,
  setLeicaStepCompleteError,
  LEICA_TOGGLE_WORKSTATUS,
  toggleLeicaWorkStatusSuccess,
  toggleLeicaWorkStatusError,
  LEICA_GET_ISSUES_BY_ID,
  getLeicaIssuesByIdSuccess,
  getLeicaIssuesByIdError,
  LEICA_GET_CHECKLIST_BY_STEPIDS,
  getLeicaChecklistByStepIdsSuccess,
  getLeicaChecklistByStepIdsError,
  LEICA_PUT_ISSUE,
  putLeicaIssueSuccess,
  putLeicaIssueError,
  LEICA_DELETE_SYSTEM,
  deleteLeicaSystemSuccess,
  deleteLeicaSystemError,
  LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS,
  submitExcelWithMappedHeadersSuccess,
  submitExcelWithMappedHeadersError,
  LEICA_SUBMIT_NEW_SYSTEM,
  submitNewLeicaSystemSuccess,
  submitNewLeicaSystemError,
  LEICA_UPDATE_SYSTEM_CONFIG,
  updateLeicaSystemConfigError,
  updateLeicaSystemConfigSuccess,
  LEICA_GET_DOWNTIMEKPI,
  getLeicaDowntimeKPIsSuccess,
  getLeicaDowntimeKPIsError,
  LEICA_PUT_SONDERNUMMERN,
  putLeicaSondernummernSuccess,
  putLeicaSondernummernError,
  LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA,
  getLeicaSystemsWithChecklistDataSuccess,
  getLeicaSystemsWithChecklistDataError,
  LEICA_GET_FACTORYBOARD_DATA,
  getLeicaFactoryboardDataSuccess,
  getLeicaFactoryboardDataError,
  LEICA_GET_DEPARTUREBOARD_DATA,
  getLeicaDepartureboardDataSuccess,
  getLeicaDepartureboardDataError,
  LEICA_RESCHEDULE_SYSTEMS,
  rescheduleLeicaSystemsSuccess,
  rescheduleLeicaSystemsError,
  LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD,
  autoOrganizeSystemsOnFactoryboardSuccess,
  autoOrganizeSystemsOnFactoryboardError,
  LEICA_AUTOSCHEDULE_NEW_SYSTEM,
  autoscheduleNewLeicaSystemSuccess,
  autoscheduleNewLeicaSystemError,
  getLeicaSystemsWithChecklistData,
  LEICA_PUT_RESOURCE_ABSENCE_TIMES,
  putLeicaResourceAbsenceTimesSuccess,
  putLeicaResourceAbsenceTimesError,
  // LEICA_PUT_HOLD_TIME,
  // putLeicaHoldTimeSuccess,
  // putLeicaHoldTimeError,
  // LEICA_DELETE_HOLD_TIME,
  // deleteLeicaHoldTimeSuccess,
  // deleteLeicaHoldTimeError,
  LEICA_GET_HOLD_TIMES,
  getLeicaSystemHoldTimesSuccess,
  getLeicaSystemHoldTimesError,
  LEICA_CREATE_HOLDTIME,
  createLeicaHoldTimeSuccess,
  createLeicaHoldTimeError,
  LEICA_DELETE_HOLDTIME,
  deleteLeicaHoldTimeSuccess,
  deleteLeicaHoldTimeError,
  LEICA_UPDATE_HOLDTIME,
  updateLeicaHoldTimeSuccess,
  updateLeicaHoldTimeError,
  LEICA_UPDATE_CHECKLIST,
  updateLeicaChecklistSuccess,
  updateLeicaChecklistError,
  LEICA_UPDATE_SONDERRAUM_CHECKLIST,
  updateLeicaSonderraumChecklistSuccess,
  updateLeicaSonderraumChecklistError,
} from "../actions";

// import mockoverview from "../../data/leica/mock_overviewdata";
// import mockdepartures from "../../data/leica/mock_departuredata";
// import mockChecklist from "../../data/leica/mock_checklistdata";
// import mockSystemList from "../../data/leica/mock_grabSystems";

import WorkTimeTemplate from "../../data/leica/WorkTimeTemplate";
import IssueTemplate from "../../data/leica/IssueTemplate";
import ResourceTemplate from "../../data/leica/ResourceTemplate";
// import workpackageArray from "../../data/leica/deprecated_workpackages";
import SondernummernStepTemplate from "../../data/leica/sondernummernStepTemplate";
import Step from "../../data/leica/Step";
import ChecklistTemplate from "../../data/leica/checklist";
import SonderraumChecklistTemplate from "../../data/leica/sonderraumChecklist";
import SystemTemplate from "../../data/leica/systemTemplate";
import HoldDatesTemplate from "../../data/leica/HoldDatesTemplate";
import PartTemplateTemplate from "../../data/leica/partTemplate";

import { getEnddateByStartDateAndWorkingDays } from "../../helpers/formatFactoryboardData";

import * as selectors from "./selectors";
import * as api from "../../api";
import * as leicaServiceApi from "../../leicaServiceApi";
import * as evadb from "../../helpers/evadb";
import checklistTemplate from "../../data/leica/checklist";
import { number } from "yup";
import { forEach } from "ramda";
import { DayPilot, DayPilotScheduler, DayPilotQueue } from "daypilot-pro-react";
import { initial, update } from "lodash";
import leica from "../../views/app/applications/leica";
import {
  calculateMinsRemainingInCurrentWP,
  calculateMinsRemainingInCurrentWorkpackage,
  calculateMinsSpentOnChecklistToday,
  calculateOriginallyExpectedWorkHoursForChecklist,
  processStaticChecklistData,
  checkIsNonEmptyValue,
  formatHoldTime,
  getChecklistStatusBySystemStatus,
  getCurrentWorkpackage,
  getResourceAbsenceDatesMapping,
  normalizeAllEmptyValuesInObject,
  normalizeWorkshares,
} from "../../helpers/checklistDataCalculation";
import {
  formatResourceDataForFactoryboard,
  formatSystemDataForFactoryboard,
} from "../../helpers/formatFactoryboardData";
import { Typography } from "antd";

const systemTemplateId = "1fc4e41ff14343f8ade5438a25aea986";
const StepTemplateTemplateId = "2babe4ed09564eec81669e9b72026150";
const resourceTemplateId = "1c10a7e19ce14d1fbaf3ae93ff0cec56";
const employeeTemplateId = "7af105c5b5644688941283db3e54b117";
const partTemplateId = "fff815abb4774f139f8073aa23b16a14";
const issueTemplateId = "c0ab1c843ca9473ead4554e246779060";
const checklistTemplateId = "021a22690f044896b9243e62a5c2e15a";
const sgrChecklistTemplateId = "bd27be8bf07e498cbecd9943a5c72ab5";
const settingsInstanceId = "ef720589ab1f4c6aa9c3b0d85cc2d7d5"; // there should always be exactly one settings instance

const DowntimePlans = {"SIT": 65, "Final": 60, "Total": 125, "Modulbau": 125};

function getDowntimeDataInTimeframe(
  systemlist,
  startdate,
  enddate
) {

  let results = {"totalDowntimeSIT": 0, "totalDowntimeFinal": 0, "totalDowntimeTotal": 0, "totalDowntimeModulbau": 0, "systemCountInPlanSIT": 0, "systemCountInPlanFinal":0, "systemCountInPlanTotal": 0, "systemCountInPlanModulbau": 0, "systemCountTotal": 0}
  for (const [systemNr, systemdata] of Object.entries(systemlist)) {
    if(systemdata.enddate >= startdate & systemdata.enddate <= enddate)
    {
      results.systemCountTotal += 1;
      results.totalDowntimeSIT += systemdata.downtime.SIT;
      results.totalDowntimeFinal += systemdata.downtime.Final;
      results.totalDowntimeTotal += systemdata.downtime.SIT;
      results.totalDowntimeTotal += systemdata.downtime.Final;
      results.totalDowntimeModulbau += systemdata.downtime.Modulbau;

      if(systemdata.downtime.SIT <= DowntimePlans.SIT)
      {
        results.systemCountInPlanSIT ++ ;
      }
      if(systemdata.downtime.Final <= DowntimePlans.Final)
      {
        results.systemCountInPlanFinal ++ ;
      }
      if(systemdata.downtime.SIT + systemdata.downtime.Final <= DowntimePlans.Total)
      {
        results.systemCountInPlanTotal ++ ;
      }
      if(systemdata.downtime.Modulbau <= DowntimePlans.Modulbau)
      {
        results.systemCountInPlanModulbau ++;
      }
    }
  }
  return results;
}

//returns Date or String if no Date can be determined
function getDepartureBoardCompletionStatus( checklistData, hasSgrChecklist, leicaWorkHoursPerDay, resourceAbsenceDays ){

  let completionStatus;

  if (typeof checklistData.checklistStartdatum == "undefined")
  {
    completionStatus = hasSgrChecklist ? "SGR not scheduled" : "SIT not scheduled";
  }
  else if ( new Date() - new Date (checklistData.checklistStartdatum) < 0 )
  {
    completionStatus = calculateDepartureBoardCompletionDate(
      checklistData,
      leicaWorkHoursPerDay,
      resourceAbsenceDays
      )
  }
  else
  {
    completionStatus = hasSgrChecklist ? "SGR not scheduled" : "SIT start overdue";
  }
  return completionStatus;
}

function calculateDepartureBoardCompletionDate( checklistData, leicaWorkHoursPerDay, resourceAbsenceDays ){
  return getEnddateByStartDateAndWorkingDays(
    checklistData,
    checklistData.currentlyExpectedWorkHoursInWorkstation / leicaWorkHoursPerDay,
    resourceAbsenceDays
    ).toString("yyyy-MM-dd");
}

function calculateSystemSchedule(
  systemData,
  resourceData,
  leicaWorkHoursPerDay
) {
  // transform loaded system/checklist data to events
  const events = [];
  const unassignedSystems = [];

  const [resources, resourceAbsenceDays, geparktResourceId] = resourceData;

  systemData.forEach((system) => {
    let event = {
      id: system.systemId,
      text: system.systemNrLeica,
      status: system.status,
      checklistData: {},
      srChecklistData: {},
      lastPossibleCompletionDate: new DayPilot.Date(
        system.lastPossibleCompletionDate
      ),
      customerExpectanceDate: new DayPilot.Date(system.customerExpectanceDate),
    };

    const checklists = [system.checklistData, system.srChecklistData];

    checklists.forEach((checklist) => {
      // flag to check if checklist is valid and non-empty (empty checklists can happen with (legacy only?) sr checklists)
      const checklistIsValid = Boolean(
        !checklist.isSrChecklist ||
          checklist.workshares ||
          // checklist.startdatum || (
          (checklist.isLegacySrChecklist &&
            checklist.sonderraumChecklistDurationInDays > 0)
      );

      // only continue to create events for valid checklists
      if (checklistIsValid) {
        // determine currently set start and end date of this checklist
        let checklistStartdatum = checklist.startdatum
          ? new DayPilot.Date(checklist.startdatum)
          : undefined;
        let checklistEnddatum = undefined; // factual expected enddate, accounts for holds, non-business days and delays in production
        let checklistTheoreticalEnddatum = checklist.enddatum
          ? new DayPilot.Date(checklist.enddatum)
          : undefined; // originally determined enddate, does not account for holds or non-business days
        // let checklistTheoreticalEnddatumIncludingHoldsAndWeekends = undefined
        let checklistDurationInPT = 0; // factual duration including delays, includes holds and absences
        let checklistDeviationInPT = 0; // absences and holds are not a deviation, these will not be part of this number (only production delays)
        let checklistPercentComplete = 0; // as above, absences and holds will be ignored for the completion degree
        let checklistExpectedDurationWithoutHoldsInPT = 0; // factual duration including delays, without holds
        let checklistTimeRemainingWithoutHoldsInPT = 0;
        let checklistTheoreticalDurationOfWholeChecklistInPT = 0; // pure working times, excludes all holds, weekends and absences
        let checklistTotalDurationOfHoldsInDays = 0; // add duration of all holds together (currently always max. 1 hold), includes non-business days
        let checklistTotalDurationOfHoldsInPT = 0; // like above, but excluding non-business days
        if (checklist.isLegacySrChecklist) {
          checklistTheoreticalDurationOfWholeChecklistInPT =
            checklist.sonderraumChecklistDurationInDays;
        } else {
          // as of LOMP4-167 to 170, workshares are now in hours instead of PT
          // only include workshares from 122 upwards in duration calculation
          const checklistTheoreticalDurationOfWholeChecklistInHours =
            Object.values(
              Object.fromEntries(
                Object.entries(checklist.workshares).filter(
                  (wp, duration) =>
                    checklist.isSrChecklist || parseInt(wp) > 121
                )
              )
            ).reduce((a, b) => a + b, 0);
          checklistTheoreticalDurationOfWholeChecklistInPT =
            checklistTheoreticalDurationOfWholeChecklistInHours /
            leicaWorkHoursPerDay;
        }
        const checklistIsCurrentlyActive =
          (!["newsystem", "transitiontosgr"].includes(system.status) &&
            !checklist.isSrChecklist &&
            ["started", "inproduction"].includes(system.status)) ||
          (checklist.isSrChecklist &&
            ["sgrinproduction"].includes(system.status));
        const checklistIsComplete = checklist.isSrChecklist
          ? false
          : ["transitiontosgr", "sgrinproduction"].includes(system.status);
        const checklistIsNotStarted =
          !checklistIsCurrentlyActive && !checklistIsComplete;

        // holds an array of all work and hold blocks that make up the production process of this checklist
        let initialTimeBlock = {
          isHold: false,
          isCurrentlyRunning: undefined,
          // below: this should be "Extend" (make system bar longer) for the last time block of a currently running checklist
          // or "None" (letotalDowntimee at same length) else, to align with following time blocks)
          behtotalDowntimeiourOverAbsences: undefined,
          resource: checklist.workstation,
          start: checklistStartdatum,
          end: undefined,
          durationInDays: undefined, // factual/expected considering delays, includes non-business days
          // deviationInPT: undefined,
        };
        const productionTimeBlocks = [initialTimeBlock];

        // if there is exactly one hold on this checklist (currently can only be 1 or 0)
        if (typeof checklist.holdTimes === "object") {
          const holdStart = new DayPilot.Date(checklist.holdTimes.start);
          const holdEnd = new DayPilot.Date(checklist.holdTimes.end);
          const holdDurationInDays = new DayPilot.Duration(holdStart, holdEnd);

          let timeBlockDuringHold = {
            ...initialTimeBlock,
            isHold: true,
            resource: checklist.holdTimes.resourceDuringHold,
            start: holdStart,
            end: holdEnd,
            durationInDays: holdDurationInDays.days() + 1,
          };
          let timeBlockAfterHold = {
            ...initialTimeBlock,
            isHold: false,
            resource: checklist.holdTimes.resourceAfterHold,
            start: new DayPilot.Date(holdEnd.addDays(1)),
            end: undefined,
            durationInDays: undefined,
          };
          productionTimeBlocks.push(timeBlockDuringHold, timeBlockAfterHold);
          // set end of initial production time block to start of hold
          productionTimeBlocks[0] = {
            ...productionTimeBlocks[0],
            end: new DayPilot.Date(holdStart.addDays(-1)),
            durationInDays: productionTimeBlocks[0].start
              ? new DayPilot.Duration(
                  productionTimeBlocks[0].start,
                  holdStart.addDays(-1)
                ).days() + 1
              : undefined,
          };
          // set total checklist duration of holds
          checklistTotalDurationOfHoldsInDays =
            timeBlockDuringHold.durationInDays;
          checklistTotalDurationOfHoldsInPT = 0;
          for (
            let daysChecked = 0;
            daysChecked <= checklistTotalDurationOfHoldsInDays;
            daysChecked++
          ) {
            // break
            const currentDayIsWeekend = [0, 6].includes(
              holdStart.addDays(daysChecked).getDayOfWeek()
            );
            if (!currentDayIsWeekend) {
              checklistTotalDurationOfHoldsInPT++;
            }
          }
        }

        const today = new DayPilot.Date().getDatePart();

        // set checklist to expand the last time block over absences, but not the others
        productionTimeBlocks.forEach(
          (block) => (block.behtotalDowntimeiourOverAbsences = "None")
        );
        productionTimeBlocks[
          productionTimeBlocks.length - 1
        ].behtotalDowntimeiourOverAbsences = "Expand";

        // determine which production time block is currently running, and also set behtotalDowntimeiour during absences accordingly
        if (!checklistIsCurrentlyActive) {
          productionTimeBlocks.forEach((block) => {
            block.isCurrentlyRunning = false;
          });
        } else {
          // check if more than 1 time block exists (caused by holds)
          if (productionTimeBlocks.length > 1) {
            let activeBlockFound = false;
            // loop the production time blocks backwards and find the latest one that was started (i. e., start date is before today)
            for (let idx = productionTimeBlocks.length - 1; idx >= 0; idx--) {
              // break
              if (
                productionTimeBlocks[idx].start < today &&
                !activeBlockFound
              ) {
                productionTimeBlocks[idx].isCurrentlyRunning = true;
                activeBlockFound = true;
              } else {
                productionTimeBlocks[idx].isCurrentlyRunning = false;
              }
            }
          } else {
            productionTimeBlocks[0].isCurrentlyRunning = true;
          }
        }

        // calculate duration of whole system

        // if its not started, the duration will be the predicted production duration plus holds
        if (checklistIsNotStarted) {
          checklistExpectedDurationWithoutHoldsInPT =
            checklistTheoreticalDurationOfWholeChecklistInPT;
          checklistTimeRemainingWithoutHoldsInPT =
            checklistTheoreticalDurationOfWholeChecklistInPT;
          checklistDurationInPT =
            checklistExpectedDurationWithoutHoldsInPT +
            checklistTotalDurationOfHoldsInPT;
          // if a startdate is known, calculate the enddate of the checklist using duration
          if (checklistStartdatum) {
            // walk day by day from startdate until the duration is complete, to make sure we're not missing out on any weekends etc
            checklistEnddatum = new DayPilot.Date(checklistStartdatum);
            // since the last day (enddatum) is inclusive, we are incrementing from 1
            // --> a system with same start and end date would htotalDowntimee a current duration of 1 day
            let currentDurationInPT = 1;
            let currentTimeBlockIdx = 0;

            const startResource = productionTimeBlocks[0].resource;
            const startDayIsAbsence =
              startResource in resourceAbsenceDays &&
              resourceAbsenceDays[startResource].includes(checklistStartdatum);
            if (startDayIsAbsence) {
              // if we are starting on an absence day, don't count start day for duration
              currentDurationInPT = 0;
            }
            while (currentDurationInPT <= checklistDurationInPT) {
              checklistEnddatum = checklistEnddatum.addDays(1);
              const reachedEndOfCurrentTimeBlock =
                productionTimeBlocks[currentTimeBlockIdx].end &&
                checklistEnddatum >
                  productionTimeBlocks[currentTimeBlockIdx].end;
              if (reachedEndOfCurrentTimeBlock) {
                currentTimeBlockIdx++;
              }
              const currentDayIsWeekend = [0, 6].includes(
                checklistEnddatum.getDayOfWeek()
              );
              const currentResource =
                productionTimeBlocks[currentTimeBlockIdx].resource;
              const currentDayIsAbsence =
                currentResource in resourceAbsenceDays &&
                resourceAbsenceDays[currentResource].includes(
                  checklistEnddatum
                );
              // ignore absences during hold because they will not cause delays
              const ignoreAbsence =
                productionTimeBlocks[currentTimeBlockIdx].isHold;
              const isCountableBusinessDay =
                !currentDayIsWeekend && (!currentDayIsAbsence || ignoreAbsence);
              if (isCountableBusinessDay) {
                currentDurationInPT++;
              }
            }
            // assign the checklist enddate as enddate of the last time block as well
            productionTimeBlocks[productionTimeBlocks.length - 1] = {
              ...productionTimeBlocks[productionTimeBlocks.length - 1],
              end: new DayPilot.Date(checklistEnddatum),
              durationInDays: new DayPilot.Duration(
                productionTimeBlocks[productionTimeBlocks.length - 1].start,
                checklistEnddatum
              ),
            };
          } else {
            // if no startdate is known, the duration is the expected duration based off the workshares
            // in this case, the system should not be able to htotalDowntimee holds,
            // as holds can only be created for systems in production, which can vice-versa not be unscheduled
            productionTimeBlocks[productionTimeBlocks.length - 1] = {
              ...productionTimeBlocks[productionTimeBlocks.length - 1],
              durationInDays: new DayPilot.Duration(checklistDurationInPT),
            };
          }
        } else {
          // if the checklist is already started (i.e., in production or already finished), the expected duration needs to account for delays
          // calculate time spent before today in PT from time between start and today, excluding non-business days, holds and absences
          let checklistTimeSpentBeforeTodayInPurePT = 0; // excludes absences and holds completely
          let checklistTimeSpentBeforeTodayInPT = 0; // includes holds and (implicitly) absences during holds, but ignores weekends and absences during productive times - i.e., everything that should be considered when calculating deviation from schedule
          let currentDate = new DayPilot.Date(checklistStartdatum);
          let currentTimeBlockIdx = 0;
          while (currentDate < DayPilot.Date.today()) {
            // break
            const isWeekend = [0, 6].includes(currentDate.getDayOfWeek());
            const reachedEndOfCurrentTimeBlock =
              productionTimeBlocks[currentTimeBlockIdx].end &&
              currentDate > productionTimeBlocks[currentTimeBlockIdx].end;
            if (reachedEndOfCurrentTimeBlock) {
              currentTimeBlockIdx++;
            }
            const currentResource =
              productionTimeBlocks[currentTimeBlockIdx].resource;
            const currentDayIsAbsence =
              currentResource in resourceAbsenceDays &&
              resourceAbsenceDays[currentResource].includes(currentDate);
            // ignore absences during hold because they will not cause delays
            const ignoreAbsence =
              productionTimeBlocks[currentTimeBlockIdx].isHold;
            const isHold = productionTimeBlocks[currentTimeBlockIdx].isHold;
            const isCountableBusinessDay =
              !isWeekend && (!currentDayIsAbsence || ignoreAbsence);
            if (isCountableBusinessDay) {
              checklistTimeSpentBeforeTodayInPT++;
              if (!isHold) {
                checklistTimeSpentBeforeTodayInPurePT++;
              }
            }
            currentDate = currentDate.addDays(1);
          }

          // calculate time spent today in PT
          let checklistTimeSpentTodayInPurePT = 0;
          // check if checklist is already finished, if today is a weekend, absence or hold day because this would mean no effective work was done today (even though hold time may htotalDowntimee passed)
          if (!checklistIsComplete) {
            const currentWorkBlock = productionTimeBlocks.find(
              (block) => block.isCurrentlyRunning
            );
            if (currentWorkBlock) {
              const currentWorkBlockIsHold = currentWorkBlock.isHold;
              const todayIsAbsenceOnCurrentWorkstation =
                currentWorkBlock.resource in resourceAbsenceDays &&
                resourceAbsenceDays[currentWorkBlock.resource].includes(today);
              if (
                !currentWorkBlockIsHold &&
                !todayIsAbsenceOnCurrentWorkstation
              ) {
                // if not, calculate time spent today so far
                checklistTimeSpentTodayInPurePT =
                  checklist.timeSpentTodayInMinutes / 60 / leicaWorkHoursPerDay;
              }
            }
          }

          // add up complete time spent until now, disregarding holds, weekends and absence times completely (these will be added later)
          const checklistTimeSpentUntilNowInPurePT =
            checklistTimeSpentBeforeTodayInPurePT +
            checklistTimeSpentTodayInPurePT;

          // calculate expected remaining time in current system

          // get time remaining in current wp
          let checklistTimeRemainingInCurrentWorkpackageInPT = 0;
          // only include current workpackage in calculation if it's higher than 121
          if (checklist.isSrChecklist || checklist.currentWorkPackage > 121) {
            checklistTimeRemainingInCurrentWorkpackageInPT =
              checklist.timeRemainingInCurrentWorkpackageInMinutes /
              60 /
              leicaWorkHoursPerDay;
          }

          // get time remaining in checklist after current wp
          let checklistTimeRemainingAfterCurrentWorkpackageInPT = 0;
          Object.keys(checklist.workshares)
            // only consider workpackages from 122 upwards
            .filter(
              (workpackage) =>
                workpackage > checklist.currentWorkPackage &&
                (checklist.isSrChecklist || parseInt(workpackage) > 121)
            )
            .forEach((workpackage) => {
              // as of LOMP4-167 to 170, workshares are now in hours instead of PT
              const workpackageDurationInHours =
                checklist.workshares[workpackage];
              checklistTimeRemainingAfterCurrentWorkpackageInPT +=
                workpackageDurationInHours / leicaWorkHoursPerDay;
            });

          // calculate remaining pure production time on checklist (excluding remaining holds)
          const checklistTimeRemainingInPT =
            checklistTimeRemainingInCurrentWorkpackageInPT +
            checklistTimeRemainingAfterCurrentWorkpackageInPT;
          // calculate total prediction of expected production time, including time already spent, time remaining and holds
          const currentlyExpectedDurationOfWholeChecklistInPT =
            checklistTimeSpentUntilNowInPurePT +
            checklistTimeRemainingInPT +
            checklistTotalDurationOfHoldsInPT;
          checklistDurationInPT = currentlyExpectedDurationOfWholeChecklistInPT;

          // calculate current deviation and degree of completion, ignoring holds
          checklistExpectedDurationWithoutHoldsInPT =
            currentlyExpectedDurationOfWholeChecklistInPT -
            checklistTotalDurationOfHoldsInPT;
          checklistTimeRemainingWithoutHoldsInPT = checklistTimeRemainingInPT;

          checklistDeviationInPT =
            checklistExpectedDurationWithoutHoldsInPT -
            checklistTheoreticalDurationOfWholeChecklistInPT;
          checklistPercentComplete =
            ((checklistTheoreticalDurationOfWholeChecklistInPT -
              checklistTimeRemainingWithoutHoldsInPT) /
              checklistTheoreticalDurationOfWholeChecklistInPT) *
            100;

          // calculate prediction for real enddate
          // walk day by day from startdate until the duration is complete, to make sure we're not missing out on any weekends etc
          checklistEnddatum = new DayPilot.Date(today);
          let currentDurationInPT = 1;
          currentTimeBlockIdx = 0;

          while (currentDurationInPT < checklistTimeRemainingInPT) {
            checklistEnddatum = checklistEnddatum.addDays(1);

            const reachedEndOfCurrentTimeBlock =
              productionTimeBlocks[currentTimeBlockIdx].end &&
              checklistEnddatum > productionTimeBlocks[currentTimeBlockIdx].end;
            if (reachedEndOfCurrentTimeBlock) {
              currentTimeBlockIdx++;
            }
            const currentDayIsWeekend = [0, 6].includes(
              checklistEnddatum.getDayOfWeek()
            );
            const currentResource =
              productionTimeBlocks[currentTimeBlockIdx].resource;
            const currentDayIsAbsence =
              currentResource in resourceAbsenceDays &&
              resourceAbsenceDays[currentResource].includes(checklistEnddatum);
            // ignore absences during hold because they will not cause delays
            const ignoreAbsence =
              productionTimeBlocks[currentTimeBlockIdx].isHold;
            const isCountableBusinessDay =
              !currentDayIsWeekend && (!currentDayIsAbsence || ignoreAbsence);
            if (isCountableBusinessDay) {
              currentDurationInPT++;
            }
          }

          // assign the checklist enddate as enddate of the last time block as well
          productionTimeBlocks[productionTimeBlocks.length - 1] = {
            ...productionTimeBlocks[productionTimeBlocks.length - 1],
            end: new DayPilot.Date(checklistEnddatum),
            durationInDays: new DayPilot.Duration(
              productionTimeBlocks[productionTimeBlocks.length - 1].start,
              checklistEnddatum
            ),
          };
        }

        const checklistIsScheduled = !productionTimeBlocks.find(
          (block) =>
            block.start === undefined ||
            block.end === undefined ||
            [undefined, ""].includes(block.resource)
        );
        let checklistData = {
          ...checklist,
          durationInPTExact: checklistDurationInPT,
          durationInPTIntegerCeiled: DayPilot.Duration.ofDays(
            Math.ceil(checklistDurationInPT)
          ),
          expectedDurationWithoutHoldsInPT:
            checklistExpectedDurationWithoutHoldsInPT,
          timeRemainingWithoutHoldsInPT: checklistTimeRemainingWithoutHoldsInPT,
          theoreticalEnddate: checklistTheoreticalEnddatum,
          theoreticalDurationOfWholeSystemInPT:
            checklistTheoreticalDurationOfWholeChecklistInPT,
          complete: checklistPercentComplete,
          deviationInPT: checklistDeviationInPT,
          // currentWorkPackage: checklist.currentWorkPackage,
          // checklistId: checklist.checklistId,
          isScheduled: checklistIsScheduled,
          productionTimeBlocks: productionTimeBlocks,
          checklistStart: checklistStartdatum,
          checklistEnd: checklistEnddatum,
        };

        if (!checklist.isSrChecklist) {
          event = {
            ...event,
            checklistData: checklistData,
          };
        } else {
          if (checklist.isLegacySrChecklist) {
            checklistData = {
              ...checklistData,
              sonderraumChecklistDurationInDays:
                checklist.sonderraumChecklistDurationInDays,
            };
          }
          event = {
            ...event,
            srChecklistData: checklistData,
          };
        }
      }
    });
    events.push(event);
  });
  return events;
}

function createFactoryboardEvents(
  scheduledSystemData,
  resourceData,
  employees,
  leicaWorkHoursPerDay
) {
  // get proper resource data
  // const resourceAbsenceDays = resourceData.resourceAbsenceDays
  const [resources, resourceAbsenceDays, geparktResourceId] = resourceData;
  const factoryboardEvents = {
    scheduled: [],
    unscheduled: [],
  };

  // iterate over scheduled and unscheduled systems
  scheduledSystemData.forEach((system) => {
    // check which parts of this system are to be displayed on factoryboard
    // for systems with status "transitiontosgr" or "sgrinproduction", the sit checklist should not show on factoryboard
    const showSitChecklist = !["transitiontosgr", "sgrinproduction"].includes(
      system.status
    );

    // configure object for normal (non-sr) event bar
    const systemHasSonderraumChecklist =
      system.srChecklistData &&
      Object.keys(system.srChecklistData).length !== 0 &&
      ((system.srChecklistData?.isLegacySrChecklist &&
        system.srChecklistData?.sonderraumChecklistDurationInDays > 0) ||
        system.srChecklistData?.durationInPTExact !== 0);

    // configure tooltip
    const customerDate = new DayPilot.Date(
      system.customerExpectanceDate
    ).toString("MMMM dd, yyyy");
    const latestDate = new DayPilot.Date(
      system.lastPossibleCompletionDate
    ).toString("MMMM dd, yyyy");

    // const durationLastDayInMins =
    //   Math.round((system.durationInPTExact % 1) * leicaWorkHoursPerDay * 60);
    // const m = Math.round(durationLastDayInMins % 60);
    // const h = Math.round((durationLastDayInMins - m) / 60);

    const timeRemainingWithoutHoldsInMins = Math.round(
      system.checklistData.timeRemainingWithoutHoldsInPT *
        leicaWorkHoursPerDay *
        60
    );
    const mTimeRemaining = Math.round(timeRemainingWithoutHoldsInMins % 60);
    const hTimeRemaining = Math.round(
      (timeRemainingWithoutHoldsInMins - mTimeRemaining) / 60
    );

    const theoreticalDurationOfWholeSystemInMins = Math.round(
      system.checklistData.theoreticalDurationOfWholeSystemInPT *
        leicaWorkHoursPerDay *
        60
    );
    const mTheoreticalDuration = Math.round(
      theoreticalDurationOfWholeSystemInMins % 60
    );
    const hTheoreticalDuration = Math.round(
      (theoreticalDurationOfWholeSystemInMins - mTheoreticalDuration) / 60
    );

    const normalChecklistTooltipFragmentForSrChecklist = `
                    <i>Originally expected work time in SIT:</i> ${hTheoreticalDuration} hr${
      hTheoreticalDuration === 1 ? "" : "s"
    }, ${mTheoreticalDuration} min${mTheoreticalDuration === 1 ? "" : "s"}<br/>
                    <i>Work time remaining until completion in SIT:</i> ${hTimeRemaining} hr${
      hTimeRemaining === 1 ? "" : "s"
    }, ${mTimeRemaining} min${mTimeRemaining === 1 ? "" : "s"}<br/>
                    <i>Current deviation from schedule in SIT:</i> ${
                      Math.round(system.checklistData.deviationInPT * 100) / 100
                    } day${
      system.checklistData.deviationInPT === 1 ? "" : "s"
    }`;

    const normalChecklistTooltip = `<strong>System ${system.text}</strong><br/> 
                    <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
                    <i>Current workpackage:</i> ${
                      ["transitiontosgr", "sgrinproduction"].includes(
                        system.status
                      )
                        ? system.srChecklistData.currentWorkPackage
                        : system.checklistData.currentWorkPackage
                    }<br/> 
                    <i>Originally expected work time:</i> ${hTheoreticalDuration} hr${
      hTheoreticalDuration === 1 ? "" : "s"
    }, ${mTheoreticalDuration} min${mTheoreticalDuration === 1 ? "" : "s"}<br/>
                    <i>Work time remaining until completion:</i> ${hTimeRemaining} hr${
      hTimeRemaining === 1 ? "" : "s"
    }, ${mTimeRemaining} min${mTimeRemaining === 1 ? "" : "s"}<br/>
                    <i>Current deviation from schedule:</i> ${
                      Math.round(system.checklistData.deviationInPT * 100) / 100
                    } day${
      system.checklistData.deviationInPT === 1 ? "" : "s"
    }<br/>
                    <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
                    <i>Geplanter interner Output Termin:</i> ${latestDate}<br/>
                    <i>OM Date:</i> ${customerDate}<br/>`;

    const normalSystemIsScheduled = system.checklistData.isScheduled;

    system.checklistData.productionTimeBlocks.forEach((block, idx) => {
      let event = {
        id: system.id.toString() + "#" + idx.toString(),
        status: system.status,
        customerExpectanceDate: system.customerExpectanceDate,
        lastPossibleCompletionDate: system.lastPossibleCompletionDate,
        text: systemHasSonderraumChecklist ? "S-" + system.text : system.text,
        duration: system.checklistData.durationInPTIntegerCeiled,
        bubbleHtml: normalChecklistTooltip,
        conflicts: [],
        hasSonderraumEvent: systemHasSonderraumChecklist,
        ...block,
        ...system.checklistData,
      };

      if (showSitChecklist) {
        if (normalSystemIsScheduled) {
          factoryboardEvents.scheduled.push(event);
        } else {
          factoryboardEvents.unscheduled.push(event);
        }
      }
    });

    // create sonderraum event
    if (systemHasSonderraumChecklist) {
      // sr tooltip
      const srTimeRemainingWithoutHoldsInMins = Math.round(
        system.srChecklistData.timeRemainingWithoutHoldsInPT *
          leicaWorkHoursPerDay *
          60
      );
      const srMTimeRemaining = Math.round(
        srTimeRemainingWithoutHoldsInMins % 60
      );
      const srHTimeRemaining = Math.round(
        (srTimeRemainingWithoutHoldsInMins - srMTimeRemaining) / 60
      );

      const srTheoreticalDurationOfWholeSystemInMins = Math.round(
        system.srChecklistData.theoreticalDurationOfWholeSystemInPT *
          leicaWorkHoursPerDay *
          60
      );
      const srMTheoreticalDuration = Math.round(
        srTheoreticalDurationOfWholeSystemInMins % 60
      );
      const srHTheoreticalDuration = Math.round(
        (srTheoreticalDurationOfWholeSystemInMins - srMTheoreticalDuration) / 60
      );

      const srChecklistTooltip = `<strong>System ${system.text}</strong><br/> 
                    <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
                    <i>Current workpackage:</i> ${
                      ["transitiontosgr", "sgrinproduction"].includes(
                        system.status
                      )
                        ? system.srChecklistData.currentWorkPackage
                        : system.checklistData.currentWorkPackage
                    }<br/> 
                    <i>Originally expected work time:</i> ${srHTheoreticalDuration} hr${
        srHTheoreticalDuration === 1 ? "" : "s"
      }, ${srMTheoreticalDuration} min${
        srMTheoreticalDuration === 1 ? "" : "s"
      }<br/>
                    <i>Work time remaining until completion:</i> ${srHTimeRemaining} hr${
        srHTimeRemaining === 1 ? "" : "s"
      }, ${srMTimeRemaining} min${srMTimeRemaining === 1 ? "" : "s"}<br/>
                    <i>Current deviation from schedule:</i> ${
                      Math.round(system.srChecklistData.deviationInPT * 100) /
                      100
                    } day${
        system.srChecklistData.deviationInPT === 1 ? "" : "s"
      }<br/>
      <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
      <i>Originally expected work time in SIT:</i> ${hTheoreticalDuration} hr${
        hTheoreticalDuration === 1 ? "" : "s"
      }, ${mTheoreticalDuration} min${
        mTheoreticalDuration === 1 ? "" : "s"
      }<br/>
                      <i>Work time remaining until completion in SIT:</i> ${hTimeRemaining} hr${
        hTimeRemaining === 1 ? "" : "s"
      }, ${mTimeRemaining} min${mTimeRemaining === 1 ? "" : "s"}<br/>
                      <i>Current deviation from schedule in SIT:</i> ${
                        Math.round(system.checklistData.deviationInPT * 100) /
                        100
                      } day${
        system.checklistData.deviationInPT === 1 ? "" : "s"
      }
      <br/>
                    <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
                    <i>Geplanter interner Output Termin:</i> ${latestDate}<br/>
                    <i>OM Date:</i> ${customerDate}<br/>`;

      system.srChecklistData.productionTimeBlocks.forEach((block, idx) => {
        let sonderraumEvent = {
          id: "SR-" + system.id + "#" + idx,
          text: "SR-" + system.text,
          status: system.status,
          customerExpectanceDate: system.customerExpectanceDate,
          lastPossibleCompletionDate: system.lastPossibleCompletionDate,
          ...system.srChecklistData,
          duration: system.srChecklistData.durationInPTIntegerCeiled,
          // duration: DayPilot.Duration.ofDays(Math.ceil(system.sonderraumChecklistDurationInDays)),
          normalChecklistEnddate: system.checklistData.checklistEnd,
          status: system.status,
          complete: 0,
          deviationInPT: 0,
          conflicts: [], // tracks the system ids that this system conflicts with
          bubbleHtml: srChecklistTooltip,
          checklistId: system.srChecklistData.checklistId,
          ...block,
        };
        if (system.srChecklistData.isScheduled) {
          factoryboardEvents.scheduled.push(sonderraumEvent);
        } else {
          factoryboardEvents.unscheduled.push(sonderraumEvent);
        }
      });

      if (
        showSitChecklist &&
        systemHasSonderraumChecklist &&
        system.checklistData.isScheduled &&
        system.srChecklistData.isScheduled
      ) {
        const timeDistanceBetweenNormalAndSonderraumSystem =
          system.checklistData.checklistEnd !==
            system.srChecklistData.checklistStart &&
          system.checklistData.checklistEnd !==
            system.srChecklistData.checklistStart.addDays(1);
        if (timeDistanceBetweenNormalAndSonderraumSystem) {
          let geparktEvent = {
            // ...event,
            id: "GEPARKT-" + system.id,
            status: system.status,
            customerExpectanceDate: system.customerExpectanceDate,
            lastPossibleCompletionDate: system.lastPossibleCompletionDate,
            text: systemHasSonderraumChecklist
              ? "S-" + system.text
              : system.text,
            bubbleHtml: normalChecklistTooltip,
            conflicts: [],
            hasSonderraumEvent: systemHasSonderraumChecklist,
            start: system.checklistData.checklistEnd,
            end: system.srChecklistData.checklistStart,
            theoreticalEnddate: system.srChecklistData.checklistStart, // same as normal enddate
            resource: geparktResourceId,
            areas: [],
          };
          factoryboardEvents.scheduled.push(geparktEvent);
        }
      }
    }
  });
  Object.values(factoryboardEvents).forEach((categoryArray) => {});
  return factoryboardEvents;
}

function mapStepsToChecklist(steps, sondernummern) {
  let payloadData = {};
  payloadData.checklist = [];

  let checklist = [];

  let duration = 0;

  steps.forEach((step) => {
    let checklistStepData = {};

    //Get the Workpackage for Template Step
    let workpackage = checklist.find(
      (element) => element.id === step.properties.Arbeitspaket.propertyValue
    );

    //if workpackage not existent create it
    if (typeof workpackage === "undefined") {
      workpackage = {};
      workpackage.id = step.properties.Arbeitspaket.propertyValue;
      workpackage.data = [];
      checklist.push(workpackage);
      workpackage = checklist.find(
        (element) => element.id === step.properties.Arbeitspaket.propertyValue
      );
    }

    //fill StepData with data from StepTemplate
    checklistStepData.stepid = step.properties.Step.propertyValue;
    checklistStepData.option = step.properties.Option.propertyValue;
    checklistStepData.manual = step.properties.Related_Manual?.propertyValue;
    checklistStepData.testProtocol =
      step.properties.TestProtocol?.propertyValue;
    checklistStepData.description = step.properties.Beschreibung.propertyValue;

    if (step.properties.estimatedtime.propertyValue !== "") {
      duration += parseFloat(step.properties.estimatedtime.propertyValue);
    }

    //Get actual step
    // let Step = Steps.find(
    //   (element) =>
    //     element.properties[0].propertyValue === StepTemplate.instanceId
    // );

    // if (step.properties.Status.propertyValue !== "none") {
    //Fill StepData with data from Step
    checklistStepData.timeest =
      step.properties.estimatedtime.propertyValue !== ""
        ? step.properties.estimatedtime.propertyValue
        : 0;
    checklistStepData.status = {
      state: step.properties.Status.propertyValue,
      timespend: step.properties.TimeSpend.propertyValue,
      completionDate: step.properties.Abgeschlossen_am.propertyValue,
    };
    checklistStepData.instanceId = step.instanceId;
    if (checklistStepData.status.state === "inproduction") {
      payloadData.currentWorkStep = checklistStepData.stepid;
      payloadData.currentWorkStepId = step.instanceId;
      payloadData.currentWorkPackage = workpackage.id;
      if (step.properties.Arbeitszeiten.propertyValue !== "") {
        let worktimeIds =
          step.properties.Arbeitszeiten.propertyValue.split(",");
        payloadData.currentWorkTimeId = worktimeIds[worktimeIds.length - 1];
      }
      //   }
      // // } else {
      //   checklistStepData.timeest = 0;
      //   checklistStepData.status = { state: "none", timespend: 0, completionDate: "" };
    }
    workpackage.data.push(checklistStepData);
  });

  // include sondernummern in checklist in proper positions
  sondernummern.forEach((sondernr) => {
    let stepData = {};
    // get workpackage
    const workpackage = checklist.find(
      (workpck) => workpck.id == sondernr.properties.Arbeitspaket.propertyValue
    );
    //fill StepData with data from StepTemplate
    stepData.stepid = sondernr.properties.Step.propertyValue;
    stepData.option = sondernr.properties.Option.propertyValue;
    stepData.manual = sondernr.properties.Related_Manual.propertyValue;
    stepData.testProtocol = sondernr.properties.TestProtocol.propertyValue;
    stepData.description = sondernr.properties.Beschreibung.propertyValue;

    //Get actual step
    // let Step = Steps.find(
    //   (step) =>
    //     step.properties[0].propertyValue === sondernr.instanceId
    // );

    // if (typeof Step !== "undefined") {
    //Fill StepData with data from Step
    stepData.timeest = sondernr.properties.estimatedtime.propertyValue;
    stepData.status = {
      state: sondernr.properties.Status.propertyValue,
      timespend: sondernr.properties.TimeSpend.propertyValue,
    };
    stepData.instanceId = sondernr.instanceId;
    if (stepData.status.state === "inproduction") {
      payloadData.currentWorkStep = stepData.stepid;
      payloadData.currentWorkStepId = sondernr.instanceId;
      payloadData.currentWorkPackage = workpackage.id;
      if (sondernr.properties.Arbeitszeiten.propertyValue !== "") {
        let worktimeIds =
          sondernr.properties.Arbeitszeiten.propertyValue.split(",");
        payloadData.currentWorkTimeId = worktimeIds[worktimeIds.length - 1];
      }
    }
    workpackage.data.splice(
      sondernr.properties["PositionInArbeitspaket"].propertyValue,
      0,
      stepData
    );
  });

  payloadData.checklist = checklist;
  return { ...payloadData };
}

function* fetchAdminDashboardSettings() {
  const settingsInstance = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + settingsInstanceId)
    .then((res) => {
      return res.json();
    });
  for (const prop of settingsInstance.properties) {
    if (isNaN(Number(prop.propertyValue))) {
      yield put(
        getAdminDashboardSettingsError(
          "Setting is not a number: " + prop.propertyValue
        )
      );
    }
  }
  const normalizedSettingsInstance = evadb.normalizeInstance(settingsInstance);
  const settings = {
    leicaWorkHoursPerDay: Number(
      normalizedSettingsInstance?.properties?.WorkHoursPerDay?.propertyValue
    ),
    factors: {
      sit: Number(
        normalizedSettingsInstance?.properties?.FactorSIT?.propertyValue
      ),
      sted: Number(
        normalizedSettingsInstance?.properties?.FactorSTED?.propertyValue
      ),
      dive: Number(
        normalizedSettingsInstance?.properties?.FactorDIVE?.propertyValue
      ),
      smd: Number(
        normalizedSettingsInstance?.properties?.FactorSMD?.propertyValue
      ),
    },
  };
  yield put(getAdminDashboardSettingsSuccess(settings));
}

function* putAdminDashboardSettings({ payload }) {
  const settingsToUpdate = payload;
  const settingsInstance = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + settingsInstanceId)
    .then((res) => {
      return res.json();
    });
  const normalizedSettingsInstance = evadb.normalizeInstance(settingsInstance);
  const settingsToPropertiesMapping = {
    workHours: "WorkHoursPerDay",
    SIT: "FactorSIT",
    STED: "FactorSTED",
    DIVE: "FactorDIVE",
    SMD: "FactorSMD",
  };

  Object.entries(settingsToUpdate).forEach(([setting, newVal]) => {
    normalizedSettingsInstance.properties[
      settingsToPropertiesMapping[setting]
    ].propertyValue = String(newVal);
  });
  const updatedProperties = evadb.denormalizeInstance(
    normalizedSettingsInstance
  ).properties;
  settingsInstance.properties = updatedProperties;
  delete settingsInstance.createdby;
  delete settingsInstance.Created_time;
  delete settingsInstance.stamp;
  delete settingsInstance.Updated_time;
  const apiResponse = yield api
    .postData("/modules/db/instance/Instance", settingsInstance)
    .then((res) => {
      return res;
    });
  if (apiResponse.status !== 200) {
    yield put(setAdminDashboardSettingsError(apiResponse));
  }
  const updatedSettings = {
    leicaWorkHoursPerDay: Number(
      normalizedSettingsInstance?.properties?.WorkHoursPerDay?.propertyValue
    ),
    factors: {
      sit: Number(
        normalizedSettingsInstance?.properties?.FactorSIT?.propertyValue
      ),
      sted: Number(
        normalizedSettingsInstance?.properties?.FactorSTED?.propertyValue
      ),
      dive: Number(
        normalizedSettingsInstance?.properties?.FactorDIVE?.propertyValue
      ),
      smd: Number(
        normalizedSettingsInstance?.properties?.FactorSMD?.propertyValue
      ),
    },
  };
  yield put(setAdminDashboardSettingsSuccess(updatedSettings));
}

function* fetchPartTemplates() {
  const results = yield api
    .getData(
      "/modules/db/instance/GetByTemplateId?templateId=" + partTemplateId,
      {
        token: localStorage.getItem("access_token"),
      }
    )
    .then((res) => {
      return res.json();
    });
  const partTemplates = [];
  results.forEach((instance) => {
    const norm = evadb.normalizeInstance(instance);
    const part = {
      id: norm.instanceId,
      articleNumber: norm.properties["VK-Nummer"].propertyValue,
      option: norm.properties.Option.propertyValue,
      materialDescription: norm.properties.Materialkurztext.propertyValue,
    };
    partTemplates.push(part);
  });
  yield put(getLeicaPartTemplatesSuccess(partTemplates));
}

function* createOrUpdatePartTemplates({ payload }) {
  const partsToAdd = payload.partsToAdd;
  const partsToUpdate = payload.partsToUpdate;

  const normalizedPartTemplate = evadb.normalizeInstance(PartTemplateTemplate);

  // add new parts
  for (const part of partsToAdd) {
    const newInstance = { ...normalizedPartTemplate };
    newInstance.properties["VK-Nummer"].propertyValue = part.articleNumber;
    newInstance.properties["Option"].propertyValue = part.option;
    newInstance.properties["Materialkurztext"].propertyValue =
      part.materialDescription;
    const apiResponse = yield api
      .postData(
        "/modules/db/instance/Instance",
        evadb.denormalizeInstance(newInstance)
      )
      .then((res) => {
        return res;
      });
    if (apiResponse.status !== 200) {
      yield put(createOrUpdateLeicaPartTemplatesError(apiResponse));
    }
  }

  // update existing parts
  for (const part of partsToUpdate) {
    const partInstance = yield api
      .getData("/modules/db/instance/GetInstancebyId?id=" + part.id)
      .then((res) => {
        return res.json();
      });
    const normalizedPartInstance = evadb.normalizeInstance(partInstance);
    normalizedPartInstance.properties["VK-Nummer"].propertyValue =
      part.articleNumber;
    normalizedPartInstance.properties["Option"].propertyValue = part.option;
    normalizedPartInstance.properties["Materialkurztext"].propertyValue =
      part.materialDescription;
    const updatedPartInstance = evadb.denormalizeInstance(
      normalizedPartInstance
    );
    delete updatedPartInstance.createdby;
    delete updatedPartInstance.Created_time;
    delete updatedPartInstance.stamp;
    delete updatedPartInstance.Updated_time;
    const apiResponse = yield api
      .postData(
        "/modules/db/instance/Instance",
        evadb.denormalizeInstance(updatedPartInstance)
      )
      .then((res) => {
        return res;
      });
    if (apiResponse.status !== 200) {
      yield put(createOrUpdateLeicaPartTemplatesError(apiResponse));
    }
  }
  yield call(fetchPartTemplates);
  yield put(createOrUpdateLeicaPartTemplatesSuccess());
}

function* deletePartTemplates({ payload }) {
  const ids = payload;
  const apiResponse = yield api
    .deleteData("/modules/db/instance/DeleteBulk?ids=" + ids.join(","))
    .then((res) => {
      return res;
    });
  if (apiResponse.status !== 200) {
    yield put(deleteLeicaPartTemplatesError(apiResponse));
  }
  yield call(fetchPartTemplates);
  yield put(deleteLeicaPartTemplatesSuccess());
}

function* fetchSystems() {
  // get work hours per day
  // yield call(fetchAdminDashboardSettings);
  // const leicaWorkHoursPerDay = Number(
  //   yield select(selectors.leicaWorkHoursPerDay)
  // );
  const leicaWorkHoursPerDay = 6.5

  // fetch resources
  yield call(fetchResources);
  const resources = yield select(selectors.resources);
  // const resourceAbsenceDays = getResourceAbsenceDatesMapping(resources);
  const resourceAbsenceDays = undefined

  let systems =  yield api    
    .getData("/modules/db/instance/GetByTemplateId?templateId=" + systemTemplateId)
    .then((res) => {
      return res.json();
  });

  //normalize Systems
  let normalizedSystems = [];
  systems.forEach((system) => {
    normalizedSystems.push(evadb.normalizeInstance(system));
  });

  // calculate system schedule for factoryboard
  const formattedSystemData = normalizedSystems.map((system) => {
    return {
      SystemNrLeica: system.properties.SystemNrLeica.propertyValue,
      Status: system.properties.Status.propertyValue,
      systemId: system.instanceId,
      checklistId: system.properties.Checklist.propertyValue,
      sgrChecklistId: system.properties.SonderraumChecklist.propertyValue
    };
  });

  yield put(getLeicaSystemsForSystemDBSuccess([...formattedSystemData]));
}

function* fetchResources() {
  const results = yield api
    .getData(
      "/modules/db/instance/GetByTemplateId?templateId=" + resourceTemplateId,
      {
        token: localStorage.getItem("access_token"),
      }
    )
    .then((res) => {
      return res.json();
    });

  if (typeof results.message === "undefined") {
    const normalizedresults = [];
    results.forEach((result) => {
      normalizedresults.push(evadb.normalizeInstance(result));
    });
    const resources = [];
    normalizedresults.forEach((result) => {
      resources.push({
        id: result.instanceId,
        name: result.properties.Name.propertyValue,
        type: result.properties.Type.propertyValue,
        absenceTimes: result.properties.AbsenceTimes
          ? JSON.parse(result.properties.AbsenceTimes.propertyValue)
          : [],
      });
    });
    yield put(getLeicaResourcesSuccess([...resources]));
  } else {
    yield put(getLeicaResourcesError({ ...results }));
  }
  return Promise.resolve();
}

function* fetchSystemsWithChecklistData(
  resourceAbsenceDays,
  leicaWorkHoursPerDay
) {
  // NB: resources absence days are passed because we check for absences when calculating a checklists work time before today
  // leicaWorkHoursPerDay are passed to convert from count of completed work days to completed work hours

  // get all systems
  const systems = yield api
    .getData(
      "/modules/db/instance/GetByTemplateId?templateId=" + systemTemplateId,
      {
        token: localStorage.getItem("access_token"),
      }
    )
    .then((res) => {
      return res.json();
    });

  const normalizedsystems = [];
  const checklistIds = [];
  const sonderraumChecklistIds = [];
  const checklistToSystemMapping = {};
  const srChecklistToSystemMapping = {};
  systems.forEach((system) => {
    const normalizedSystem = evadb.normalizeInstance(system);
    // only fetch systems that are not completed
    if (
      [
        "newsystem",
        "started",
        "inproduction",
        "onhold",
        "transitiontosgr",
        "sgrinproduction",
      ].includes(normalizedSystem.properties.Status.propertyValue)
    ) {
      checklistIds.push(normalizedSystem.properties.Checklist.propertyValue);
      checklistToSystemMapping[
        normalizedSystem.properties.Checklist.propertyValue
      ] = normalizedSystem;
      srChecklistToSystemMapping[
        normalizedSystem.properties.SonderraumChecklist.propertyValue
      ] = normalizedSystem;
      sonderraumChecklistIds.push(
        normalizedSystem.properties.SonderraumChecklist.propertyValue
      );
      normalizedsystems.push(normalizedSystem);
    }
  });

  // get checklist data for all systems
  let checklistQuery = {
    or: [],
    and: [{ title: "Checklist" }, { instanceId: { $in: checklistIds } }],
    linked_data: 1,
  };
  const systemChecklists = yield api
    .postData("/modules/db/instance/getInstancesByQuery", checklistQuery)
    .then((res) => {
      return res.json();
    });
  const normalizedChecklists = [];

  const stepIds = [];
  // const stepIdsAndWorkpackages = []
  const currentlyActiveWorkpackages = new Set();
  // key-value mapping between each step and the current workpackage of the step's system
  // (this is NOT neccessarily the workpackage OF the step, but the workpackage for which we want to CHECK if the step belongs to it!)
  systemChecklists.forEach((checklist) => {
    const normalizedChecklist = evadb.normalizeInstance(checklist);

    const workshares = normalizeWorkshares(
      normalizedChecklist?.properties?.Workshares?.propertyValue
    );
    const lastCompletedWorkpackage =
      "LastCompletedWorkpackage" in normalizedChecklist.properties
        ? normalizedChecklist.properties.LastCompletedWorkpackage.propertyValue
        : undefined;
    const currentWorkPackage = getCurrentWorkpackage(
      workshares,
      lastCompletedWorkpackage
    );

    currentlyActiveWorkpackages.add(currentWorkPackage);

    // stotalDowntimee the things that we calculated here with the checklist for downstream usage
    normalizedChecklist.additionalData = {
      currentWorkPackage: currentWorkPackage,
      normalizedWorkshares: workshares,
      lastCompletedWorkpackageChecked: lastCompletedWorkpackage,
    };

    // check if a system is in production - if yes, stotalDowntimee checklist steps for downstream calculation of duration
    // NB: it would be easier to use all steps here, but this workaround reduces weight/time of data fetching operation

    if (
      [
        // "started", // by commenting, ignore systems that are before WP 122 because those will be treated like newsystems in duration calculation
        "inproduction",
        "transitiontosgr",
        "sgrinproduction",
      ].includes(
        checklistToSystemMapping[normalizedChecklist.instanceId].properties
          .Status.propertyValue
      )
    ) {
      stepIds.push(
        ...normalizedChecklist.properties.Steps.propertyValue.split(",")
      );
    }
    normalizedChecklists.push(normalizedChecklist);
  });

  // get Sonderraum checklist data for all systems
  let sonderraumChecklistQuery = {
    or: [],
    and: [
      { title: "SonderraumChecklist" },
      { instanceId: { $in: sonderraumChecklistIds } },
    ],
    linked_data: 1,
  };
  const sonderraumChecklists = yield api
    .postData(
      "/modules/db/instance/getInstancesByQuery",
      sonderraumChecklistQuery
    )
    .then((res) => {
      return res.json();
    });
  const normalizedSonderraumChecklists = [];
  sonderraumChecklists.forEach((sonderraumChecklist) => {
    const normalizedSonderraumChecklist =
      evadb.normalizeInstance(sonderraumChecklist);

    // ensure that empty sonderraum checklists are filtered out and will be disregarded from here
    if (!normalizedSonderraumChecklist?.properties?.Workshares?.propertyValue) {
      // in forEach loop, return behtotalDowntimees equivalently to a continue in a conventional for loop
      return;
    }
    const workshares = normalizeWorkshares(
      normalizedSonderraumChecklist?.properties?.Workshares?.propertyValue
    );
    const lastCompletedWorkpackage =
      "LastCompletedWorkpackage" in normalizedSonderraumChecklist.properties
        ? normalizedSonderraumChecklist.properties.LastCompletedWorkpackage
            .propertyValue
        : undefined;
    const currentWorkPackage = getCurrentWorkpackage(
      workshares,
      lastCompletedWorkpackage
    );

    currentlyActiveWorkpackages.add(currentWorkPackage);

    // stotalDowntimee the things that we calculated here with the checklist for downstream usage
    normalizedSonderraumChecklist.additionalData = {
      currentWorkPackage: currentWorkPackage,
      normalizedWorkshares: workshares,
      lastCompletedWorkpackageChecked: lastCompletedWorkpackage,
    };

    // check if a system is in production - if yes, stotalDowntimee checklist steps for downstream calculation of duration
    // NB: it would be easier to use all steps here, but this workaround reduces weight/time of data fetching operation
    if (
      [
        // "started", // ignore systems that are before WP 122 because those will be treated like newsystems in duration calculation
        "inproduction",
        "onhold",
        "transitiontosgr",
        "sgrinproduction",
      ].includes(
        srChecklistToSystemMapping[normalizedSonderraumChecklist.instanceId]
          .properties.Status.propertyValue
      )
    ) {
      if (normalizedSonderraumChecklist.properties?.Steps?.propertyValue) {
        stepIds.push(
          ...normalizedSonderraumChecklist.properties.Steps.propertyValue.split(
            ","
          )
        );
      }
    }
    normalizedSonderraumChecklists.push(normalizedSonderraumChecklist);
  });

  // ----------------------------------------------------------------------------
  // can probably be deleted after checking we don't htotalDowntimee any legacy systems using german format
  const todayGermanFormat = new Date().toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  // ----------------------------------------------------------------------------
  const todayISOFormat = new Date().toISOString().substring(0, 10);
  const todayISOFormatRegex = "^" + todayISOFormat;
  // we need to fetch WP 121 no matter if it's active because we need to know when the last Step was completed (if at all)
  // this corresponds to the startdate of WP 122 which is the startdate of the respective bar on Factoryboard
  currentlyActiveWorkpackages.add("121");
  const currentlyActiveWorkpackagesArray = [...currentlyActiveWorkpackages];

  // fetch steps of the current workpackage or completed today, for all checklists that are in production
  let stepQuery = {
    or: [],
    and: [
      { title: "Step" },
      { instanceId: { $in: stepIds } },
      {
        $or: [
          {
            "properties.8.propertyValue": {
              $in: currentlyActiveWorkpackagesArray,
            },
          },
          { "properties.4.propertyValue": todayGermanFormat }, // can probably be deleted after checking we don't htotalDowntimee any legacy systems using german format
          { "properties.4.propertyValue": { $regex: todayISOFormatRegex } },
        ],
      },
    ],
    linked_data: 1,
  };
  let steps = yield api
    .postData("/modules/db/instance/getInstancesByQuery", stepQuery)
    .then((res) => {
      return res.json();
    });

  const normalizedSteps = [];
  const srNormalizedSteps = [];
  const stepTemplateIds = [];
  steps.forEach((step) => {
    const normalizedStep = evadb.normalizeInstance(step);
    if (
      normalizedStep.properties.Arbeitspaket.propertyValue.startsWith("SMD") ||
      normalizedStep.properties.Arbeitspaket.propertyValue.startsWith("STED") ||
      normalizedStep.properties.Arbeitspaket.propertyValue.startsWith("DIVE")
    ) {
      srNormalizedSteps.push(normalizedStep);
    } else {
      normalizedSteps.push(normalizedStep);
    }
    stepTemplateIds.push(normalizedStep.properties.StepTemplate.propertyValue);
  });

  // go through all systems and match them back together with their checklists/steps
  const allSystemsData = [];
  for (const system of normalizedsystems) {
    const normalizedChecklistInstance = normalizedChecklists.splice(
      normalizedChecklists.findIndex(
        (checklist) =>
          checklist.instanceId == system.properties.Checklist.propertyValue
      ),
      1
    )[0];
    const indexOfMatchingSonderraumChecklist =
      normalizedSonderraumChecklists.findIndex(
        (sonderraumChecklist) =>
          sonderraumChecklist.instanceId ==
          system.properties.SonderraumChecklist.propertyValue
      );
    let sonderraumChecklist = undefined;
    if (indexOfMatchingSonderraumChecklist !== -1) {
      // won't find anything if the sonderraum checklist is empty
      sonderraumChecklist = normalizedSonderraumChecklists.splice(
        normalizedSonderraumChecklists.findIndex(
          (sonderraumChecklist) =>
            sonderraumChecklist.instanceId ==
            system.properties.SonderraumChecklist.propertyValue
        ),
        1
      )[0];
    }

    // fetch hold times for sit and sgr checklists -- there should only be one per checklist
    let holdTime = undefined;
    if (normalizedChecklistInstance.properties.HoldTimes.propertyValue !== "") {
      holdTime = yield api
        .getData(
          "/modules/db/instance/GetInstancebyId?id=" +
            normalizedChecklistInstance.properties.HoldTimes.propertyValue
        )
        .then((res) => {
          return res.json();
        });
    }
    let srHoldTime = undefined;
    if (sonderraumChecklist?.properties?.HoldTimes?.propertyValue) {
      srHoldTime = yield api
        .getData(
          "/modules/db/instance/GetInstancebyId?id=" +
            sonderraumChecklist.properties.HoldTimes.propertyValue
        )
        .then((res) => {
          return res.json();
        });
    }

    // assemble all static data we can get about the system from database
    let systemData = {
      systemId: system.instanceId,
      systemNrLeica: system.properties.SystemNrLeica.propertyValue,
      status: system.properties.Status.propertyValue,
      customerExpectanceDate:
        system.properties.CustomerExpectanceDate.propertyValue,
      lastPossibleCompletionDate:
        system.properties.LatestPossibleCompletionDate.propertyValue,
      checklistData: processStaticChecklistData(
        system,
        normalizedChecklistInstance,
        "SIT",
        normalizedSteps.filter(
          (
            step // filter only those steps from all that belong to current checklist
          ) =>
            normalizedChecklistInstance.properties.Steps.propertyValue
              .split(",")
              .includes(step.instanceId)
        ),
        resourceAbsenceDays,
        holdTime,
        leicaWorkHoursPerDay
      ),
      srChecklistData:
        sonderraumChecklist === undefined
          ? undefined // if no/empty sr checklist, keep the key for consistency, but don't stotalDowntimee any properties as value
          : processStaticChecklistData(
              system,
              sonderraumChecklist,
              "SGR",
              srNormalizedSteps.filter(
                (
                  step // filter only those steps from all that belong to current checklist
                ) =>
                  sonderraumChecklist.properties.Steps.propertyValue
                    .split(",")
                    .includes(step.instanceId)
              ),
              resourceAbsenceDays,
              srHoldTime,
              leicaWorkHoursPerDay
            ),
    };
    // normalize all empty values in system object, then push to list of systems
    systemData = normalizeAllEmptyValuesInObject(systemData);
    allSystemsData.push(systemData);
  }

  yield put(getLeicaSystemsWithChecklistDataSuccess([...allSystemsData]));
  return Promise.resolve();
}

function* fetchEmployees() {
  const results = yield api
    .getData(
      "/modules/db/instance/GetByTemplateId?templateId=" + employeeTemplateId,
      {
        token: localStorage.getItem("access_token"),
      }
    )
    .then((res) => {
      return res.json();
    });

  const employees = {};
  results.forEach((result) => {
    const normalizedResult = evadb.normalizeInstance(result);
    employees[normalizedResult.instanceId] =
      normalizedResult.properties.LeicaInternalID.propertyValue;
  });

  yield put(getLeicaEmployeesSuccess({ ...employees }));
  return Promise.resolve();
}

function* fetchFactoryboardData() {
  // get work hours per day
  // yield call(fetchAdminDashboardSettings);
  // const leicaWorkHoursPerDay = Number(
  //   yield select(selectors.leicaWorkHoursPerDay)
  // );
  const leicaWorkHoursPerDay = 6.5

  // fetch resources
  yield call(fetchResources);
  const resources = yield select(selectors.resources);
  // const resourceAbsenceDays = getResourceAbsenceDatesMapping(resources);
  const resourceAbsenceDays = undefined

  // fetch employees
  yield call(fetchEmployees);
  const employees = yield select(selectors.employees);

  // fetch systems
  yield call(() =>
    fetchSystemsWithChecklistData(resourceAbsenceDays, leicaWorkHoursPerDay)
  );
  const systemData = yield select(selectors.systemsWithChecklistData);
  // calculate system schedule for factoryboard

  // bring data into factoryboard-specific format
  const factoryBoardResourceData = formatResourceDataForFactoryboard(
    resources,
    resourceAbsenceDays
  );
  const geparktResourceId = resources.find(
    (resource) => resource.type === "Geparkt"
  ).id;
  const factoryBoardSystemData = formatSystemDataForFactoryboard(
    systemData,
    leicaWorkHoursPerDay,
    resourceAbsenceDays,
    geparktResourceId,
    employees
  );

  yield put(
    getLeicaFactoryboardDataSuccess({
      factoryBoardResourceData: factoryBoardResourceData,
      geparktResourceId: geparktResourceId,
      resourceAbsenceDays: resourceAbsenceDays,
      factoryBoardSystemData: factoryBoardSystemData,
      employees: employees,
    })
  );
}

function* fetchDepartureBoardData() {
  // get work hours per day
  // yield call(fetchAdminDashboardSettings);
  // const leicaWorkHoursPerDay = Number(
  //   yield select(selectors.leicaWorkHoursPerDay)
  // );
  const leicaWorkHoursPerDay = 6.5

  // fetch resources
  yield call(fetchResources);
  const resources = yield select(selectors.resources);
  // const resourceAbsenceDays = getResourceAbsenceDatesMapping(resources);
  const resourceAbsenceDays = undefined

  // fetch systems
  yield call(() =>
    fetchSystemsWithChecklistData(resourceAbsenceDays, leicaWorkHoursPerDay)
  );
  const systemData = yield select(selectors.systemsWithChecklistData);

  let departureboardData = [];

    // filter only those systems that have one of the statuses below,
    // ignore others (e.g., ready for shipping) on departureboard

  systemData.filter((system) =>
        [
          "newsystem",
          "started",
          "inproduction",
          "transitiontosgr",
          "sgrinproduction",
        ].includes(system.status)
      ).forEach((system) => {

    const hasSgrChecklist = typeof system.srChecklistData != "undefined" ? true : false;
    const totalSystemWPs = hasSgrChecklist ? Object.keys(system.srChecklistData.workshares).length + Object.keys(system.checklistData.workshares).length : Object.keys(system.checklistData.workshares).length ;
  
    let currentWorkpackage = null;
    let lastCompletedWorkPackage = null; 
    let sgrActive = false;
    let sitActive = false;
    let completedWps = 0;
    let totalWorkshareArray = hasSgrChecklist ? Object.values(system.checklistData.workshares).concat(Object.values(system.srChecklistData.workshares)) : Object.values(system.checklistData.workshares) ;
    let finishedWorkshareArray = [];
    // FTE stands for Full Time Equivalent (one whole workday for one worker)
    let deviationInFTE = 0;
    let expectedCompletionDate = "";
  
    switch (system.status){
      case "newsystem":
        expectedCompletionDate = getDepartureBoardCompletionStatus( system.checklistData, hasSgrChecklist, leicaWorkHoursPerDay, resourceAbsenceDays )
        
        break;
      case "started":
        sitActive = true;
        currentWorkpackage = system.checklistData.currentWorkPackage;
        lastCompletedWorkPackage = system.checklistData.lastCompletedWorkpackage;
        completedWps = Object.keys(system.checklistData.workshares).indexOf(currentWorkpackage);
        finishedWorkshareArray = Object.values(system.checklistData.workshares).slice(0,completedWps);
        deviationInFTE = system.checklistData.deviationFromScheduleInWorkHours / leicaWorkHoursPerDay;

        expectedCompletionDate = getDepartureBoardCompletionStatus( system.checklistData, hasSgrChecklist, leicaWorkHoursPerDay, resourceAbsenceDays )
        
        break;
      case "inproduction":
        sitActive = true;
        currentWorkpackage = system.checklistData.currentWorkPackage;
        lastCompletedWorkPackage = system.checklistData.lastCompletedWorkpackage;
        completedWps = Object.keys(system.checklistData.workshares).indexOf(currentWorkpackage);
        finishedWorkshareArray = Object.values(system.checklistData.workshares).slice(0,completedWps);
        deviationInFTE = system.checklistData.deviationFromScheduleInWorkHours / leicaWorkHoursPerDay;
        expectedCompletionDate = calculateDepartureBoardCompletionDate( system.checklistData, leicaWorkHoursPerDay, resourceAbsenceDays );
        break;
      case "transitiontosgr":
        lastCompletedWorkPackage = Object.keys(system.checklistData.workshares)[Object.keys(system.checklistData.workshares).length - 1];
        completedWps = Object.keys(system.checklistData.workshares).length;
        finishedWorkshareArray = Object.values(system.checklistData.workshares)

        expectedCompletionDate = getDepartureBoardCompletionStatus( system.srChecklistData, hasSgrChecklist, leicaWorkHoursPerDay, resourceAbsenceDays )
        
        break;
      case "sgrinproduction":
        sgrActive = true;
        currentWorkpackage = system.srChecklistData.currentWorkPackage;
        lastCompletedWorkPackage = system.srChecklistData.lastCompletedWorkpackage;
        let completedSgrWps = Object.keys(system.srChecklistData.workshares).indexOf(currentWorkpackage);
        completedWps = completedSgrWps + Object.keys(system.checklistData.workshares).length;
        finishedWorkshareArray = Object.values(system.srChecklistData.workshares).slice(0,completedSgrWps).concat(Object.values(system.checklistData.workshares)) ;
        deviationInFTE = system.srChecklistData.deviationFromScheduleInWorkHours / leicaWorkHoursPerDay;
        expectedCompletionDate = calculateDepartureBoardCompletionDate( system.srChecklistData, leicaWorkHoursPerDay, resourceAbsenceDays );
        break;

    }
  
    const wpCompletion = [completedWps, totalSystemWPs];
  
    let totalWorkshareTime = totalWorkshareArray.reduce(function(a, b){
      return a + b;
    });
  
    let finishedWorkshareTime = finishedWorkshareArray.length == 0 ? 0 : finishedWorkshareArray.reduce(function(a, b){
      return a + b;
    });
  
    const completion = finishedWorkshareTime == 0 ? 0 : finishedWorkshareTime/totalWorkshareTime;
    
    departureboardData.push({
          LeicaSystemNr: system.systemNrLeica,
          workpackage: currentWorkpackage,
          completion: completion,
          wpCompletion: wpCompletion,
          deviation: deviationInFTE,
          completedate: expectedCompletionDate
        });
      });

  yield put(getLeicaDepartureboardDataSuccess({ departureboardData }));
}

function* fetchOverview(id) {
  const overviewData = {};

  let systemResults = yield api
    .getData(
      "/modules/db/instance/SearchKeywordByTemplate?templateId=" +
        systemTemplateId +
        "&searchText=" +
        id.payload.systemId
    )
    .then((res) => {
      return res.json();
    });

  if (typeof systemResults[0] === "undefined") {
    yield put(getLeicaSystemOverviewError("Systemnumber not found!"));
    return;
  }
  systemResults = evadb.normalizeInstance(systemResults[0]);

  overviewData.systemId = systemResults.instanceId;

  const checklistId = systemResults.properties.Checklist.propertyValue;
  const sonderraumChecklistId =
    systemResults.properties.SonderraumChecklist.propertyValue;
  overviewData.systemstatus = systemResults.properties.Status.propertyValue;

  if (systemResults.properties.Systemausstattung.propertyValue !== "") {
    overviewData.systemdata = JSON.parse(
      systemResults.properties.Systemausstattung.propertyValue
    );
  }

  if (typeof checklistId === "undefined" || checklistId === "") {
    yield put(getLeicaSystemOverviewError("No Checklist for this System!"));
    return;
  }

  overviewData.checklistId = checklistId;

  let checklistResults = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + checklistId)
    .then((res) => {
      return res.json();
    });
  if (typeof checklistResults !== "undefined" && checklistResults !== "") {
    const checklistResultsNorm = evadb.normalizeInstance(checklistResults);

    const stepIds = checklistResultsNorm.properties.Steps.propertyValue;
    const issueIds =
      "Stoerzeiten" in checklistResultsNorm.properties
        ? checklistResultsNorm.properties.Stoerzeiten.propertyValue
        : "";
    const holdTimeIds = checklistResultsNorm.properties.HoldTimes.propertyValue;
    const workstationId =
      checklistResultsNorm?.properties?.Workstation?.propertyValue;

    let workstationResultNorm = undefined;
    if (workstationId !== "" && workstationId !== undefined) {
      let workstationResult = yield api
        .getData("/modules/db/instance/GetInstancebyId?id=" + workstationId)
        .then((res) => {
          return res.json();
        });
      workstationResultNorm = evadb.normalizeInstance(workstationResult);
    }

    overviewData.checklistId = checklistResultsNorm.instanceId;
    overviewData.startdatum =
      "Startdatum" in checklistResultsNorm.properties
        ? checklistResultsNorm.properties.Startdatum.propertyValue
        : undefined;
    overviewData.latestPossibleCompletionDate =
      "LatestPossibleCompletionDate" in systemResults.properties
        ? systemResults.properties.LatestPossibleCompletionDate.propertyValue
        : checklistResultsNorm?.properties?.LatestPossibleCompletionDate
            ?.propertyValue;
    overviewData.customerExpectanceDate =
      "CustomerExpectanceDate" in systemResults.properties
        ? systemResults.properties.CustomerExpectanceDate.propertyValue
        : checklistResultsNorm?.properties?.CustomerExpectanceDate
            ?.propertyValue;
    overviewData.absenceTimes = workstationResultNorm?.properties?.AbsenceTimes
      ? JSON.parse(workstationResultNorm.properties.AbsenceTimes.propertyValue)
      : [];

    let stepRequestIds = [];
    if (stepIds !== "") {
      stepRequestIds = stepIds.split(",");
    }

    let issueRequestIds = [];
    if (issueIds !== "") {
      issueRequestIds = issueIds.split(",");
    }

    let holdTimeRequestIds = [];
    if (holdTimeIds !== "") {
      holdTimeRequestIds = holdTimeIds.split(",");
    }

    overviewData.issueIdArray = issueRequestIds;
    overviewData.stepIdArray = stepRequestIds;
    overviewData.holdTimeIdArray = holdTimeRequestIds;

    overviewData.issueIdArray = overviewData.issueIdArray.filter(function (
      value
    ) {
      return value !== "";
    });
    overviewData.stepIdArray = overviewData.stepIdArray.filter(function (
      value
    ) {
      return value !== "";
    });

    try {
      overviewData.workshares =
        typeof checklistResultsNorm.properties.Workshares.propertyValue ===
        "string"
          ? JSON.parse(checklistResultsNorm.properties.Workshares.propertyValue)
          : checklistResultsNorm.properties.Workshares.propertyValue;
    } catch (e) {
      console.error(e);
      return;
    }

    const hasStartdate =
      "Startdatum" in checklistResultsNorm.properties &&
      checklistResultsNorm.properties.Startdatum.propertyValue !== "" &&
      checklistResultsNorm.properties.Startdatum.propertyValue !== undefined;
    const hasEnddate =
      "Enddatum" in checklistResultsNorm.properties &&
      checklistResultsNorm.properties.Enddatum.propertyValue !== "" &&
      checklistResultsNorm.properties.Enddatum.propertyValue !== undefined;
    const hasWorkstation =
      "Workstation" in checklistResultsNorm.properties &&
      checklistResultsNorm.properties.Workstation.propertyValue !== "" &&
      checklistResultsNorm.properties.Workstation.propertyValue !== undefined;
    overviewData.isScheduled = hasStartdate && hasEnddate && hasWorkstation;
    overviewData.sitWorkstation = hasWorkstation ? workstationResultNorm.properties.Name.propertyValue : undefined

  } else {
    yield put(getLeicaSystemOverviewError({ ...checklistResults }));
  }
  if (sonderraumChecklistId !== "" && sonderraumChecklistId !== undefined) {
    // fetch sonderraum duration if any
    let sonderraumChecklistResults = yield api
      .getData(
        "/modules/db/instance/GetInstancebyId?id=" + sonderraumChecklistId
      )
      .then((res) => {
        return res.json();
      });
    if (
      typeof sonderraumChecklistResults !== "undefined" &&
      sonderraumChecklistResults !== ""
    ) {
      const sonderraumChecklistResultsNorm = evadb.normalizeInstance(
        sonderraumChecklistResults
      );

      overviewData.sonderraumChecklistId = sonderraumChecklistId;
      overviewData.sonderraumDuration =
        sonderraumChecklistResultsNorm.properties.Duration.propertyValue; // legacy
      overviewData.sonderraumWorkshares =
        sonderraumChecklistResultsNorm.properties.Workshares?.propertyValue
          ?.split(",")
          .filter(function (value) {
            return value !== "";
          });

      const sonderraumStepIds =
        sonderraumChecklistResultsNorm?.properties?.Steps?.propertyValue;
      const sonderraumIssueIds =
        "Stoerzeiten" in sonderraumChecklistResultsNorm.properties
          ? sonderraumChecklistResultsNorm.properties.Stoerzeiten.propertyValue
          : "";
      const sonderraumHoldTimeIds =
        sonderraumChecklistResultsNorm?.properties?.HoldTimes?.propertyValue;
      const sonderraumWorkstationId =
        sonderraumChecklistResultsNorm?.properties?.AssignedResource
          ?.propertyValue;

      let sonderraumWorkstationResultNorm = undefined;
      if (
        sonderraumWorkstationId !== "" &&
        sonderraumWorkstationId !== undefined
      ) {
        let sonderraumWorkstationResult = yield api
          .getData(
            "/modules/db/instance/GetInstancebyId?id=" + sonderraumWorkstationId
          )
          .then((res) => {
            return res.json();
          });
        sonderraumWorkstationResultNorm = evadb.normalizeInstance(
          sonderraumWorkstationResult
        );
      }

      overviewData.sonderraumStartdatum =
        "Startdatum" in sonderraumChecklistResultsNorm.properties
          ? sonderraumChecklistResultsNorm.properties.Startdatum.propertyValue
          : undefined;
      overviewData.sonderraumAbsenceTimes = sonderraumWorkstationResultNorm
        ?.properties?.AbsenceTimes
        ? JSON.parse(
            sonderraumWorkstationResultNorm.properties.AbsenceTimes
              .propertyValue
          )
        : [];

      let sonderraumStepRequestIds = [];
      if (typeof sonderraumStepIds === "string" && sonderraumStepIds !== "") {
        sonderraumStepRequestIds = sonderraumStepIds.split(",");
      }

      let sonderraumIssueRequestIds = [];
      if (typeof sonderraumIssueIds === "string" && sonderraumIssueIds !== "") {
        sonderraumIssueRequestIds = sonderraumIssueIds.split(",");
      }

      let sonderraumHoldTimeRequestIds = [];
      if (
        typeof sonderraumHoldTimeIds === "string" &&
        sonderraumHoldTimeIds !== ""
      ) {
        sonderraumHoldTimeRequestIds = sonderraumHoldTimeIds.split(",");
      }

      overviewData.sonderraumIssueIdArray = sonderraumIssueRequestIds;
      overviewData.sonderraumStepIdArray = sonderraumStepRequestIds;
      overviewData.sonderraumHoldTimeIdArray = sonderraumHoldTimeRequestIds;

      overviewData.sonderraumIssueIdArray =
        overviewData.sonderraumIssueIdArray.filter(function (value) {
          return value !== "";
        });
      overviewData.sonderraumStepIdArray =
        overviewData.sonderraumStepIdArray.filter(function (value) {
          return value !== "";
        });

      try {
        overviewData.sonderraumWorkshares =
          typeof sonderraumChecklistResultsNorm?.properties?.Workshares
            ?.propertyValue === "string"
            ? JSON.parse(
                sonderraumChecklistResultsNorm.properties.Workshares
                  .propertyValue
              )
            : sonderraumChecklistResultsNorm?.properties?.Workshares
                ?.propertyValue;
      } catch (e) {
        // return;
      }

      const sonderraumHasStartdate =
        "Startdatum" in sonderraumChecklistResultsNorm.properties &&
        sonderraumChecklistResultsNorm.properties.Startdatum.propertyValue !==
          "" &&
        sonderraumChecklistResultsNorm.properties.Startdatum.propertyValue !==
          undefined;
      const sonderraumHasEnddate =
        "Enddatum" in sonderraumChecklistResultsNorm.properties &&
        sonderraumChecklistResultsNorm.properties.Enddatum.propertyValue !==
          "" &&
        sonderraumChecklistResultsNorm.properties.Enddatum.propertyValue !==
          undefined;
      const sonderraumHasWorkstation =
        "AssignedResource" in sonderraumChecklistResultsNorm.properties &&
        sonderraumChecklistResultsNorm.properties.AssignedResource
          .propertyValue !== "" &&
        sonderraumChecklistResultsNorm.properties.AssignedResource
          .propertyValue !== undefined;

      overviewData.sonderraumIsScheduled =
        sonderraumHasStartdate &&
        sonderraumHasEnddate &&
        sonderraumHasWorkstation;

        overviewData.sonderraumWorkstation = sonderraumHasWorkstation ? sonderraumWorkstationResultNorm.properties.Name.propertyValue : undefined

    } else {
      yield put(getLeicaSystemOverviewError({ ...sonderraumChecklistResults }));
    }
  }

  yield put(getLeicaSystemOverviewSuccess({ ...overviewData }));
}

function* fetchIssuesById(issues) {
  let issueRequestIds = issues.payload.issueIdArray;

  if (typeof issueRequestIds !== "undefined" && issueRequestIds.length !== 0) {
    //Get Issues
    let query = {
      or: [],
      and: [],
      linked_data: 1,
    };
    issueRequestIds.forEach((stepId) => {
      query.or.push({ instanceId: stepId });
    });

    let issueResults = yield api
      .postData("/modules/db/instance/getInstancesByQuery", query)
      .then((res) => {
        return res.json();
      });
    //TODO: When is result a not good call?
    if (issueResults !== "") {
      let issuesNormalized = [];
      issueResults.forEach((result) => {
        issuesNormalized.push(evadb.normalizeInstance(result));
      });
      let issueData = [];
      issuesNormalized.forEach((element) => {
        let issue = {};
        issue.Materialnummer =
          element.properties["Materialnummer"].propertyValue;
        issue.Kategorie = element.properties["Kategorie"].propertyValue;
        issue.Bereich = element.properties["Bereich"].propertyValue;
        issue.Zeitverlust = element.properties["Zeitverlust"].propertyValue;
        issue.Datum = element.properties["Datum"].propertyValue;
        issueData.push(issue);
      });

      yield put(getLeicaIssuesByIdSuccess(issueData));
    } else {
      yield put(getLeicaIssuesByIdError(issueResults));
    }
  } else {
    yield put(getLeicaIssuesByIdError("No Issues"));
  }
}

function* fetchChecklistByStepIds(steps) {
  let stepRequestIds = steps.payload.stepIdArray;

  if (typeof stepRequestIds !== "undefined" && stepRequestIds.length !== 0) {
    let data = {};

    //Get actual StepData
    let query = {
      or: [],
      and: [{ title: "Step" }, { instanceId: { $in: stepRequestIds } }],
      linked_data: 1,
    };
    let checklistSteps = yield api
      .postData("/modules/db/instance/getInstancesByQuery", query)
      .then((res) => {
        return res.json();
      });

    let StepsNormalized = [];
    if (checklistSteps !== "") {
      checklistSteps.forEach((result) => {
        StepsNormalized.push(evadb.normalizeInstance(result));
      });
    } else {
      yield put(getLeicaChecklistByStepIdsError("Steps not fetchable!"));
      return;
    }

    // find sondernummern if any
    let sondernummern = [];
    StepsNormalized.forEach((step) => {
      if (step?.properties?.istSondernummer?.propertyValue === "True") {
        sondernummern.push(step);
      }
    });

    let sondernummernInstances = [];
    for (const sondernr of sondernummern) {
      const sondernummerObject = yield api
        .getData(
          "/modules/db/instance/GetInstancebyId?id=" +
            sondernr.properties[0].propertyValue
        )
        .then((res) => {
          return res.json();
        });
      sondernummernInstances.push(sondernummerObject);
    }

    let sondernummernNormalized = [];
    if (sondernummernInstances !== "") {
      sondernummernInstances.forEach((nr) => {
        sondernummernNormalized.push(evadb.normalizeInstance(nr));
      });
    } else {
      yield put(
        getLeicaChecklistByStepIdsError("Sondernummern not fetchable!")
      );
      return;
    }

    data = mapStepsToChecklist(StepsNormalized, sondernummernNormalized);

    if (typeof data.currentWorkTimeId !== "undefined") {
      let worktime = yield api
        .getData(
          "/modules/db/instance/GetInstancebyId?id=" + data.currentWorkTimeId
        )
        .then((res) => {
          return res.json();
        });

      data.currentWorkState = worktime.properties[1].propertyValue === "";
    } else {
      data.currentWorkState = false;
    }

    yield put(getLeicaChecklistByStepIdsSuccess({ ...data }));
  } else {
    yield put(getLeicaChecklistByStepIdsError("No Steps in Checklist!"));
  }
}

function* fetchHoldTimes({ payload }) {
  const holdTimes = {};
  // fetch all holdTimes for system, if any
  if (payload.holdTimeIdArray.length > 0) {
    let holdTimesQuery = {
      or: [],
      and: [
        { title: "HoldDates" },
        { instanceId: { $in: payload.holdTimeIdArray } },
      ],
      linked_data: 1,
    };
    const rawHoldTimes = yield api
      .postData("/modules/db/instance/getInstancesByQuery", holdTimesQuery)
      .then((res) => {
        return res.json();
      });
    const normalizedRawHoldTimes = rawHoldTimes.map((rawHoldTime) =>
      evadb.normalizeInstance(rawHoldTime)
    );
    // loop through hold times found and format the output as needed
    normalizedRawHoldTimes.forEach((holdTime) => {
      holdTimes[holdTime.instanceId] = {
        startsAt: {
          type: "Date",
          value: holdTime.properties.Startdate.propertyValue,
        },
        endsAt: {
          type: "Date",
          value: holdTime.properties.Enddate.propertyValue,
        },
      };
    });
  }
  yield put(getLeicaSystemHoldTimesSuccess(holdTimes, payload.isSonderraum));
}

function* autoscheduleNewSystem({ payload }) {
  const systemnumber = payload;
  // get newly created system to find out its checklist id
  let query = {
    or: [],
    and: [
      { title: "Microscopesystem" },
      { "properties.0.propertyValue": systemnumber },
    ],
    linked_data: 1,
  };
  let newlyCreatedSystem = yield api
    .postData("/modules/db/instance/getInstancesByQuery", query)
    .then((res) => {
      return res.json();
    });
  const checklistId = evadb.normalizeInstance(newlyCreatedSystem[0])?.properties
    ?.Checklist?.propertyValue;

  // schedule system
  let autoschedulingResponse = //yield leicaServiceApi
    //.postData("/schedule_system", {checklistId: checklistId})
    yield api
      .postData("/modules/Leica1/endpoints/schedule_system", {
        checklistId: checklistId,
      })
      .then((res) => {
        return res.json();
      });
}

function* rescheduleSystems({ payload }) {
  const { systems, sonderraumSystems } = payload;
  // stotalDowntimee modified normal systems
  const checklistTemplate = ChecklistTemplate;
  for (const [
    id,
    { checklistId, start, end, resource, assignedEmployee },
  ] of Object.entries(systems)) {
    // get the checklist properties that are supposed to stay the same
    // (since we need to pass this to the instance again as well when updating to totalDowntimeoid overwriting it)
    // FOR LATER OPTIMIZATION (IF NEEDED, e.g. if there are many systems):
    // instead of fetching the checklist from the db again to get this info,
    // it might be faster to keep all info needed in the reducer shopfloor
    // (loaded anyway when opening the factoryboard), and just pass it through the function arguments
    let checklistInstance = yield api
      .getData("/modules/db/instance/GetInstancebyId?id=" + checklistId)
      .then((res) => {
        return res.json();
      });
    if (typeof checklistInstance !== "object") {
      yield put(rescheduleLeicaSystemsError("Checklist not found!"));
    }
    const checklistInstanceNorm = evadb.normalizeInstance(checklistInstance);
    const updatedProperties = checklistTemplate.properties;
    updatedProperties[0].propertyValue = start ? start : ""; // Startdatum
    updatedProperties[1].propertyValue =
      checklistInstanceNorm.properties.Steps.propertyValue; // Steps
    updatedProperties[2].propertyValue = // HoldTimes
      checklistInstanceNorm.properties.HoldTimes
        ? checklistInstanceNorm.properties.HoldTimes.propertyValue
        : "";
    updatedProperties[3].propertyValue = checklistInstanceNorm.properties
      .Stoerzeiten // Störzeiten
      ? checklistInstanceNorm.properties.Stoerzeiten.propertyValue
      : "";
    // updatedProperties[4].propertyValue = checklistInstanceNorm.properties.CustomerExpectanceDate.propertyValue // CustomerExpectanceDate
    // updatedProperties[5].propertyValue = checklistInstanceNorm.properties.LatestPossibleCompletionDate.propertyValue // LatestPossibleCompletionDate
    updatedProperties[4].propertyValue = // LastCompletedWorkpackage
      checklistInstanceNorm.properties.LastCompletedWorkpackage
        ? checklistInstanceNorm.properties.LastCompletedWorkpackage
            .propertyValue
        : "";
    updatedProperties[5].propertyValue = // Workshares
      typeof checklistInstanceNorm.properties.Workshares.propertyValue !==
      "string"
        ? JSON.stringify(
            checklistInstanceNorm.properties.Workshares.propertyValue
          )
        : checklistInstanceNorm.properties.Workshares.propertyValue;
    updatedProperties[6].propertyValue = resource ? resource : ""; // Workstation
    updatedProperties[7].propertyValue = end ? end : ""; // Enddatum
    updatedProperties[8].propertyValue = assignedEmployee
      ? assignedEmployee
      : ""; // assignedEmployee
    checklistInstance.properties = updatedProperties;
    delete checklistInstance.createdby;
    delete checklistInstance.Created_time;
    delete checklistInstance.stamp;
    delete checklistInstance.Updated_time;
    const apiResponse = yield api
      .postData("/modules/db/instance/Instance", checklistInstance)
      .then((res) => {
        return res;
      });
    if (apiResponse.status !== 200) {
      yield put(rescheduleLeicaSystemsError(apiResponse));
    }
  }
  const sonderraumChecklistTemplate = SonderraumChecklistTemplate;
  for (const [
    id,
    {
      sonderraumChecklistId,
      resource,
      start,
      end,
      assignedEmployee,
      isLegacyChecklist,
    },
  ] of Object.entries(sonderraumSystems)) {
    // get the checklist properties that are supposed to stay the same
    // (since we need to pass this to the instance again as well when updating to totalDowntimeoid overwriting it)
    // FOR LATER OPTIMIZATION (IF NEEDED, e.g. if there are many systems):
    // instead of fetching the checklist from the db again to get this info,
    // it might be faster to keep all info needed in the reducer shopfloor
    // (loaded anyway when opening the factoryboard), and just pass it through the function arguments
    let sonderraumChecklistInstance = yield api
      .getData(
        "/modules/db/instance/GetInstancebyId?id=" + sonderraumChecklistId
      )
      .then((res) => {
        return res.json();
      });
    if (typeof sonderraumChecklistInstance !== "object") {
      yield put(rescheduleLeicaSystemsError("Sonderraum checklist not found!"));
    }
    const sonderraumChecklistInstanceNorm = evadb.normalizeInstance(
      sonderraumChecklistInstance
    );
    const updatedProperties = sonderraumChecklistTemplate.properties;
    if (isLegacyChecklist) {
      updatedProperties[0].propertyValue = start ? start : ""; // startdatum
      updatedProperties[1].propertyValue =
        "Steps" in sonderraumChecklistInstanceNorm.properties
          ? sonderraumChecklistInstanceNorm.properties.Steps.propertyValue
          : ""; // steps
      updatedProperties[2].propertyValue =
        "HoldTimes" in sonderraumChecklistInstanceNorm.properties
          ? sonderraumChecklistInstanceNorm.properties.HoldTimes.propertyValue
          : ""; // holdtimes
      updatedProperties[3].propertyValue =
        "Stoerzeiten" in sonderraumChecklistInstanceNorm.properties
          ? sonderraumChecklistInstanceNorm.properties.Stoerzeiten.propertyValue
          : ""; // störzeiten
      updatedProperties[4].propertyValue =
        "LastCompletedWorkpackage" in sonderraumChecklistInstanceNorm.properties
          ? sonderraumChecklistInstanceNorm.properties.LastCompletedWorkpackage
              .propertyValue
          : ""; // last completed workpackage
      updatedProperties[5].propertyValue =
        "Workshares" in sonderraumChecklistInstanceNorm.properties
          ? sonderraumChecklistInstanceNorm.properties.Workshares.propertyValue
          : ""; // workshares
      updatedProperties[6].propertyValue = resource ? resource : ""; // assigned resource
      updatedProperties[7].propertyValue =
        "Enddatum" in sonderraumChecklistInstanceNorm.properties
          ? sonderraumChecklistInstanceNorm.properties.Enddatum.propertyValue
          : ""; // enddatum
      updatedProperties[8].propertyValue = assignedEmployee
        ? assignedEmployee
        : ""; // assigned employee
      updatedProperties[9].propertyValue =
        sonderraumChecklistInstanceNorm.properties.Duration.propertyValue; // duration
    } else {
      // if new format
      updatedProperties[0].propertyValue = start ? start : ""; // startdatum
      updatedProperties[1].propertyValue =
        sonderraumChecklistInstanceNorm.properties.Steps.propertyValue; // steps
      updatedProperties[2].propertyValue =
        sonderraumChecklistInstanceNorm.properties.HoldTimes.propertyValue; // holdtimes
      updatedProperties[3].propertyValue =
        sonderraumChecklistInstanceNorm.properties.Stoerzeiten.propertyValue; // störzeiten
      updatedProperties[4].propertyValue =
        sonderraumChecklistInstanceNorm.properties.LastCompletedWorkpackage.propertyValue; // last completed workpackage
      updatedProperties[5].propertyValue =
        sonderraumChecklistInstanceNorm.properties.Workshares.propertyValue; // workshares
      updatedProperties[6].propertyValue = resource ? resource : ""; // assigned resource
      updatedProperties[7].propertyValue = end ? end : ""; // enddatum
      updatedProperties[8].propertyValue = assignedEmployee
        ? assignedEmployee
        : ""; // assigned employee
      updatedProperties[9].propertyValue =
        sonderraumChecklistInstanceNorm.properties.Duration.propertyValue; // duration
    }
    sonderraumChecklistInstance.properties = updatedProperties;
    delete sonderraumChecklistInstance.createdby;
    delete sonderraumChecklistInstance.Created_time;
    delete sonderraumChecklistInstance.stamp;
    delete sonderraumChecklistInstance.Updated_time;
    const apiResponse = yield api
      .postData("/modules/db/instance/Instance", sonderraumChecklistInstance)
      .then((res) => {
        return res;
      });
    if (!apiResponse.status === 200) {
      yield put(rescheduleLeicaSystemsError(apiResponse));
    }
  }
  // window.location.reload()
  yield put(rescheduleLeicaSystemsSuccess());
}

function* autoOrganizeSystems({ payload }) {
  // const apiResponse = yield leicaServiceApi
  //   .postData("/reschedule_system", payload)
  const apiResponse = yield api
    .postData("/modules/Leica1/endpoints/reschedule_system", payload)
    .then((res) => {
      return res;
    });
  if (!apiResponse.status === 200) {
    yield put(autoOrganizeSystemsOnFactoryboardError(apiResponse));
  }
  window.location.reload();
  yield put(autoOrganizeSystemsOnFactoryboardSuccess());
}

function* SetStepComplete(data) {
  // const checklistId = data.payload.checklistId;
  const systemId = data.payload.systemId;

  const finishStep = // yield api
    // .postData("/modules/Leica1/endpoints/complete_step", {
      yield leicaServiceApi
      .postData("/complete_step", {
      // checklistId: checklistId,
      systemId: systemId,
    })
    .then((res) => {
      return res.json();
    });

  if (typeof finishStep === "object") {
    yield put(setLeicaStepCompleteSuccess(finishStep));
  } else {
    yield put(setLeicaStepCompleteError(finishStep));
  }
}

function* ToggleWorkstate(data) {
  const checklistId = data.payload.checklistId;
  const sonderraumChecklistId = data.payload.sonderraumChecklistId;
  const systemStatus = data.payload.systemStatus;

  //Get Checklist by ChecklistId or SonderraumChecklist by SonderraumChecklistId, respectively
  let checklistResults = undefined;
  if (["newsystem", "started", "inproduction"].includes(systemStatus)) {
    checklistResults = yield api
      .getData("/modules/db/instance/GetInstancebyId?id=" + checklistId)
      .then((res) => {
        return res.json();
      });
  } else {
    checklistResults = yield api
      .getData(
        "/modules/db/instance/GetInstancebyId?id=" + sonderraumChecklistId
      )
      .then((res) => {
        return res.json();
      });
  }

  //Create current Date and Time
  const now = new Date();

  //if no error Message procede
  if (
    typeof checklistResults === "string" ||
    typeof checklistResults === "undefined"
  ) {
    yield put(toggleLeicaWorkStatusError("No Checklist for this System"));
    return;
  }

  const checklistResultsNorm = evadb.normalizeInstance(checklistResults);
  const stepIds = checklistResultsNorm.properties.Steps.propertyValue;

  let stepRequestIds = [];
  if (stepIds !== "") {
    stepRequestIds = stepIds.split(",");
  }

  //Get current Step
  let currentStepQuery = {
    or: [],
    and: [
      { "properties.propertyValue": "inproduction" },
      { instanceId: { $in: stepRequestIds } },
    ],
    linked_data: 1,
  };

  let currentStep = yield api
    .postData("/modules/db/instance/getInstancesByQuery", currentStepQuery)
    .then((res) => {
      return res.json();
    });
  currentStep = currentStep[0];

  //Get current Worktime

  let workTimeIds = currentStep.properties[2].propertyValue.split(",");

  let currentWorkTimeQuery = {
    or: [],
    and: [
      { "properties.1.propertyValue": "" },
      { instanceId: { $in: workTimeIds } },
    ],
    linked_data: 1,
  };

  let currentWorkTime = yield api
    .postData("/modules/db/instance/getInstancesByQuery", currentWorkTimeQuery)
    .then((res) => {
      return res.json();
    });

  currentWorkTime = currentWorkTime[0];
  let response = {};

  if (typeof currentWorkTime !== "undefined" && currentWorkTime !== "") {
    //Finish currentWorkTime, if it is currently counting time
    currentWorkTime.properties[1].propertyValue = now
      .toISOString()
      .slice(0, 23);
    let currentWorkTime_TimeSpend =
      Math.round(
        (Date.parse(currentWorkTime.properties[1].propertyValue) -
          Date.parse(currentWorkTime.properties[0].propertyValue)) /
          600
      ) / 100;
    delete currentWorkTime.createdby;
    delete currentWorkTime.Created_time;
    delete currentWorkTime.stamp;
    delete currentWorkTime.Updated_time;
    //Post currentWorkTime to DB
    api.postData("/modules/db/instance/Instance", currentWorkTime);

    currentStep.properties[1].propertyValue =
      currentStep.properties[1].propertyValue === ""
        ? currentWorkTime_TimeSpend.toString()
        : (
            Math.round(
              (parseFloat(currentStep.properties[1].propertyValue) +
                currentWorkTime_TimeSpend) *
                100
            ) / 100
          ).toString();

    response.toggleResponse = false;
    response.timeSpend = currentStep.properties[1].propertyValue;
  } else {
    //Create and start new WorkTime
    let newWorkTime = WorkTimeTemplate;
    newWorkTime.properties[0].propertyValue = now.toISOString().slice(0, 23);
    newWorkTime = yield api
      .postData("/modules/db/instance/Instance", newWorkTime)
      .then((res) => {
        return res.json();
      });

    const newWorktimeId = newWorkTime.data;

    currentStep.properties[2].propertyValue =
      currentStep.properties[2].propertyValue + "," + newWorktimeId;
    response.toggleResponse = true;
  }

  delete currentStep.Created_time;
  delete currentStep.createdby;
  delete currentStep.stamp;
  delete currentStep.Updated_time;

  api.postData("/modules/db/instance/Instance", currentStep);
  yield put(toggleLeicaWorkStatusSuccess(response));
}

function* putSondernummern({ payload }) {
  // get work hours per day
  // yield call(fetchAdminDashboardSettings);
  // const leicaWorkHoursPerDay = Number(
  //   yield select(selectors.leicaWorkHoursPerDay)
  // );
  const leicaWorkHoursPerDay = 6.5

  // sort numbers by position index (ascending) to make sure they will be inserted at right position
  const numbers = payload.numbers;
  const checklistId = payload.checklistId;

  let numbersSortedByPosition = new Array();
  numbers.forEach((number) => {
    numbersSortedByPosition.push({ ...number });
  });
  numbersSortedByPosition.sort(
    (nr1, nr2) =>
      parseFloat(nr1.currentlyLockedPosition) -
      parseFloat(nr2.currentlyLockedPosition)
  );

  let numbersToIncludeInChecklist = new Array();

  for (const number of numbersSortedByPosition) {
    let sondernummernStepTemplate = SondernummernStepTemplate;
    sondernummernStepTemplate.properties[0].propertyValue =
      number.currentlyLockedTab;
    sondernummernStepTemplate.properties[1].propertyValue = number.number;
    sondernummernStepTemplate.properties[4].propertyValue = number.worktime;
    sondernummernStepTemplate.properties[5].propertyValue = number.description;
    sondernummernStepTemplate.properties[7].propertyValue =
      number.currentlyLockedPosition.toString();
    let sondernummernStepTemplateId = yield api
      .postData("/modules/db/instance/Instance", sondernummernStepTemplate)
      .then((res) => {
        return res.json();
      });

    let step = Step;
    step.properties[0].propertyValue = sondernummernStepTemplateId.data;
    step.properties[6].propertyValue = number.worktime;
    step.properties[7].propertyValue = "True"; // "istSondernummer" property
    step.properties[8].propertyValue = number.currentlyLockedTab;
    step.properties[9].propertyValue = number.number;
    step.properties[10].propertyValue = "SpecialPartsRequest";
    step.properties[12].propertyValue = number.worktime;
    step.properties[13].propertyValue = number.description;
    step.properties[15].propertyValue =
      number.currentlyLockedPosition.toString();
    let stepId = yield api
      .postData("/modules/db/instance/Instance", step)
      .then((res) => {
        return res.json();
      });
    numbersToIncludeInChecklist.push({ number: number, id: stepId.data });
  }

  // get step ids
  let checklistObject = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + checklistId)
    .then((res) => {
      return res.json();
    });
  let stepIds = checklistObject.properties[1].propertyValue.split(",");

  // From step ids, get step objects
  let query = {
    or: [],
    and: [],
    linked_data: 1,
  };
  stepIds.forEach((stepId) => {
    query.or.push({ instanceId: stepId });
  });
  let checklistSteps = yield api
    .postData("/modules/db/instance/getInstancesByQuery", query)
    .then((res) => {
      return res.json();
    });

  let stepsNormalized = [];
  if (checklistSteps !== "") {
    checklistSteps.forEach((result) => {
      stepsNormalized.push(evadb.normalizeInstance(result));
    });
  } else {
    // TO DO: handle error case!
    return null;
  }

  //Get Template Steps
  let stepTemplates = yield api
    .getData(
      "/modules/db/instance/GetByTemplateId?templateId=" +
        StepTemplateTemplateId
    )
    .then((res) => {
      return res.json();
    });
  let stepTemplatesNorm = [];
  if (stepTemplates !== "") {
    stepTemplates.forEach((result) => {
      stepTemplatesNorm.push(evadb.normalizeInstance(result));
    });
  } else {
    // TO DO: handle error case
    return null;
  }

  // build a helper-checklist to find correct position of sondernummern in step-list from there
  // (the problem is that some steptemplates from the checklist don't appear in the step-list,
  // thus, we need to find the next one that does to place the number behind it)
  let helperchecklist = [];
  stepTemplatesNorm.forEach((stepTemplate) => {
    // find workpackage if already exists
    let workpackage = helperchecklist.find(
      (workpck) =>
        workpck.id == stepTemplate.properties["Arbeitspaket"].propertyValue
    );
    //if workpackage not existent create it
    if (typeof workpackage === "undefined") {
      workpackage = {};
      workpackage.id = stepTemplate.properties["Arbeitspaket"].propertyValue;
      workpackage.data = [];
      helperchecklist.push(workpackage);
      workpackage = helperchecklist.find(
        (workpck) =>
          workpck.id == stepTemplate.properties["Arbeitspaket"].propertyValue
      );
    }
    // for every steptemplate, place either the *step* id in the checklist, or "undefined" if no step exists
    // this will later help to locate the *step* that comes after a selected checklist position,
    // to place the sondernummer next to that *step* in the stepIds list
    let Step = stepsNormalized.find(
      (step) =>
        step.properties["StepTemplate"].propertyValue ===
        stepTemplate.instanceId
    );
    const stepId = Step !== undefined ? Step.instanceId : Step;
    workpackage.data.push(stepId);
  });
  let updatedStepIds = [...stepIds];
  numbersToIncludeInChecklist.forEach((number) => {
    let workpackageIndex = helperchecklist.findIndex(
      (workpck) => workpck.id == number.number.currentlyLockedTab
    );
    // add sondernummern entry in helper checklist (as undefined so that it will be ignored further down)
    helperchecklist[workpackageIndex].data.splice(
      number.number.currentlyLockedPosition,
      0,
      undefined
    );
    let nextStepIdAfterSondernummer = undefined;
    while (
      nextStepIdAfterSondernummer === undefined &&
      workpackageIndex < helperchecklist.length
    ) {
      // break
      let nextStepIdAfterSondernummerTmp = helperchecklist[
        workpackageIndex
      ].data.find(
        (stepId, index) =>
          (index > number.number.currentlyLockedPosition ||
            helperchecklist[workpackageIndex].id !==
              number.number.currentlyLockedTab) &&
          stepId !== undefined
      );
      if (nextStepIdAfterSondernummerTmp === undefined) {
        workpackageIndex = workpackageIndex + 1;
      } else {
        nextStepIdAfterSondernummer = nextStepIdAfterSondernummerTmp;
      }
    }
    const indexOfNextStepId = updatedStepIds.findIndex(
      (stepId) => stepId === nextStepIdAfterSondernummer
    );
    updatedStepIds.splice(indexOfNextStepId, 0, number.id);
  });

  // add duration of sondernummern steps to workshares
  const workshares = JSON.parse(checklistObject.properties[7].propertyValue);
  numbersToIncludeInChecklist.forEach(
    (number) =>
      (workshares[number.number.currentlyLockedTab] = (
        parseFloat(workshares[number.number.currentlyLockedTab]) +
        parseFloat(number.number.worktime) / 60 / leicaWorkHoursPerDay
      ).toString()) // convert mins to PT
  );

  // update checklist instance in database
  let checklistTemplate = ChecklistTemplate;
  checklistTemplate.instanceId = checklistId;
  checklistTemplate.properties = [...checklistObject.properties];
  checklistTemplate.properties[1].propertyValue = updatedStepIds.join();
  checklistTemplate.properties[7].propertyValue = JSON.stringify(workshares);
  const apiResponse = yield api
    .postData("/modules/db/instance/Instance", checklistTemplate)
    .then((res) => {
      return res;
    });
  if (apiResponse.status === 200) {
    yield put(putLeicaSondernummernSuccess());
  } else {
    yield put(putLeicaSondernummernError(apiResponse));
  }
}

function* createIssue(payload) {
  const issueData = payload.payload.issueData;
  const stepId = payload.payload.issueData.stepId;
  const checklistId = payload.payload.issueData.checklistId;
  const images = payload.payload.issueData.images;

  let checklistResults = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + checklistId)
    .then((res) => {
      return res.json();
    });

  // stotalDowntimeing all images in database, then returning ids to post together with issuedata
  const imagePaths = yield Promise.all(
    images.map((image) => {
      const postImage = new FormData();
      postImage.append("file", image);
      return api
        .postData("/modules/db/DataUpload/FileUpload", postImage, {
          isFormData: true,
        })
        .then((res) => {
          return res.json();
        });
    })
  ).then((imgPaths) => {
    return imgPaths;
  });

  let issue = IssueTemplate;
  IssueTemplate.properties[0].propertyValue = new Date()
    .toISOString()
    .slice(0, 10);
  IssueTemplate.properties[1].propertyValue = issueData.materialNumber;
  IssueTemplate.properties[2].propertyValue = issueData.serialNumber;
  IssueTemplate.properties[3].propertyValue = issueData.workpackage;
  IssueTemplate.properties[4].propertyValue = stepId;
  IssueTemplate.properties[5].propertyValue = issueData.description;
  IssueTemplate.properties[6].propertyValue = issueData.resolution;
  IssueTemplate.properties[7].propertyValue = issueData.area;
  IssueTemplate.properties[8].propertyValue = issueData.category;
  IssueTemplate.properties[9].propertyValue = issueData.timeloss;
  IssueTemplate.properties[10].propertyValue = checklistId;
  IssueTemplate.properties[11].propertyValue = JSON.stringify(imagePaths);

  const issueRequest = yield api
    .postData("/modules/db/instance/Instance", issue)
    .then((res) => {
      return res.json();
    });

  const issueId = issueRequest.data;

  checklistResults.properties[3].propertyValue === ""
    ? (checklistResults.properties[3].propertyValue += issueId)
    : (checklistResults.properties[3].propertyValue += "," + issueId);

  delete checklistResults.Created_time;
  delete checklistResults.createdby;
  delete checklistResults.stamp;
  delete checklistResults.Updated_time;

  const apiCallOk = api
    .postData("/modules/db/instance/Instance", checklistResults)
    .then((res) => {
      return res.ok;
    });
  if (apiCallOk) {
    yield put(putLeicaIssueSuccess());
  } else {
    yield put(putLeicaIssueError("Could not Update Checklist"));
  }
}

// function* deleteHoldTime({ payload }) {
//   const checklistId = payload?.system?.checklistId; // may be normal or sr checklist, but the behtotalDowntimeiour is the same
//   let checklistResults = yield api
//     .getData("/modules/db/instance/GetInstancebyId?id=" + checklistId)
//     .then((res) => {
//       return res.json();
//     });
//   const updatedProperties = checklistResults.properties;
//   updatedProperties[2].propertyValue = updatedProperties[2].propertyValue
//     .split(",")
//     .filter((id) => id !== payload.holdTimeId)
//     .join(","); // remove the hold time id from the respective checklist property
//   checklistResults.properties = updatedProperties;
//   delete checklistResults.createdby;
//   delete checklistResults.Created_time;
//   delete checklistResults.stamp;
//   delete checklistResults.Updated_time;
//   const apiResponse = yield api
//     .postData("/modules/db/instance/Instance", checklistResults)
//     .then((res) => {
//       return res;
//     });
//   if (apiResponse.status === 200) {
//     yield put(deleteLeicaHoldTimeSuccess());
//   } else {
//     yield put(deleteLeicaHoldTimeError(apiResponse));
//   }
// }

// function* updateHoldTime({ payload }) {
//   const updatedHoldDate = HoldDatesTemplate;
//   delete updatedHoldDate.createdby;
//   delete updatedHoldDate.Created_time;
//   delete updatedHoldDate.stamp;
//   delete updatedHoldDate.Updated_time;
//   if (payload.overrideExisting && payload.existingHoldTimeId) {
//     updatedHoldDate.instanceId = payload.existingHoldTimeId;
//   }
//   updatedHoldDate.properties[0].propertyValue = payload.newHoldTime.start;
//   updatedHoldDate.properties[1].propertyValue = payload.newHoldTime.end;
//   updatedHoldDate.properties[2].propertyValue =
//     payload.newHoldTime.resourceDuringHold;
//   updatedHoldDate.properties[3].propertyValue =
//     payload.newHoldTime.resourceAfterHold;
//   const apiResponse = yield api
//     .postData("/modules/db/instance/Instance", updatedHoldDate)
//     .then((res) => {
//       return res.json();
//     });
//   const holdDateInstanceId = apiResponse.data || payload.existingHoldTimeId;
//   if (
//     apiResponse.status === "Instance created successfully" ||
//     apiResponse === "Instance Updated successfully"
//   ) {
//     // update link to hold time in checklist
//     let checklist = yield api
//       .getData(
//         "/modules/db/instance/GetInstancebyId?id=" + payload.system.checklistId
//       )
//       .then((res) => {
//         return res.json();
//       });
//     if (checklist) {
//       delete checklist.createdby;
//       delete checklist.Created_time;
//       delete checklist.stamp;
//       delete checklist.Updated_time;
//       checklist.properties[2].propertyValue = holdDateInstanceId;
//       const apiResponse = yield api
//         .postData("/modules/db/instance/Instance", checklist)
//         .then((res) => {
//           return res;
//         });
//       if (apiResponse.status === 200) {
//         yield put(
//           putLeicaHoldTimeSuccess({
//             inputPayload: payload,
//             newHoldTimeId: holdDateInstanceId,
//           })
//         );
//       }
//     }
//   } else {
//     yield put(putLeicaHoldTimeError(apiResponse));
//   }
// }

function* deleteHoldTime({ payload }) {
  // update checklist instance where hold time is linked -- remove hold time id
  const checklistInstance = yield api
    .getData(
      "/modules/db/instance/GetInstancebyId?id=" +
        payload.correspondingChecklistId
    )
    .then((res) => {
      return res.json();
    });
  const checklistInstanceNorm = evadb.normalizeInstance(checklistInstance);
  checklistInstanceNorm.properties.HoldTimes.propertyValue = "";
  delete checklistInstanceNorm.createdby;
  delete checklistInstanceNorm.Created_time;
  delete checklistInstanceNorm.stamp;
  delete checklistInstanceNorm.Updated_time;
  const checklistInstanceUpdated = evadb.denormalizeInstance(
    checklistInstanceNorm
  );
  const apiResponse = yield api
    .postData("/modules/db/instance/Instance", checklistInstanceUpdated)
    .then((res) => {
      return res;
    });
  if (apiResponse.status !== 200) {
    yield put(deleteLeicaHoldTimeError(apiResponse));
  }
  // delete hold time instance itself
  yield api
    .deleteData("/modules/db/instance/DeleteBulk?ids=" + payload.holdTimeId)
    .then((res) => {
      return res.status;
    });
  if (apiResponse.status === 200) {
  } else {
    yield put(deleteLeicaHoldTimeError(apiResponse));
  }
}

function* updateHoldTime({ payload }) {
  const holdTimeInstance = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + payload.id)
    .then((res) => {
      return res.json();
    });
  const holdTimeInstanceNorm = evadb.normalizeInstance(holdTimeInstance);
  holdTimeInstanceNorm.properties.Startdate.propertyValue =
    payload.start.toString("yyyy-MM-dd");
  holdTimeInstanceNorm.properties.Enddate.propertyValue =
    payload.end.toString("yyyy-MM-dd");
  holdTimeInstanceNorm.properties.ResourceDuringHold.propertyValue =
    payload.resourceDuringHold;
  holdTimeInstanceNorm.properties.ResourceAfterHold.propertyValue =
    payload.resourceAfterHold;
  delete holdTimeInstanceNorm.createdby;
  delete holdTimeInstanceNorm.Created_time;
  delete holdTimeInstanceNorm.stamp;
  delete holdTimeInstanceNorm.Updated_time;
  const holdTimeInstanceUpdated =
    evadb.denormalizeInstance(holdTimeInstanceNorm);
  const apiResponse = yield api
    .postData("/modules/db/instance/Instance", holdTimeInstanceUpdated)
    .then((res) => {
      return res;
    });
  if (apiResponse.status !== 200) {
    yield put(updateLeicaHoldTimeError(apiResponse));
  } else {
    yield put(updateLeicaHoldTimeSuccess());
  }
}

function* createHoldTime({ payload }) {
  const newHoldTime = HoldDatesTemplate;
  delete newHoldTime.createdby;
  delete newHoldTime.Created_time;
  delete newHoldTime.stamp;
  delete newHoldTime.Updated_time;
  const newHoldTimeNorm = evadb.normalizeInstance(newHoldTime);
  newHoldTimeNorm.properties.Startdate.propertyValue =
    payload.start.toString("yyyy-MM-dd");
  newHoldTimeNorm.properties.Enddate.propertyValue =
    payload.end.toString("yyyy-MM-dd");
  newHoldTimeNorm.properties.ResourceDuringHold.propertyValue =
    payload.resourceDuringHold;
  newHoldTimeNorm.properties.ResourceAfterHold.propertyValue =
    payload.resourceAfterHold;
  const newHoldTimeDenorm = evadb.denormalizeInstance(newHoldTimeNorm);
  const apiResponse = yield api
    .postData("/modules/db/instance/Instance", newHoldTimeDenorm)
    .then((res) => {
      return res.json();
    });
  if (
    apiResponse.status === "Instance created successfully" ||
    apiResponse === "Instance Updated successfully"
  ) {
    const newHoldTimeId = apiResponse.data;
    // update link to hold time in checklist
    let checklist = yield api
      .getData(
        "/modules/db/instance/GetInstancebyId?id=" +
          payload.correspondingChecklistId
      )
      .then((res) => {
        return res.json();
      });
    if (checklist) {
      delete checklist.createdby;
      delete checklist.Created_time;
      delete checklist.stamp;
      delete checklist.Updated_time;
      const checklistNorm = evadb.normalizeInstance(checklist);
      checklistNorm.properties.HoldTimes.propertyValue = newHoldTimeId;
      const checklistUpdated = evadb.denormalizeInstance(checklistNorm);
      const apiResponse = yield api
        .postData("/modules/db/instance/Instance", checklistUpdated)
        .then((res) => {
          return res;
        });
      if (apiResponse.status === 200) {
        yield put(createLeicaHoldTimeSuccess());
      }
    }
  } else {
    yield put(createLeicaHoldTimeError(apiResponse));
  }
}

function* updateChecklist({ payload }) {
  const checklistInstance = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + payload.id)
    .then((res) => {
      return res.json();
    });
  delete checklistInstance.createdby;
  delete checklistInstance.Created_time;
  delete checklistInstance.stamp;
  delete checklistInstance.Updated_time;
  const checklistInstanceNorm = evadb.normalizeInstance(checklistInstance);
  checklistInstanceNorm.properties.Startdatum.propertyValue = payload.start
    ? payload.start.toString("yyyy-MM-dd")
    : "";
  checklistInstanceNorm.properties.Enddatum.propertyValue = payload.end
    ? payload.end.toString("yyyy-MM-dd")
    : "";
  checklistInstanceNorm.properties.Workstation.propertyValue =
    payload.workstation || "";
  if (!checklistInstanceNorm.properties.AssignedEmployee) {
    checklistInstanceNorm.properties = {
      ...checklistInstanceNorm.properties,
      AssignedEmployee:
        checklistTemplate.properties[checklistTemplate.properties.length - 1],
    };
  }
  checklistInstanceNorm.properties.AssignedEmployee.propertyValue =
    payload.assignedEmployee || "";
  // because of a bug where a system had workshares as an object instead of JSON string, verify here that workshares are stringified
  checklistInstanceNorm.properties.Workshares.propertyValue =
    typeof checklistInstanceNorm.properties.Workshares.propertyValue !==
    "string"
      ? JSON.stringify(
          checklistInstanceNorm.properties.Workshares.propertyValue
        )
      : checklistInstanceNorm.properties.Workshares.propertyValue;
  const checklistUpdated = evadb.denormalizeInstance(checklistInstanceNorm);
  const apiResponse = yield api
    .postData("/modules/db/instance/Instance", checklistUpdated)
    .then((res) => {
      return res;
    });
  if (apiResponse.status === 200) {
    yield put(updateLeicaChecklistSuccess());
  } else {
    yield put(updateLeicaChecklistError(apiResponse));
  }
}

function* updateSonderraumChecklist({ payload }) {
  const sonderraumChecklistInstance = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + payload.id)
    .then((res) => {
      return res.json();
    });
  delete sonderraumChecklistInstance.createdby;
  delete sonderraumChecklistInstance.Created_time;
  delete sonderraumChecklistInstance.stamp;
  delete sonderraumChecklistInstance.Updated_time;
  const sonderraumChecklistInstanceNorm = evadb.normalizeInstance(
    sonderraumChecklistInstance
  );
  sonderraumChecklistInstanceNorm.properties.Startdatum.propertyValue =
    payload.start ? payload.start.toString("yyyy-MM-dd") : "";
  sonderraumChecklistInstanceNorm.properties.Enddatum.propertyValue =
    payload.end ? payload.end.toString("yyyy-MM-dd") : "";
  sonderraumChecklistInstanceNorm.properties.AssignedResource.propertyValue =
    payload.workstation || "";
  if (!sonderraumChecklistInstanceNorm.properties.AssignedEmployee) {
    sonderraumChecklistInstanceNorm.properties = {
      ...sonderraumChecklistInstanceNorm.properties,
      AssignedEmployee:
        checklistTemplate.properties[checklistTemplate.properties.length - 1],
    };
  }
  sonderraumChecklistInstanceNorm.properties.AssignedEmployee.propertyValue =
    payload.assignedEmployee || "";
  const sonderraumChecklistUpdated = evadb.denormalizeInstance(
    sonderraumChecklistInstanceNorm
  );
  const apiResponse = yield api
    .postData("/modules/db/instance/Instance", sonderraumChecklistUpdated)
    .then((res) => {
      return res;
    });
  if (apiResponse.status === 200) {
    yield put(updateLeicaChecklistSuccess());
  } else {
    yield put(updateLeicaChecklistError(apiResponse));
  }
}

function* updateResourceAbsenceTimes({ payload }) {
  const updatedInstance = ResourceTemplate;
  delete updatedInstance.createdby;
  delete updatedInstance.Created_time;
  delete updatedInstance.stamp;
  delete updatedInstance.Updated_time;
  updatedInstance.instanceId = payload.resource.id;
  updatedInstance.properties[0].propertyValue = payload.resource.name;
  updatedInstance.properties[1].propertyValue = payload.resource.type;
  updatedInstance.properties[2].propertyValue = JSON.stringify(
    payload.newAbsenceTimes
  );
  const apiResponse = yield api
    .postData("/modules/db/instance/Instance", updatedInstance)
    .then((res) => {
      return res;
    });
  if (apiResponse.status === 200) {
    const updatedResource = {
      ...payload.resource,
      absenceTimes: payload.newAbsenceTimes,
    };
    yield put(putLeicaResourceAbsenceTimesSuccess(updatedResource));
  } else {
    yield put(putLeicaResourceAbsenceTimesError(apiResponse));
  }

  yield put(putLeicaResourceAbsenceTimesSuccess());
}

function* deleteSystem(id) {
  const systemId = id.payload.systemId;
  let systemResults = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + systemId)
    .then((res) => {
      return res.json();
    });
  if (typeof systemResults !== "object") {
    yield put(deleteLeicaSystemError("Systemnumber not found!"));
    return 400; // return unknown error code
  }
  systemResults = evadb.normalizeInstance(systemResults);
  const checklistId = systemResults.properties.Checklist.propertyValue;

  if (typeof checklistId === "undefined" || checklistId === "") {
    yield put(deleteLeicaSystemError("No Checklist for this System!"));
    return 400; // return unknown error code
  }
  let checklist = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + checklistId)
    .then((res) => {
      return res.json();
    });
  checklist = evadb.normalizeInstance(checklist);

  const steps = checklist.properties.Steps.propertyValue.split(",");
  let stepBulks = [];

  for (let i = 0; i * 60 <= steps.length; i++) {
    stepBulks.push(steps.splice(i * 60, 60));
  }

  let error = false;

  stepBulks.map((bulk) =>
    api
      .deleteData("/modules/db/instance/DeleteBulk?ids=" + bulk.join(","))
      .then((res) => {
        if (res.status !== 200) {
          error = true;
        }
      })
  );
  if (!error) {
    error = yield api
      .deleteData(
        "/modules/db/instance/DeleteBulk?ids=" + systemId + "," + checklistId
      )
      .then((res) => {
        return res.status;
      });
  } else {
    yield put(deleteLeicaSystemError("Couldn't Delete System"));
    return 400; // return unknown error code
  }
  if (error === 200) {
    yield put(deleteLeicaSystemSuccess(systemId));
    return error; // return success code
  } else {
    yield put(deleteLeicaSystemError("Couldn't Delete System"));
    return 400; // return unknown error code
  }
}

function* submitExcelWithMapping(data) {
  const formdata = data.payload.formdata;
  const opts = { isFormData: true };
  let error = 400;
  // let response = yield leicaServiceApi
  //     .postData("/Upload_Excel", formdata, opts)
  let response = yield api
    .postData("/modules/Leica1/endpoints/Upload_Excel", formdata, opts)
    .then((res) => res.json());
  error = response.response_code;

  if (error === 200) {
    yield put(submitExcelWithMappedHeadersSuccess(response));
  } else {
    yield put(
      submitExcelWithMappedHeadersError(
        "Unknown Error While Submitting File With Header Mapping To API"
      )
    );
  }
}

function* submitNewSystem(data) {
  let error = 400;
  let errorMsg = "unknown error";
  const postdata = data.payload.postdata;

  // const response = yield leicaServiceApi
  // .postData("/Create_Microscope", postdata)
  const response = yield api
    .postData("/modules/Leica1/endpoints/Create_Microscope", postdata)
    .then((res) => {
      if (res.status === 200) {
        error = 200;
      } else if (res.status === 409) {
        error = 409;
      } else if (res.status === 403) {
        error = 403;
      }
      return res.json();
    });
  if (error === 200) {
    yield put(submitNewLeicaSystemSuccess(response.special_parts));
  } else if (error === 409) {
    // system number is not unique
    yield put(submitNewLeicaSystemError(error));
  } else if (error === 403) {
    // end date is too early, production start would be in the past
    yield put(submitNewLeicaSystemError(error));
  } else {
    // unknown error
    yield put(submitNewLeicaSystemError(error));
  }
  return error;
}

function* updateSystemConfig({ payload }) {
  const systemId = payload.data.systemId;
  const lastPossibleCompletionDate = payload.data.lastPossibleCompletionDate;
  const customerExpectanceDate = payload.data.customerExpectanceDate;
  const sonderraumChecklistId = payload.data.sonderraumChecklistId;
  // const newSonderraumDuration = payload.data.sonderraumDuration

  // fetch system to get checklist id
  let systemInstance = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + systemId)
    .then((res) => {
      return res.json();
    });
  const normalizedSystemInstance = evadb.normalizeInstance(systemInstance);

  const checklistId =
    normalizedSystemInstance.properties.Checklist.propertyValue;

  // fetch sonderraum checklist instance to update
  let sonderraumChecklistInstance = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + sonderraumChecklistId)
    .then((res) => {
      return res.json();
    });
  // update properties
  // sonderraumChecklistInstance.properties[0].propertyValue = newSonderraumDuration // duration
  delete sonderraumChecklistInstance.createdby;
  delete sonderraumChecklistInstance.Created_time;
  delete sonderraumChecklistInstance.stamp;
  delete sonderraumChecklistInstance.Updated_time;
  const sonderraumApiResponse = yield api
    .postData("/modules/db/instance/Instance", sonderraumChecklistInstance)
    .then((res) => {
      return res;
    });
  if (sonderraumApiResponse.status !== 200) {
    yield put(updateLeicaSystemConfigError(sonderraumApiResponse));
  }

  // update customer expectance date and last possible completion date on system instance
  let updatedSystemProperties = systemInstance.properties;
  updatedSystemProperties[
    updatedSystemProperties.findIndex(
      (property) => property.propertyName == "CustomerExpectanceDate"
    )
  ].propertyValue = customerExpectanceDate;
  updatedSystemProperties[
    updatedSystemProperties.findIndex(
      (property) => property.propertyName == "LatestPossibleCompletionDate"
    )
  ].propertyValue = lastPossibleCompletionDate;

  systemInstance.properties = updatedSystemProperties;
  delete systemInstance.createdby;
  delete systemInstance.Created_time;
  delete systemInstance.stamp;
  delete systemInstance.Updated_time;

  const systemApiResponse = yield api
    .postData("/modules/db/instance/Instance", systemInstance)
    .then((res) => {
      return res;
    });

  // fetch checklist instance to check if scheduling needs to be updated
  let checklistInstance = yield api
    .getData("/modules/db/instance/GetInstancebyId?id=" + checklistId)
    .then((res) => {
      return res.json();
    });
  const normalizedChecklistInstance =
    evadb.normalizeInstance(checklistInstance);
  let updatedProperties = checklistInstance.properties;

  // check if the last possible completion date is now before enddatum
  // if so, delete startdatum, enddatum and workstation to unschedule system and place back in queue
  const enddatum = new Date(
    normalizedChecklistInstance.properties.Enddatum.propertyValue
  );
  if (!Number.isNaN(enddatum.getTime())) {
    if (enddatum > new Date(lastPossibleCompletionDate)) {
      updatedProperties[
        updatedProperties.findIndex(
          (property) => property.propertyName == "Startdatum"
        )
      ].propertyValue = "";
      updatedProperties[
        updatedProperties.findIndex(
          (property) => property.propertyName == "Enddatum"
        )
      ].propertyValue = "";
      updatedProperties[
        updatedProperties.findIndex(
          (property) => property.propertyName == "Workstation"
        )
      ].propertyValue = "";

      checklistInstance.properties = updatedProperties;
      delete checklistInstance.createdby;
      delete checklistInstance.Created_time;
      delete checklistInstance.stamp;
      delete checklistInstance.Updated_time;

      const apiResponse = yield api
        .postData("/modules/db/instance/Instance", checklistInstance)
        .then((res) => {
          return res;
        });
    }
  }
  // if (apiResponse.status === 200) {
  yield put(updateLeicaSystemConfigSuccess());
  // } else {
  //   yield put(updateLeicaSystemConfigError(apiResponse))
  // }
}

function* getDowntimeKPI() {

  let issues =  yield api    
    .getData("/modules/db/instance/GetByTemplateId?templateId=" + issueTemplateId)
    .then((res) => {
      return res.json();
  });
  
  let systems =  yield api    
    .getData("/modules/db/instance/GetByTemplateId?templateId=" + systemTemplateId)
    .then((res) => {
      return res.json();
  });
    
  let checklists =  yield api    
    .getData("/modules/db/instance/GetByTemplateId?templateId=" + checklistTemplateId)
    .then((res) => {
      return res.json();
  });

  let sgrChecklists =  yield api    
    .getData("/modules/db/instance/GetByTemplateId?templateId=" + sgrChecklistTemplateId)
    .then((res) => {
      return res.json();
  });

  //normalize Issues
  let normalizedIssues = []; 
  issues.forEach((issue) =>{
    normalizedIssues.push(evadb.normalizeInstance(issue));
  });  

  //normalize Systems
  let normalizedSystems = [];
  systems.forEach((system) => {
    normalizedSystems.push(evadb.normalizeInstance(system));
  });

  //normalize Checklists
  let normalizedChecklists = {}; 
  checklists.forEach((checklist) =>{
    let normChecklist = (evadb.normalizeInstance(checklist));
    normalizedChecklists[normChecklist.instanceId] = normChecklist.properties;
  });  
  
  //normalize SGRChecklists
  let normalizedSGRChecklists = {}; 
  sgrChecklists.forEach((sgrChecklist) =>{
    let normSGRChecklist = (evadb.normalizeInstance(sgrChecklist));
    normalizedSGRChecklists[normSGRChecklist.instanceId] = normSGRChecklist.properties;
  });

  //agregate Systemdata
  let issuesByChecklist = {};

  normalizedIssues.forEach((issue) => {
    let checklistId = issue.properties.Checklist.propertyValue;
    let bereich = issue.properties.Bereich.propertyValue;
    let zeitverlust = issue.properties.Zeitverlust.propertyValue;
    issuesByChecklist[checklistId] ? 
      issuesByChecklist[checklistId][bereich] ?  
        issuesByChecklist[checklistId][bereich] = issuesByChecklist[checklistId][bereich] + parseFloat(zeitverlust) : issuesByChecklist[checklistId][bereich] = parseFloat(zeitverlust)
      :
      issuesByChecklist[checklistId] = {"SIT": 0, "Endabnahme": 0, "Modulbau": 0 };
      issuesByChecklist[checklistId][bereich] = parseFloat(zeitverlust);
  });

  let kpiRelevantSystems = {}
  let prepObject = {};
  normalizedSystems.forEach((system) => {

    if(system.properties.Status.propertyValue == "completed"){     
      let systemnumber = system.properties.SystemNrLeica.propertyValue;
      prepObject["checklist"] = system.properties.Checklist.propertyValue;
      prepObject["sgrChecklist"] = system.properties.SonderraumChecklist.propertyValue;

      if (normalizedSGRChecklists[prepObject["sgrChecklist"]].Steps.propertyValue !== ""){
        prepObject["enddate"] = Date.parse(normalizedSGRChecklists[prepObject["sgrChecklist"]].Enddatum.propertyValue);
      }
      else {
        prepObject["sgrChecklist"] = "";
        prepObject["enddate"] = Date.parse(normalizedChecklists[prepObject["checklist"]].Enddatum.propertyValue);
      }
    
      prepObject["downtime"] = {
        "SIT":  issuesByChecklist[prepObject["checklist"]] ? issuesByChecklist[prepObject["checklist"]].SIT : 0 
                + 
                issuesByChecklist[prepObject["sgrChecklist"]] ? issuesByChecklist[prepObject["sgrChecklist"]].SIT : 0,
        "Final":  issuesByChecklist[prepObject["checklist"]] ? issuesByChecklist[prepObject["checklist"]].Endabnahme : 0
                  +
                  issuesByChecklist[prepObject["sgrChecklist"]] ? issuesByChecklist[prepObject["sgrChecklist"]].Endabnahme : 0, 
        "Modulbau": issuesByChecklist[prepObject["checklist"]] ? issuesByChecklist[prepObject["checklist"]].Modulbau: 0
                    +
                    issuesByChecklist[prepObject["sgrChecklist"]] ? issuesByChecklist[prepObject["sgrChecklist"]].Modulbau : 0
      }
      kpiRelevantSystems[systemnumber] = prepObject;
      prepObject = {};
    }
  });

  //Calculate KPI start Dates
  let today = new Date();
  today.setSeconds(0);
  today.setMinutes(0);
  today.setHours(0);

  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);  

  //gets Date of Monday of current week (Index 1 of current week)
  //if index = 0 (sunday) week is corrected since getDay defines week from Sunday to saturday
  let weekstart = new Date(today);
  weekstart.getDay() == 0 ? weekstart.setDate(weekstart.getDate() - 6 ) : weekstart.setDate(weekstart.getDate() - weekstart.getDay() + 1);

  let monthstart = new Date(today);
  monthstart.setDate(1);

  let quarterstart = new Date(today);
  let month;

  switch (Math.floor(quarterstart.getMonth()/3)) {
    case 0:
      month = 0;
      break;
    case 1:
      month = 3;
      break;
    case 2:
      month = 6;
      break;
    case 3:
      month = 9;
  }

  quarterstart.setMonth(month);
  quarterstart.setDate(1);

  let yearstart = new Date(today);
  yearstart.setDate(1);
  yearstart.setMonth(0);

  //get length of KPI Durations
  const daysOfWeek = today.getDay();
  const daysOfMonth = Math.ceil((today - monthstart) / (1000 * 3600 * 24)) + 1;
  const daysOfQuarter = Math.ceil((today - quarterstart) / (1000 * 3600 * 24)) + 1;
  const daysOfYear = Math.ceil((today - yearstart) / (1000 * 3600 * 24)) + 1;
  
  let kpiDataYesterday = getDowntimeDataInTimeframe (kpiRelevantSystems,yesterday,today);
  let kpiDataWTD = getDowntimeDataInTimeframe (kpiRelevantSystems,weekstart,today);
  let kpiDataMTD = getDowntimeDataInTimeframe (kpiRelevantSystems,monthstart,today);
  let kpiDataQTD = getDowntimeDataInTimeframe (kpiRelevantSystems,quarterstart,today);
  let kpiDataYTD = getDowntimeDataInTimeframe (kpiRelevantSystems,yearstart,today);
  let kpidatalog= {};

  for (const [systemNr, system] of Object.entries(kpiRelevantSystems)) {
    if (system.enddate >= monthstart){
      kpidatalog[systemNr] = system
    }
  };
  

  let response = {
    SIT: {
      avDowntimeYesterday: kpiDataYesterday.totalDowntimeSIT == 0 ? 0 : kpiDataYesterday.totalDowntimeSIT / kpiDataYesterday.systemCountTotal,
      avDowntimeMTD: kpiDataMTD.totalDowntimeSIT == 0 ? 0 : kpiDataMTD.totalDowntimeSIT / kpiDataMTD.systemCountTotal,
      "%MTD": kpiDataMTD.systemCountInPlanSIT == 0 ? 0 : kpiDataMTD.systemCountInPlanSIT / kpiDataMTD.systemCountTotal,
      "%QTD": kpiDataQTD.systemCountInPlanSIT == 0 ? 0 : kpiDataQTD.systemCountInPlanSIT / kpiDataQTD.systemCountTotal,
      "%YTD": kpiDataYTD.systemCountInPlanSIT == 0 ? 0 : kpiDataYTD.systemCountInPlanSIT / kpiDataYTD.systemCountTotal,
    },
    Final: {
      avDowntimeYesterday: kpiDataYesterday.totalDowntimeFinal == 0 ? 0 : kpiDataYesterday.totalDowntimeFinal / kpiDataYesterday.systemCountTotal,
      avDowntimeMTD: kpiDataMTD.totalDowntimeFinal == 0 ? 0 : kpiDataMTD.totalDowntimeFinal / kpiDataMTD.systemCountTotal,
      "%MTD": kpiDataMTD.systemCountInPlanFinal == 0 ? 0 : kpiDataMTD.systemCountInPlanFinal / kpiDataMTD.systemCountTotal,
      "%QTD": kpiDataQTD.systemCountInPlanFinal == 0 ? 0 : kpiDataQTD.systemCountInPlanFinal / kpiDataQTD.systemCountTotal,
      "%YTD": kpiDataYTD.systemCountInPlanFinal == 0 ? 0 : kpiDataYTD.systemCountInPlanFinal / kpiDataYTD.systemCountTotal,
    },
    Total: {
      avDowntimeYesterday: kpiDataYesterday.totalDowntimeTotal == 0 ? 0 : kpiDataYesterday.totalDowntimeTotal / kpiDataYesterday.systemCountTotal,
      avDowntimeMTD: kpiDataMTD.totalDowntimeTotal == 0 ? 0 : kpiDataMTD.totalDowntimeTotal / kpiDataMTD.systemCountTotal,
      "%MTD": kpiDataMTD.systemCountInPlanTotal == 0 ? 0 : kpiDataMTD.systemCountInPlanTotal / kpiDataMTD.systemCountTotal,
      "%QTD": kpiDataQTD.systemCountInPlanTotal == 0 ? 0 : kpiDataQTD.systemCountInPlanTotal / kpiDataQTD.systemCountTotal,
      "%YTD": kpiDataYTD.systemCountInPlanTotal == 0 ? 0 : kpiDataYTD.systemCountInPlanTotal / kpiDataYTD.systemCountTotal,
    },
    Modulbau: {
      totalDowntimeYesterday: kpiDataYesterday.totalDowntimeModulbau,
      DowntimePerDayWTD: kpiDataWTD.totalDowntimeModulbau == 0 ? 0 : kpiDataWTD.totalDowntimeModulbau / daysOfWeek,
      DowntimePerDayMTD: kpiDataMTD.totalDowntimeModulbau == 0 ? 0 : kpiDataMTD.totalDowntimeModulbau / daysOfMonth,
      DowntimePerDayQTD: kpiDataQTD.totalDowntimeModulbau == 0 ? 0 : kpiDataQTD.totalDowntimeModulbau / daysOfQuarter,
      DowntimePerDayYTD: kpiDataYTD.totalDowntimeModulbau == 0 ? 0 : kpiDataYTD.totalDowntimeModulbau / daysOfYear,
    },
  };

  yield put(getLeicaDowntimeKPIsSuccess(response));
}

export default function* rootSaga() {
  yield all([
    fork(function* watchFetchAdminDashboardSettings() {
      yield takeEvery(
        LEICA_GET_ADMINDASHBOARD_SETTINGS,
        fetchAdminDashboardSettings
      );
    }),
  ]);
  yield all([
    fork(function* watchPutAdminDashboardSettings() {
      yield takeEvery(
        LEICA_SET_ADMINDASHBOARD_SETTINGS,
        putAdminDashboardSettings
      );
    }),
  ]);
  yield all([
    fork(function* watchFetchPartTemplates() {
      yield takeEvery(LEICA_GET_PARTTEMPLATES, fetchPartTemplates);
    }),
  ]);
  yield all([
    fork(function* watchCreateOrUpdatePartTemplates() {
      yield takeEvery(
        LEICA_CREATE_OR_UPDATE_PARTTEMPLATES,
        createOrUpdatePartTemplates
      );
    }),
  ]);
  yield all([
    fork(function* watchDeletePartTemplates() {
      yield takeEvery(LEICA_DELETE_PARTTEMPLATES, deletePartTemplates);
    }),
  ]);
  yield all([
    fork(function* watchFetchSystems() {
      yield takeEvery(LEICA_GET_SYSTEMS_FOR_SYSTEM_DB, fetchSystems);
    }),
  ]);
  yield all([
    fork(function* watchFetchResources() {
      yield takeEvery(LEICA_GET_RESOURCES, fetchResources);
    }),
  ]);
  yield all([
    fork(function* watchFetchEmployees() {
      yield takeEvery(LEICA_GET_EMPLOYEES, fetchEmployees);
    }),
  ]);
  yield all([
    fork(function* watchFetchSystemsWithChecklistData() {
      yield takeEvery(
        LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA,
        fetchSystemsWithChecklistData
      );
    }),
  ]);
  yield all([
    fork(function* watchFetchFactoryboardData() {
      yield takeEvery(LEICA_GET_FACTORYBOARD_DATA, fetchFactoryboardData);
    }),
  ]);
  yield all([
    fork(function* watchFetchDepartureBoardData() {
      yield takeEvery(LEICA_GET_DEPARTUREBOARD_DATA, fetchDepartureBoardData);
    }),
  ]);
  yield all([
    fork(function* watchFetchOverview() {
      yield takeEvery(LEICA_GET_OVERVIEW, fetchOverview);
    }),
  ]);
  yield all([
    fork(function* watchFetchIssuesById() {
      yield takeEvery(LEICA_GET_ISSUES_BY_ID, fetchIssuesById);
    }),
  ]);
  yield all([
    fork(function* watchFetchChecklistByStepIds() {
      yield takeEvery(LEICA_GET_CHECKLIST_BY_STEPIDS, fetchChecklistByStepIds);
    }),
  ]);
  yield all([
    fork(function* watchFetchHoldTimes() {
      yield takeEvery(LEICA_GET_HOLD_TIMES, fetchHoldTimes);
    }),
  ]);
  yield all([
    fork(function* watchAutoscheduleNewSystem() {
      yield takeEvery(LEICA_AUTOSCHEDULE_NEW_SYSTEM, autoscheduleNewSystem);
    }),
  ]);
  yield all([
    fork(function* watchRescheduleSystems() {
      yield takeEvery(LEICA_RESCHEDULE_SYSTEMS, rescheduleSystems);
    }),
  ]);
  yield all([
    fork(function* watchAutoOrganizeSystems() {
      yield takeEvery(
        LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD,
        autoOrganizeSystems
      );
    }),
  ]);
  yield all([
    fork(function* watchSetStepComplete() {
      yield takeEvery(LEICA_SET_STEPCOMPLETE, SetStepComplete);
    }),
  ]);
  yield all([
    fork(function* watchPutSondernummern() {
      yield takeEvery(LEICA_PUT_SONDERNUMMERN, putSondernummern);
    }),
  ]);
  yield all([
    fork(function* watchToggleWorkstate() {
      yield takeEvery(LEICA_TOGGLE_WORKSTATUS, ToggleWorkstate);
    }),
  ]);
  yield all([
    fork(function* watchPutIssue() {
      yield takeEvery(LEICA_PUT_ISSUE, createIssue);
    }),
  ]);
  yield all([
    fork(function* watchPutResourceAbsenceTimes() {
      yield takeEvery(
        LEICA_PUT_RESOURCE_ABSENCE_TIMES,
        updateResourceAbsenceTimes
      );
    }),
  ]);
  // yield all([
  //   fork(function* watchPutHoldTime() {
  //     yield takeEvery(LEICA_PUT_HOLD_TIME, updateHoldTime);
  //   }),
  // ]);
  // yield all([
  //   fork(function* watchDeleteHoldTime() {
  //     yield takeEvery(LEICA_DELETE_HOLD_TIME, deleteHoldTime);
  //   }),
  // ]);
  yield all([
    fork(function* watchCreateHoldTime() {
      yield takeEvery(LEICA_CREATE_HOLDTIME, createHoldTime);
    }),
  ]);
  yield all([
    fork(function* watchDeleteHoldTime() {
      yield takeEvery(LEICA_DELETE_HOLDTIME, deleteHoldTime);
    }),
  ]);
  yield all([
    fork(function* watchUpdateHoldTime() {
      yield takeEvery(LEICA_UPDATE_HOLDTIME, updateHoldTime);
    }),
  ]);
  yield all([
    fork(function* watchUpdateChecklist() {
      yield takeEvery(LEICA_UPDATE_CHECKLIST, updateChecklist);
    }),
  ]);
  yield all([
    fork(function* watchUpdateSonderraumChecklist() {
      yield takeEvery(
        LEICA_UPDATE_SONDERRAUM_CHECKLIST,
        updateSonderraumChecklist
      );
    }),
  ]);
  yield all([
    fork(function* watchDeleteSystem() {
      yield takeEvery(LEICA_DELETE_SYSTEM, deleteSystem);
    }),
  ]);
  yield all([
    fork(function* watchSubmitExcelWithMapping() {
      yield takeEvery(
        LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS,
        submitExcelWithMapping
      );
    }),
  ]);
  yield all([
    fork(function* watchSubmitNewSystem() {
      yield takeEvery(LEICA_SUBMIT_NEW_SYSTEM, submitNewSystem);
    }),
  ]);
  yield all([
    fork(function* watchUpdateSystemConfig() {
      yield takeEvery(LEICA_UPDATE_SYSTEM_CONFIG, updateSystemConfig);
    }),
  ]);
  yield all([
    fork(function* watchGetDowntimeKPI() {
      yield takeEvery(LEICA_GET_DOWNTIMEKPI, getDowntimeKPI);
    }),
  ]);
}

