// this maps the new (hasura/postgres db) status keys to older (mongoDB) status key strings

export const SYSTEM_STATUS_MAPPING = {
    "NEW_SYSTEM": "newsystem",
    "STARTED": "started",
    "TRANSITION_TO_SIT": "transitiontosit",
    "IN_PRODUCTION": "inproduction",
    "TRANSITION_TO_SGR": "transitiontosgr",
    "SGR_IN_PRODUCTION": "sgrinproduction",
    "COMPLETED": "completed"
}
