/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/*LEICA ONE APP */
export const LEICA_GET_ADMINDASHBOARD_SETTINGS =
  "LEICA_GET_ADMINDASHBOARD_SETTINGS";
export const LEICA_GET_ADMINDASHBOARD_SETTINGS_SUCCESS =
  "LEICA_GET_ADMINDASHBOARD_SETTINGS_SUCCESS";
export const LEICA_GET_ADMINDASHBOARD_SETTINGS_ERROR =
  "LEICA_GET_ADMINDASHBOARD_SETTINGS_ERROR";

export const LEICA_SET_ADMINDASHBOARD_SETTINGS =
  "LEICA_SET_ADMINDASHBOARD_SETTINGS";
export const LEICA_SET_ADMINDASHBOARD_SETTINGS_SUCCESS =
  "LEICA_SET_ADMINDASHBOARD_SETTINGS_SUCCESS";
export const LEICA_SET_ADMINDASHBOARD_SETTINGS_ERROR =
  "LEICA_SET_ADMINDASHBOARD_SETTINGS_ERROR";

export const LEICA_GET_PARTTEMPLATES = "LEICA_GET_PARTTEMPLATES";
export const LEICA_GET_PARTTEMPLATES_SUCCESS =
  "LEICA_GET_PARTTEMPLATES_SUCCESS";
export const LEICA_GET_PARTTEMPLATES_ERROR = "LEICA_GET_PARTTEMPLATES_ERROR";

export const LEICA_CREATE_OR_UPDATE_PARTTEMPLATES =
  "LEICA_CREATE_OR_UPDATE_PARTTEMPLATES";
export const LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_SUCCESS =
  "LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_SUCCESS";
export const LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_ERROR =
  "LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_ERROR";

export const LEICA_DELETE_PARTTEMPLATES = "LEICA_DELETE_PARTTEMPLATES";
export const LEICA_DELETE_PARTTEMPLATES_SUCCESS =
  "LEICA_DELETE_PARTTEMPLATES_SUCCESS";
export const LEICA_DELETE_PARTTEMPLATES_ERROR =
  "LEICA_DELETE_PARTTEMPLATES_ERROR";

export const LEICA_GET_SYSTEMS_FOR_SYSTEM_DB =
  "LEICA_GET_SYSTEMS_FOR_SYSTEM_DB";
export const LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_SUCCESS =
  "LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_SUCCESS";
export const LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_ERROR =
  "LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_ERROR";

export const LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA =
  "LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA";
export const LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_SUCCESS =
  "LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_SUCCESS";
export const LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_ERROR =
  "LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_ERROR";

export const LEICA_GET_FACTORYBOARD_DATA = "LEICA_GET_FACTORYBOARD_DATA";
export const LEICA_GET_FACTORYBOARD_DATA_SUCCESS =
  "LEICA_GET_FACTORYBOARD_DATA_SUCCESS";
export const LEICA_GET_FACTORYBOARD_DATA_ERROR =
  "LEICA_GET_FACTORYBOARD_DATA_ERROR";

export const LEICA_GET_DEPARTUREBOARD_DATA = "LEICA_GET_DEPARTUREBOARD_DATA";
export const LEICA_GET_DEPARTUREBOARD_DATA_SUCCESS =
  "LEICA_GET_DEPARTUREBOARD_DATA_SUCCESS";
export const LEICA_GET_DEPARTUREBOARD_DATA_ERROR =
  "LEICA_GET_DEPARTUREBOARD_DATA_ERROR";

export const LEICA_GET_SYSTEMLOADOUT = "LEICA_GET_SYSTEMLOADOUT";
export const LEICA_GET_SYSTEMLOADOUT_SUCCESS =
  "LEICA_GET_SYSTEMLOADOUT_SUCCESS";
export const LEICA_GET_SYSTEMLOADOUT_ERROR = "LEICA_GET_SYSTEMLOADOUT_ERROR";

export const LEICA_GET_OVERVIEW = "LEICA_GET_CHECKLISTVIEW";
export const LEICA_GET_OVERVIEW_SUCCESS = "LEICA_GET_CHECKLISTVIEW_SUCCESS";
export const LEICA_GET_OVERVIEW_ERROR = "LEICA_GET_CHECKLISTVIEW_ERROR";

export const LEICA_GET_ISSUES_BY_ID = "LEICA_GET_ISSUES_BY_ID";
export const LEICA_GET_ISSUES_BY_ID_SUCCESS = "LEICA_GET_ISSUES_BY_ID_SUCCESS";
export const LEICA_GET_ISSUES_BY_ID_ERROR = "LEICA_GET_ISSUES_BY_ID_ERROR";

export const LEICA_GET_CHECKLIST_BY_STEPIDS = "LEICA_GET_CHECKLIST_BY_STEPIDS";
export const LEICA_GET_CHECKLIST_BY_STEPIDS_SUCCESS =
  "LEICA_GET_CHECKLIST_BY_STEPIDS_SUCCESS";
export const LEICA_GET_CHECKLIST_BY_STEPIDS_ERROR =
  "LEICA_GET_CHECKLIST_BY_STEPIDS_ERROR";

export const LEICA_GET_HOLD_TIMES = "LEICA_GET_HOLD_TIMES";
export const LEICA_GET_HOLD_TIMES_SUCCESS = "LEICA_GET_HOLD_TIMES_SUCCESS";
export const LEICA_GET_HOLD_TIMES_ERROR = "LEICA_GET_HOLD_TIMES_ERROR";

export const LEICA_GET_RESOURCES = "LEICA_GET_RESOURCES";
export const LEICA_GET_RESOURCES_SUCCESS = "LEICA_GET_RESOURCES_SUCCESS";
export const LEICA_GET_RESOURCES_ERROR = "LEICA_GET_RESOURCES_ERROR";

export const LEICA_GET_EMPLOYEES = "LEICA_GET_EMPLOYEES";
export const LEICA_GET_EMPLOYEES_SUCCESS = "LEICA_GET_EMPLOYEES_SUCCESS";
export const LEICA_GET_EMPLOYEES_ERROR = "LEICA_GET_EMPLOYEES_ERROR";

export const LEICA_AUTOSCHEDULE_NEW_SYSTEM = "LEICA_AUTOSCHEDULE_NEW_SYSTEM";
export const LEICA_AUTOSCHEDULE_NEW_SYSTEM_SUCCESS =
  "LEICA_AUTOSCHEDULE_NEW_SYSTEM_SUCCESS";
export const LEICA_AUTOSCHEDULE_NEW_SYSTEM_ERROR =
  "LEICA_AUTOSCHEDULE_NEW_SYSTEM_ERROR";

export const LEICA_RESCHEDULE_SYSTEMS = "LEICA_RESCHEDULE_SYSTEMS";
export const LEICA_RESCHEDULE_SYSTEMS_SUCCESS =
  "LEICA_RESCHEDULE_SYSTEMS_SUCCESS";
export const LEICA_RESCHEDULE_SYSTEMS_ERROR = "LEICA_RESCHEDULE_SYSTEMS_ERROR";

export const LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD =
  "LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD";
export const LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_SUCCESS =
  "LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_SUCCESS";
export const LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_ERROR =
  "LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_ERROR";

export const LEICA_SET_STEPCOMPLETE = "LEICA_SET_STEPCOMPLETE";
export const LEICA_SET_STEPCOMPLETE_SUCCESS = "LEICA_SET_STEPCOMPLETE_SUCCESS";
export const LEICA_SET_STEPCOMPLETE_ERROR = "LEICA_SET_STEPCOMPLETE_ERROR";

export const LEICA_TOGGLE_WORKSTATUS = "LEICA_TOGGLE_WORKSTATUS";
export const LEICA_TOGGLE_WORKSTATUS_SUCCESS =
  "LEICA_TOGGLE_WORKSTATUS_SUCCESS";
export const LEICA_TOGGLE_WORKSTATUS_ERROR = "LEICA_TOGGLE_WORKSTATUS_ERROR";

export const LEICA_DELETE_SYSTEM = "LEICA_DELETE_SYSTEM";
export const LEICA_DELETE_SYSTEM_SUCCESS = "LEICA_DELETE_SYSTEM_SUCCESS";
export const LEICA_DELETE_SYSTEM_ERROR = "LEICA_DELETE_SYSTEM_ERROR";

export const LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS =
  "LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS";
export const LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_SUCCESS =
  "LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_SUCCESS";
export const LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_ERROR =
  "LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_ERROR";

export const LEICA_SUBMIT_NEW_SYSTEM = "LEICA_SUBMIT_NEW_SYSTEM";
export const LEICA_SUBMIT_NEW_SYSTEM_SUCCESS =
  "LEICA_SUBMIT_NEW_SYSTEM_SUCCESS";
export const LEICA_SUBMIT_NEW_SYSTEM_ERROR = "LEICA_SUBMIT_NEW_SYSTEM_ERROR";

export const LEICA_UPDATE_SYSTEM_CONFIG = "LEICA_UPDATE_SYSTEM_CONFIG";
export const LEICA_UPDATE_SYSTEM_CONFIG_SUCCESS =
  "LEICA_UPDATE_SYSTEM_CONFIG_SUCCESS";
export const LEICA_UPDATE_SYSTEM_CONFIG_ERROR =
  "LEICA_UPDATE_SYSTEM_CONFIG_ERROR";

export const LEICA_UPDATE_CHECKLIST = "LEICA_UPDATE_CHECKLIST";
export const LEICA_UPDATE_CHECKLIST_SUCCESS = "LEICA_UPDATE_CHECKLIST_SUCCESS";
export const LEICA_UPDATE_CHECKLIST_ERROR = "LEICA_UPDATE_CHECKLIST_ERROR";

export const LEICA_UPDATE_SONDERRAUM_CHECKLIST =
  "LEICA_UPDATE_SONDERRAUM_CHECKLIST";
export const LEICA_UPDATE_SONDERRAUM_CHECKLIST_SUCCESS =
  "LEICA_UPDATE_SONDERRAUM_CHECKLIST_SUCCESS";
export const LEICA_UPDATE_SONDERRAUM_CHECKLIST_ERROR =
  "LEICA_UPDATE_SONDERRAUM_CHECKLIST_ERROR";

  export const LEICA_UPDATE_HOLDTIME = "LEICA_UPDATE_HOLDTIME";
  export const LEICA_UPDATE_HOLDTIME_SUCCESS = "LEICA_UPDATE_HOLDTIME_SUCCESS";
  export const LEICA_UPDATE_HOLDTIME_ERROR = "LEICA_UPDATE_HOLDTIME_ERROR";

  export const LEICA_CREATE_HOLDTIME = "LEICA_CREATE_HOLDTIME";
  export const LEICA_CREATE_HOLDTIME_SUCCESS = "LEICA_CREATE_HOLDTIME_SUCCESS";
  export const LEICA_CREATE_HOLDTIME_ERROR = "LEICA_CREATE_HOLDTIME_ERROR";

  export const LEICA_DELETE_HOLDTIME = "LEICA_DELETE_HOLDTIME";
  export const LEICA_DELETE_HOLDTIME_SUCCESS = "LEICA_DELETE_HOLDTIME_SUCCESS";
  export const LEICA_DELETE_HOLDTIME_ERROR = "LEICA_DELETE_HOLDTIME_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

export const GET_USERLIST = "GET_USERLIST";
export const GET_USERLIST_SUCCESS = "GET_USERLIST_SUCCESS";
export const GET_USERLIST_ERROR = "GET_USERLIST_ERROR";

export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";
export const DELETE_USER_BY_ID_SUCCESS = "DELETE_USER_BY_ID_SUCCESS";
export const DELETE_USER_BY_ID_ERROR = "DELETE_USER_BY_ID_ERROR";

export const LEICA_PUT_ISSUE = "LEICA_PUT_ISSUE";
export const LEICA_PUT_ISSUE_SUCCESS = "LEICA_PUT_ISSUE_SUCCESS";
export const LEICA_PUT_ISSUE_ERROR = "LEICA_PUT_ISSUE_ERROR";

export const LEICA_PUT_SONDERNUMMERN = "LEICA_PUT_SONDERNUMMERN";
export const LEICA_PUT_SONDERNUMMERN_SUCCESS =
  "LEICA_PUT_SONDERNUMMERN_SUCCESS";
export const LEICA_PUT_SONDERNUMMERN_ERROR = "LEICA_PUT_SONDERNUMMERN_ERROR";

export const LEICA_PUT_RESOURCE_ABSENCE_TIMES =
  "LEICA_PUT_RESOURCE_ABSENCE_TIMES";
export const LEICA_PUT_RESOURCE_ABSENCE_TIMES_SUCCESS =
  "LEICA_PUT_RESOURCE_ABSENCE_TIMES_SUCCESS";
export const LEICA_PUT_RESOURCE_ABSENCE_TIMES_ERROR =
  "LEICA_PUT_RESOURCE_ABSENCE_TIMES_ERROR";

// export const LEICA_PUT_HOLD_TIME = "LEICA_PUT_HOLD_TIME";
// export const LEICA_PUT_HOLD_TIME_SUCCESS = "LEICA_PUT_HOLD_TIME_SUCCESS";
// export const LEICA_PUT_HOLD_TIME_ERROR = "LEICA_PUT_HOLD_TIME_ERROR";

// export const LEICA_DELETE_HOLD_TIME = "LEICA_DELETE_HOLD_TIME";
// export const LEICA_DELETE_HOLD_TIME_SUCCESS = "LEICA_DELETE_HOLD_TIME_SUCCESS";
// export const LEICA_DELETE_HOLD_TIME_ERROR = "LEICA_DELETE_HOLD_TIME_ERROR";

export const LEICA_GET_DOWNTIMEKPI = "LEICA_GET_DOWNTIMEKPI";
export const LEICA_GET_DOWNTIMEKPI_SUCCESS = "LEICA_GET_DOWNTIMEKPI_SUCCESS";
export const LEICA_GET_DOWNTIMEKPI_ERROR = "LEICA_GET_DOWNTIMEKPI_ERROR";

export const TEST_CHANGE_TOGGLE = "TEST_CHANGE_TOGGLE";
export const TEST_STEP_COMPLETE = "TEST_STEP_COMPLETE";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./leica/actions";
