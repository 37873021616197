const PartTemplate = {
    "instanceId": "",
    "templateId": "fff815abb4774f139f8073aa23b16a14",
    "identifier": "VK-Nummer",
    "imageUrl": "http://localhost:5010/db/static/assets/uploads/placeholder.jpg",
    "text": "Name",
    "title": "PartTemplate",
    "properties": [
      {
        "propertyName": "VK-Nummer",
        "propertyValue": "",
        "propertyId": "fff815abb4774f139f8073aa23b16a14",
        "accessMode": "automaticw",
        "dataType": "TEXT",
        "displayName": "",
        "formula": "",
        "index": ""
      },
      {
        "propertyName": "Option",
        "propertyValue": "",
        "propertyId": "fff815abb4774f139f8073aa23b16a14",
        "accessMode": "automaticw",
        "dataType": "TEXT",
        "displayName": "",
        "formula": "",
        "index": ""
      },
      {
        "propertyName": "Materialkurztext",
        "propertyValue": "",
        "propertyId": "fff815abb4774f139f8073aa23b16a14",
        "accessMode": "automaticw",
        "dataType": "TEXT",
        "displayName": "",
        "formula": "",
        "index": ""
      }
    ],
  }
  
  export default PartTemplate;
  