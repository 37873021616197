import { DayPilot } from "daypilot-pro-react";
import { timeBlockTemplate } from "../data/leica/factoryBoardEventObject";
import factoryboardColors from "../data/leica/factoryboardColors";
import { getDatesInTimeRange } from "./checklistDataCalculation";

function getHtmlTooltipSitFragment(
  checklistStatus,
  originallyExpectedWorkHours,
  minutesSpentToday,
  minutesRemainingInCurrentWP,
  workHoursRemainingAfterCurrentWP,
  deviationFromScheduleInWorkHours
) {
  // calculate and format time spent today
  const mTimeSpentToday = Math.round(minutesSpentToday % 60);
  const hTimeSpentToday = Math.round(
    (minutesSpentToday - mTimeSpentToday) / 60
  );

  // calculate and format remaining time
  const workMinutesRemaining =
    checklistStatus === "notstarted"
      ? originallyExpectedWorkHours * 60 // if checklist is not yet started, remaining time is identical with original time
      : minutesRemainingInCurrentWP + workHoursRemainingAfterCurrentWP * 60; // if started, add up all open time estimates
  const mTimeRemaining = Math.round(workMinutesRemaining % 60);
  const hTimeRemaining = Math.round(
    (workMinutesRemaining - mTimeRemaining) / 60
  );

  // calculate and format originally estimated time
  const originallyExpectedWorkMinutes = Math.round(
    originallyExpectedWorkHours * 60
  );
  const mOriginallyEstimatedDuration = Math.round(
    originallyExpectedWorkMinutes % 60
  );
  const hOriginallyEstimatedDuration = Math.round(
    (originallyExpectedWorkMinutes - mOriginallyEstimatedDuration) / 60
  );

  // calculate and format deviation
  // calculate and format originally estimated time
  const deviationFromScheduleInMinutes = Math.round(
    deviationFromScheduleInWorkHours * 60
  );
  const mDeviation = Math.round(deviationFromScheduleInMinutes % 60);
  const hDeviation = Math.round(
    (deviationFromScheduleInMinutes - mDeviation) / 60
  );

  // assemble tooltip html
  const tooltipFragment = `
                    <i>Originally expected work time in SIT:</i> ${hOriginallyEstimatedDuration} hr${
    hOriginallyEstimatedDuration === 1 ? "" : "s"
  }, ${mOriginallyEstimatedDuration} min${
    mOriginallyEstimatedDuration === 1 ? "" : "s"
  }<br/>
                    <i>Work time tracked on SIT checklist today:</i> ${hTimeSpentToday} hr${
    hTimeSpentToday === 1 ? "" : "s"
  }, ${mTimeSpentToday} min${mTimeSpentToday === 1 ? "" : "s"}<br/>
                    <i>Work time remaining until completion in SIT:</i> ${hTimeRemaining} hr${
    hTimeRemaining === 1 ? "" : "s"
  }, ${mTimeRemaining} min${mTimeRemaining === 1 ? "" : "s"}<br/>
                    <i>Current deviation from schedule in SIT:</i> ${hDeviation} hr${
    hDeviation === 1 ? "" : "s"
  }, ${Math.abs(mDeviation)} min${Math.abs(mDeviation) === 1 ? "" : "s"}<br/>`;
  return tooltipFragment;
}


export function setHtmlTooltipLong(
  systemNumber,
  checklistStatus,
  assignedEmployee,
  currentWP,
  originallyExpectedWorkHours,
  minutesSpentToday,
  minutesRemainingInCurrentWP,
  workHoursRemainingAfterCurrentWP,
  deviationFromScheduleInWorkHours,
  lastPossibleCompletionDate,
  customerExpectanceDate,
  employeeMapping,
  makeSgrTooltipWithAddedSitString,
  additionalSitString,
  note,
) {
  const lastPossibleCompletionDateString =
    lastPossibleCompletionDate.toString("MMMM dd, yyyy");
  const customerExpectanceDateString =
    customerExpectanceDate.toString("MMMM dd, yyyy");

  // calculate and format originally estimated time
  const originallyExpectedWorkMinutes = Math.round(
    originallyExpectedWorkHours * 60
  );
  const mOriginallyEstimatedDuration = Math.round(
    originallyExpectedWorkMinutes % 60
  );
  const hOriginallyEstimatedDuration = Math.round(
    (originallyExpectedWorkMinutes - mOriginallyEstimatedDuration) / 60
  );

  // calculate and format time spent today
  const mTimeSpentToday = Math.round(minutesSpentToday % 60);
  const hTimeSpentToday = Math.round(
    (minutesSpentToday - mTimeSpentToday) / 60
  );

  // calculate and format remaining time
  const workMinutesRemaining =
    checklistStatus === "notstarted"
      ? originallyExpectedWorkHours * 60 // if checklist is not yet started, remaining time is identical with original time
      : minutesRemainingInCurrentWP + workHoursRemainingAfterCurrentWP * 60; // if started, add up all open time estimates
  const mTimeRemaining = Math.round(workMinutesRemaining % 60);
  const hTimeRemaining = Math.round(
    (workMinutesRemaining - mTimeRemaining) / 60
  );

  // calculate and format deviation
  // calculate and format originally estimated time
  const deviationFromScheduleInMinutes = Math.round(
    deviationFromScheduleInWorkHours * 60
  );
  const mDeviation = Math.round(deviationFromScheduleInMinutes % 60);
  const hDeviation = Math.round(
    (deviationFromScheduleInMinutes - mDeviation) / 60
  );

  // assemble tooltip html with all informations viable
  const tooltipAll = `<strong>System ${systemNumber}</strong><br/>
                    <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
                    <i>Current workpackage:</i> ${currentWP}<br/>
                    <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
                    <i>Originally expected work time:</i> ${hOriginallyEstimatedDuration} hr${
    hOriginallyEstimatedDuration === 1 ? "" : "s"
  }, ${mOriginallyEstimatedDuration} min${
    mOriginallyEstimatedDuration === 1 ? "" : "s"
  }<br/>
                    <i>Work time tracked today:</i> ${hTimeSpentToday} hr${
    hTimeSpentToday === 1 ? "" : "s"
  }, ${mTimeSpentToday} min${mTimeSpentToday === 1 ? "" : "s"}<br/>
                    <i>Work time remaining until completion:</i> ${hTimeRemaining} hr${
    hTimeRemaining === 1 ? "" : "s"
  }, ${mTimeRemaining} min${mTimeRemaining === 1 ? "" : "s"}<br/>
                    <i>Current deviation from schedule:</i> ${hDeviation} hr${
    hDeviation === 1 ? "" : "s"
  }, ${Math.abs(mDeviation)} min${Math.abs(mDeviation) === 1 ? "" : "s"}<br/>
                ${
                  makeSgrTooltipWithAddedSitString
                    ? `<hr style="margin-top:0.1em;margin-bottom:0.1em"/>${additionalSitString}`
                    : ""
                }
                      <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
                    <i>Assigned employee:</i> ${
                      employeeMapping[assignedEmployee] || "-"
                    } <br/>
                    <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
                    <i>Geplanter interner Output Termin:</i> ${lastPossibleCompletionDateString}<br/>
                    <i>OM Date:</i> ${customerExpectanceDateString}<br/>
                    <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
                    <i>Note:</i> ${note || "-"}`;
  return tooltipAll;
}

export function setHtmlTooltipShort(
  systemNumber,
  checklistStatus,
  assignedEmployee,
  currentWP,
  originallyExpectedWorkHours,
  minutesSpentToday,
  minutesRemainingInCurrentWP,
  workHoursRemainingAfterCurrentWP,
  deviationFromScheduleInWorkHours,
  lastPossibleCompletionDate,
  customerExpectanceDate,
  employeeMapping,
  makeSgrTooltipWithAddedSitString,
  additionalSitString
) {
  // configure tooltip
  const lastPossibleCompletionDateString =
    lastPossibleCompletionDate.toString("MMMM dd, yyyy");
  const customerExpectanceDateString =
    customerExpectanceDate.toString("MMMM dd, yyyy");

  // calculate and format originally estimated time
  const originallyExpectedWorkMinutes = Math.round(
    originallyExpectedWorkHours * 60
  );
  const mOriginallyEstimatedDuration = Math.round(
    originallyExpectedWorkMinutes % 60
  );
  const hOriginallyEstimatedDuration = Math.round(
    (originallyExpectedWorkMinutes - mOriginallyEstimatedDuration) / 60
  );

  // calculate and format time spent today
  const mTimeSpentToday = Math.round(minutesSpentToday % 60);
  const hTimeSpentToday = Math.round(
    (minutesSpentToday - mTimeSpentToday) / 60
  );

  // calculate and format remaining time
  const workMinutesRemaining =
    checklistStatus === "notstarted"
      ? originallyExpectedWorkHours * 60 // if checklist is not yet started, remaining time is identical with original time
      : minutesRemainingInCurrentWP + workHoursRemainingAfterCurrentWP * 60; // if started, add up all open time estimates
  const mTimeRemaining = Math.round(workMinutesRemaining % 60);
  const hTimeRemaining = Math.round(
    (workMinutesRemaining - mTimeRemaining) / 60
  );

  // calculate and format deviation
  // calculate and format originally estimated time
  const deviationFromScheduleInMinutes = Math.round(
    deviationFromScheduleInWorkHours * 60
  );
  const mDeviation = Math.round(deviationFromScheduleInMinutes % 60);
  const hDeviation = Math.round(
    (deviationFromScheduleInMinutes - mDeviation) / 60
  );

  // assemble tooltip with necessary viable informations
  const tooltip =  `<strong>System ${systemNumber}</strong><br/>
                    <hr style="margin-top:0.1em;margin-bottom:0.1em"/>
                    <i>Geplanter interner Output Termin:</i> ${lastPossibleCompletionDateString}<br/>
                    <i>OM Date:</i> ${customerExpectanceDateString}<br/>
                  `;

  return tooltip;
}


export function checkIfChecklistIsScheduled(startdate, enddate, workstationId) {
  // returns true if startdatum, enddatum and resource are set, else false
  return !!(startdate && enddate && typeof workstationId === "number");
}

export function checklistIsScheduled(checklist) {
  return checkIfChecklistIsScheduled(
    checklist.workstationStartdatum,
    checklist.enddatum,
    checklist.workstation
  );
}

export function getEventBarText(system, checklistType) {
  if (checklistType === "SGR") {
    // TODO: labelling could potentially be wrong if dive workshare keys are ever renamed
    if (Object.keys(system.srChecklistData?.workshares).includes("DIVE - 1")) {
      return "DR-" + system.systemNrLeica;
    } else {
      return "SR-" + system.systemNrLeica;
    }
  } else if (checklistType === "SIT" && system.srChecklistData) {
    return "S-" + system.systemNrLeica;
  } else if (checklistType === "Geparkt") {
    return "Geparkt-" + system.systemNrLeica;
  } else {
    return system.systemNrLeica;
  }
}

export function getEventBarStatusColor(checklist, isHold = false) {
  // NB: note that this does not consider the red color that indicates a conflict, as these are calculated dynamically
  if (isHold || checklist === "Geparkt") {
    return factoryboardColors.geparkt;
  } else if (checklist.status === "notstarted") {
    return factoryboardColors.checklistNotStarted;
  } else if (checklist.deviationFromScheduleInWorkHours > 0) {
    return factoryboardColors.checklistDelayed;
  } else {
    return factoryboardColors.checklistOnSchedule;
  }
}

export function getTimeBlockHtml(id, text, searchTerm) {
  // configure html property for event bar which will replace plain text as event bar dom element and be displayed on event bar instead
  // will look the same but we use this to add an id to the dom element, and downstream to highlight search query matches
  let styledText = text;
  if (searchTerm && styledText.includes(searchTerm)) {
    styledText = styledText.replace(searchTerm, `<b>${searchTerm}</b>`);
  }
  const html = `<div id=${id}>${styledText}</div>`;
  return html;
}

export function makeUnscheduledTimeBlock(
  system,
  checklistType,
  leicaWorkHoursPerDay
) {
  const checklist =
    checklistType === "SIT" ? system.checklistData : system.srChecklistData;
  const timeBlock = {
    ...timeBlockTemplate,
    duration: new DayPilot.Duration.ofDays(
      checklist.currentlyExpectedWorkHoursInWorkstation / leicaWorkHoursPerDay
    ),
    id: checklist.checklistId + "_0", // if checklist unscheduled, there is only one time block (latter part of id)
    text: getEventBarText(system, checklistType),
    barColor: getEventBarStatusColor(checklist),
    usualBarColor: getEventBarStatusColor(checklist),
    cssClass: checklistType === "SIT" ? getSITBarCssClass(system) : undefined,
    usualCssClass:
      checklistType === "SIT" ? getSITBarCssClass(system) : undefined,
    htmlTooltipShort: checklist.htmlTooltipShort,
    htmlTooltipLong: checklist.htmlTooltipLong,
    bubbleHtml: checklist.htmlTooltipShort,
    indexWithinChecklist: 0,
    isScheduled: false,
    checklistIsStarted: false,
    correspondingSystemId: system.systemId,
    correspondingChecklistId: checklist.checklistId,
    checklistType: checklistType,
    behaviourOverAbsences: "Expand",
    lastPossibleCompletionDate: system.lastPossibleCompletionDate,
    assignedEmployee: checklist.assignedEmployee,
    systemStatus: system.status,
    hide: false,
    permitSchedulingOverlap: checklist.permitSchedulingOverlap,
    note: checklist.note, // Ensure the note is passed here
  };
  // add html to timeblock which is displayed instead of plain text
  // will look the same but add id to dom element
  timeBlock.html = getTimeBlockHtml(timeBlock.id, timeBlock.text);
  return timeBlock;
}

export function makeGeparktTimeBlock(
  system,
  endOfSITChecklist,
  startOfSGRChecklist,
  geparktResourceId
) {
  const checklist = "Geparkt"; // this does not belong to either of the checklists
  const timeBlock = {
    ...timeBlockTemplate,
    start: new DayPilot.Date(endOfSITChecklist).addDays(1),
    end: new DayPilot.Date(startOfSGRChecklist).addDays(-1),
    id: system.checklistData.checklistId + "_Geparkt", // id of sit checklist because must be unique
    resource: geparktResourceId,
    text: getEventBarText(system, checklist),
    moveDisabled: true, // may not move of its own
    barColor: getEventBarStatusColor(checklist),
    usualBarColor: getEventBarStatusColor(checklist),
    indexWithinChecklist: "Geparkt",
    isScheduled: true,
    checklistIsStarted: undefined,
    correspondingSystemId: system.systemId,
    correspondingChecklistId: "Geparkt",
    checklistType: "Geparkt",
    behaviourOverAbsences: "None", // may not scale of its own
    lastPossibleCompletionDate: system.lastPossibleCompletionDate,
    systemStatus: system.status,
    hide: system.checklistData.status === "finished" ? true : false, // disappears with completion of sit checklist
  };
  // add html to timeblock which is displayed instead of plain text
  // will look the same but add id to dom element
  timeBlock.html = getTimeBlockHtml(timeBlock.id, timeBlock.text);
  return timeBlock;
}

export function getSITBarCssClass(system) {
  if (system.srChecklistData) {
    return "sitSystemWithLinkedSondernummer";
  }
  return undefined;
}

export function getEnddateByStartDateAndWorkingDays(
  checklist,
  workingDays,
  resourceAbsenceDays
) {
  let currentDate = new DayPilot.Date(checklist.workstationStartdatum);
  while (workingDays > 0) {
    // check if the current date is a hold/absence/weekend
    const isWeekend = [0, 6].includes(currentDate.getDayOfWeek());

    let holdDates = undefined;

    if (checklist.holdTime) {
      holdDates =
        checklist.holdTime.dates ||
        getDatesInTimeRange(
          new DayPilot.Date(checklist.holdTime.start),
          new DayPilot.Date(checklist.holdTime.end)
        );
    }

    const isHold = holdDates && holdDates.includes(currentDate);

    let currentResource = undefined;
    if (isHold) {
      currentResource = checklist.holdTime.resourceDuringHold;
    } else if (checklist.holdTime && currentDate > checklist.holdTime.end) {
      currentResource = checklist.holdTime.resourceAfterHold;
    } else {
      currentResource = checklist.workstation;
    }
    const isAbsence =
      resourceAbsenceDays[currentResource]?.includes(currentDate);
    if (!isWeekend && !isHold && !isAbsence) workingDays--;
    if (workingDays > 0) {
      // enddate is inclusive, so don't increment the current date anymore if the last working day is reached
      currentDate = currentDate.addDays(1);
    }
  }
  return currentDate;
}

export function getCurrentlyExpectedChecklistEnddate(
  checklist,
  leicaWorkHoursPerDay,
  resourceAbsenceDays
) {
  // find the currently expected end date of the checklist/last timeblock
  let currentlyExpectedChecklistEnddate = undefined;
  if (checklist.status === "finished") {
    // if the checklist is not live in production, we can simply use the originally expected enddate
    currentlyExpectedChecklistEnddate = new DayPilot.Date(checklist.enddatum);
  } else {
    // get the number of work days we need to count up from the workstation start date to get the end date
    let currentlyExpectedWorkDays = Math.ceil(
      checklist.currentlyExpectedWorkHoursInWorkstation / leicaWorkHoursPerDay
    );
    // find the currently expected end date of the checklist/last time block
    // walk day by day from startdate, counting down remaining days if they are not a weekend, hold or absence
    currentlyExpectedChecklistEnddate = getEnddateByStartDateAndWorkingDays(
      checklist,
      currentlyExpectedWorkDays,
      resourceAbsenceDays
    );
  }
  return currentlyExpectedChecklistEnddate;
}

export function makeScheduledTimeBlocks(
  system,
  checklistType,
  leicaWorkHoursPerDay,
  resourceAbsenceDays
) {
  // returns array of either 1 or 3 time blocks depending on whether the checklist has a hold
  const checklist =
    checklistType === "SIT" ? system.checklistData : system.srChecklistData;
  const timeBlocks = [];

  // following settings always apply for initial scheduled time block
  // some others depend on holds and are evaluated below
  const initialTimeBlock = {
    ...timeBlockTemplate,
    start: checklist.workstationStartdatum,
    id: checklist.checklistId + "_0", // this is the first of 1 or 3 time blocks, so idx is always 0
    resource: checklist.workstation,
    text: getEventBarText(system, checklistType),
    barColor: getEventBarStatusColor(checklist),
    usualBarColor: getEventBarStatusColor(checklist),
    cssClass: checklistType === "SIT" ? getSITBarCssClass(system) : undefined,
    usualCssClass:
      checklistType === "SIT" ? getSITBarCssClass(system) : undefined,
    htmlTooltipShort: checklist.htmlTooltipShort,
    htmlTooltipLong: checklist.htmlTooltipLong,
    bubbleHtml: checklist.htmlTooltipLong,
    correspondingSystemId: system.systemId,
    correspondingChecklistId: checklist.checklistId,
    checklistType: checklistType,
    indexWithinChecklist: 0,
    isScheduled: true,
    checklistIsStarted: checklist.status === "notstarted" ? false : true,
    lastPossibleCompletionDate: system.lastPossibleCompletionDate,
    assignedEmployee: checklist.assignedEmployee,
    systemStatus: system.status,
    hide: checklist.status === "finished" ? true : false,
    note: checklist.note, // Ensure the note is passed here
  };
  // add html to timeblock which is displayed instead of plain text
  // will look the same but add id to dom element
  initialTimeBlock.html = getTimeBlockHtml(
    initialTimeBlock.id,
    initialTimeBlock.text
  );
  // find the currently expected end date of the checklist/last timeblock
  const currentlyExpectedChecklistEnddate =
    getCurrentlyExpectedChecklistEnddate(
      checklist,
      leicaWorkHoursPerDay,
      resourceAbsenceDays
    );

  //check if the checklist has a hold
  if (checklist.holdTime) {
    // update initial time block with data that depents on hold existence
    Object.assign(initialTimeBlock, {
      end: new DayPilot.Date(checklist.holdTime.start).addDays(-1), // initial block ends one day before hold
      moveDisabled: true, // moving checklist events that have a hold is forbidden
      behaviourOverAbsences: "None", // only the last time block is to respond to absences
      permitSchedulingOverlap: false, // only true for last block of checklist
    });

    // create time block for hold
    const holdTimeBlock = {
      ...timeBlockTemplate,
      start: checklist.holdTime.start,
      end: checklist.holdTime.end,
      id: checklist.checklistId + "_1", // hold idx is always 1 (=middle of 3 blocks indexed 0-2)
      resource: checklist.holdTime.resourceDuringHold,
      text: getEventBarText(system, checklistType),
      moveDisabled: true,
      barColor: getEventBarStatusColor(checklist, true),
      usualBarColor: getEventBarStatusColor(checklist),
      cssClass: checklistType === "SIT" ? getSITBarCssClass(system) : undefined,
      usualCssClass:
        checklistType === "SIT" ? getSITBarCssClass(system) : undefined,
      htmlTooltipShort: checklist.htmlTooltipShort,
      htmlTooltipLong: checklist.htmlTooltipLong,
      bubbleHtml: checklist.htmlTooltipLong,
      correspondingSystemId: system.systemId,
      correspondingChecklistId: checklist.checklistId,
      checklistType: checklistType,
      indexWithinChecklist: 1,
      isScheduled: true,
      checklistIsStarted: checklist.status === "notstarted" ? false : true,
      behaviourOverAbsences: "None",
      lastPossibleCompletionDate: system.lastPossibleCompletionDate,
      idOfThisHold: checklist.holdTime.id,
      assignedEmployee: checklist.assignedEmployee,
      systemStatus: system.status,
      hide: checklist.status === "finished" ? true : false,
      permitSchedulingOverlap: false, // only true for last block of checklist
      note: checklist.note, // Ensure the note is passed here
    };
    // add html to timeblock which is displayed instead of plain text
    // will look the same but add id to dom element
    holdTimeBlock.html = getTimeBlockHtml(holdTimeBlock.id, holdTimeBlock.text);

    // create time block for after hold
    const afterHoldTimeBlock = {
      ...timeBlockTemplate,
      start: new DayPilot.Date(checklist.holdTime.end).addDays(1),
      end: currentlyExpectedChecklistEnddate,
      id: checklist.checklistId + "_2", // after hold idx is always 2 (= last of 3 blocks indexed 0-2)
      resource: checklist.holdTime.resourceAfterHold,
      text: getEventBarText(system, checklistType),
      moveDisabled: true,
      barColor: getEventBarStatusColor(checklist),
      usualBarColor: getEventBarStatusColor(checklist),
      cssClass: checklistType === "SIT" ? getSITBarCssClass(system) : undefined,
      usualCssClass:
        checklistType === "SIT" ? getSITBarCssClass(system) : undefined,
      htmlTooltipShort: checklist.htmlTooltipShort,
      htmlTooltipLong: checklist.htmlTooltipLong,
      bubbleHtml: checklist.htmlTooltipLong,
      correspondingSystemId: system.systemId,
      correspondingChecklistId: checklist.checklistId,
      checklistType: checklistType,
      indexWithinChecklist: 2,
      isScheduled: true,
      checklistIsStarted: checklist.status === "notstarted" ? false : true,
      behaviourOverAbsences: "Expand",
      lastPossibleCompletionDate: system.lastPossibleCompletionDate,
      assignedEmployee: checklist.assignedEmployee,
      systemStatus: system.status,
      hide: checklist.status === "finished" ? true : false,
      permitSchedulingOverlap: checklist.permitSchedulingOverlap,
      note: checklist.note, // Ensure the note is passed here
    };
    // add html to timeblock which is displayed instead of plain text
    // will look the same but add id to dom element
    afterHoldTimeBlock.html = getTimeBlockHtml(
      afterHoldTimeBlock.id,
      afterHoldTimeBlock.text
    );

    timeBlocks.push(initialTimeBlock, holdTimeBlock, afterHoldTimeBlock);
  } else {
    // if no hold exists, add remaining properties to initial (and only) time bock accordingly
    Object.assign(initialTimeBlock, {
      end: currentlyExpectedChecklistEnddate, // expected end date of checklist
      behaviourOverAbsences: "Expand", // only the last time block is to respond to absences
      permitSchedulingOverlap: checklist.permitSchedulingOverlap, // only true for last block of checklist
    });
    if (checklist.status !== "notstarted") {
      Object.assign(initialTimeBlock, {
        moveHDisabled: true, // checklists that are started may not be moved horizontally
      });
    }
    timeBlocks.push(initialTimeBlock);
  }

  return timeBlocks;
}

export function generateProductionTimeBlocks(
  system,
  leicaWorkHoursPerDay,
  resourceAbsenceDays,
  geparktResourceId
) {
  // create the individual blocks that make up the system production schedule
  // these will show as event bars on the factoryboard
  const timeBlocks = [];

  // calculate time blocks for SIT checklist
  // check if the checklist is scheduled
  if (!checklistIsScheduled(system.checklistData)) {
    // catch errors with checklist status/scheduling mismatch
    if (system.checklistData.status !== "notstarted") {
      throw new Error(
        "Found an SIT checklist that is started or finished but not scheduled: " +
          system.systemNrLeica
      );
    }
    // if unscheduled, leave start/end/and resources empty and set duration instead
    timeBlocks.push(
      makeUnscheduledTimeBlock(system, "SIT", leicaWorkHoursPerDay)
    );
  } else {
    // if checklist is scheduled, consider possible holds and current checklist status
    const checklistTimeBlocks = makeScheduledTimeBlocks(
      system,
      "SIT",
      leicaWorkHoursPerDay,
      resourceAbsenceDays
    );
    timeBlocks.push(...checklistTimeBlocks);
  }

  // if system has an SGR part, calculate time blocks related to that
  if (system.srChecklistData) {
    // check if the checklist is scheduled
    if (!checklistIsScheduled(system.srChecklistData)) {
      // catch errors with checklist status/scheduling mismatch
      if (system.srChecklistData.status !== "notstarted") {
        throw new Error(
          "Found an SGR checklist that is started or finished but not scheduled: " +
            system.systemNrLeica
        );
      }
      timeBlocks.push(
        makeUnscheduledTimeBlock(system, "SGR", leicaWorkHoursPerDay)
      );
    } else {
      const sgrChecklistTimeBlocks = makeScheduledTimeBlocks(
        system,
        "SGR",
        leicaWorkHoursPerDay,
        resourceAbsenceDays
      );

      // if the SGR checklist is scheduled, there might be need for a Geparkt bar
      // check if SIT checklist is also scheduled
      if (checklistIsScheduled(system.checklistData)) {
        // check if there is empty time between SIT and SGR checklist by comparing end date of last SIT time block and start date of first SGR time block
        const sitChecklistEnd = timeBlocks[timeBlocks.length - 1].end;
        const sgrChecklistStart = sgrChecklistTimeBlocks[0].start;
        if (sitChecklistEnd.addDays(1) < sgrChecklistStart) {
          const geparktTimeBlock = makeGeparktTimeBlock(
            system,
            sitChecklistEnd,
            sgrChecklistStart,
            geparktResourceId
          );
          timeBlocks.push(geparktTimeBlock);
        }
      }
      timeBlocks.push(...sgrChecklistTimeBlocks);
    }
  }
  return timeBlocks;
}

function calculateCurrentlyExpectedPercentageOfLastDayBlocked(
  checklist,
  leicaWorkHoursPerDay
) {
  // this function assumes that all the remaining expected workstation time happening from *now* (i.e., beginning of
  // today minus time already spent today) till the end of production will spread over days of length leicaWorkHoursPerDay,
  // and returns the percentage of the last work day that would prospectively be blocked by this checklist
  // NB: note that (1) this only takes time on actual workstation into account (i.e., from sit_start_date, not start_date),
  // (2) this assumes workstation production always starts at the beginning of the start day, and (3) that we 
  // can't reliably calculate a prediction for percentage blocked on last day if the checklist finished before today 
  // (those will always return 0 percent of last day blocked)

  // convert all necessary values to the same unit of measurement (i.e., hours)
  const workHoursSpentTodayAtWorkstation = checklist.minutesSpentTodayAtWorkstation / 60;
  const workHoursRemainingInCurrentWPAtWorkstation =
    checklist.minutesRemainingInCurrentWPAtWorkstation / 60;
  // determine expected time remaining until checklist completion
  const workHoursRemainingToDoAtWorkstation =
    checklist.status === "notstarted"
      ? checklist.originallyExpectedWorkHoursAtWorkstation // if checklist is not yet started, remaining time is identical with original time
      : workHoursRemainingInCurrentWPAtWorkstation +
        checklist.workHoursRemainingAfterCurrentWPAtWorkstation; // if started, add up all open time estimates
  // get work hours remaining today, considering time already spent and total leicaWorkHoursPerDay
  const availableWorkHoursRemainingTodayAtWorkstation =
    leicaWorkHoursPerDay - workHoursSpentTodayAtWorkstation;
  // check if the time remaining is more than the rest of today
  if (workHoursRemainingToDoAtWorkstation > availableWorkHoursRemainingTodayAtWorkstation) {
    // if yes, determine the remaining work hours from tomorrow, then convert to number of standard leica work days
    const workDaysRemainingFromTomorrow =
      (workHoursRemainingToDoAtWorkstation - availableWorkHoursRemainingTodayAtWorkstation) /
      leicaWorkHoursPerDay;
    // decimal places of nr of remaining work days represent portion of last day that will be blocked by work
    const percentageOfLastDayBlocked = workDaysRemainingFromTomorrow % 1;
    return percentageOfLastDayBlocked;
  } else {
    // if the expected time remaining is less than the rest of the day, calculate how much of the current day
    // will prospectively be blocked by the current checklist in total
    const percentageOfCurrentDayBlocked =
      (workHoursSpentTodayAtWorkstation + workHoursRemainingToDoAtWorkstation) / leicaWorkHoursPerDay;
    // NB: for checklists that have finished before today, this will look like (0 + 0)/leicaWorkHoursPerDay = 0 percent blocked
    return percentageOfCurrentDayBlocked;
  }
}

export function formatChecklistForFactoryboard(
  system,
  checklistType,
  employeeMapping,
  leicaWorkHoursPerDay,
  overlapPermittedMaxBlockedPercent
) {
  // checklistType can be "SIT" or "SGR"

  const checklist =
    checklistType === "SIT" ? system.checklistData : system.srChecklistData;
  // make sure the input object is actually a checklist and not undefined
  if (!checklist) {
    return undefined;
  }

  // determine how much time will prospectively remain on the last day of production
  // this is important to check if back-to-back scheduling of this checklist with another should be permitted
  const percentageOfLastDayBlocked =
    calculateCurrentlyExpectedPercentageOfLastDayBlocked(
      checklist,
      leicaWorkHoursPerDay
    );
  // determine if back-to-back (overlapping) scheduling should be allowed for this checklist
  // this is true if the percentage of the last day blocked by the current checklist <= overlap permission threshold
  const permitSchedulingOverlap =
    percentageOfLastDayBlocked <= overlapPermittedMaxBlockedPercent;

  // check which work package to display in tooltip depending on current status of checklist
  const currentWorkPackageGlobal =
    system.checklistData.status === "finished" && system.srChecklistData
      ? system.srChecklistData.currentWorkPackage
      : system.checklistData.currentWorkPackage;

  // generate tooltip fragment for SIT data to be added to SGR tooltip
  const sitTooltipFragment = getHtmlTooltipSitFragment(
    system.checklistData.status,
    system.checklistData.originallyExpectedWorkHoursAtWorkstation,
    system.checklistData.minutesSpentTodayAtWorkstation,
    system.checklistData.minutesRemainingInCurrentWPAtWorkstation,
    system.checklistData.workHoursRemainingAfterCurrentWPAtWorkstation,
    system.checklistData.deviationFromScheduleInWorkHours
  );

  // generate tooltip for event hover
  const htmlTooltipShort = setHtmlTooltipShort(
    system.systemNrLeica,
    checklist.status,
    checklist.assignedEmployee,
    currentWorkPackageGlobal,
    checklist.originallyExpectedWorkHoursAtWorkstation,
    checklist.minutesSpentTodayAtWorkstation,
    checklist.minutesRemainingInCurrentWPAtWorkstation,
    checklist.workHoursRemainingAfterCurrentWPAtWorkstation,
    checklist.deviationFromScheduleInWorkHours,
    system.lastPossibleCompletionDate,
    system.customerExpectanceDate,
    employeeMapping,
    checklistType === "SGR", // makeSgrTooltipWithAddedSitString
    sitTooltipFragment, // string to be inserted for additional sit data on sgr tooltip
    checklist.note,
  );

  // generate tooltip for event hover
  const htmlTooltipLong = setHtmlTooltipLong(
    system.systemNrLeica,
    checklist.status,
    checklist.assignedEmployee,
    currentWorkPackageGlobal,
    checklist.originallyExpectedWorkHoursAtWorkstation,
    checklist.minutesSpentTodayAtWorkstation,
    checklist.minutesRemainingInCurrentWPAtWorkstation,
    checklist.workHoursRemainingAfterCurrentWPAtWorkstation,
    checklist.deviationFromScheduleInWorkHours,
    system.lastPossibleCompletionDate,
    system.customerExpectanceDate,
    employeeMapping,
    checklistType === "SGR", // makeSgrTooltipWithAddedSitString
    sitTooltipFragment, // string to be inserted for additional sit data on sgr tooltip
    checklist.note
  );

  // convert days to daypilot date
  // remove data that will be saved with the production time blocks directly
  // to avoid duplicates/no single source of truth in local handling
  const formattedChecklist = {
    ...checklist,
    checklistStartdatum: checklist.checklistStartdatum
      ? new DayPilot.Date(checklist.checklistStartdatum)
      : undefined,
    workstationStartdatum: checklist.workstationStartdatum
      ? new DayPilot.Date(checklist.workstationStartdatum)
      : undefined,
    enddatum: checklist.enddatum
      ? new DayPilot.Date(checklist.enddatum)
      : undefined,
    permitSchedulingOverlap: permitSchedulingOverlap,
    htmlTooltipShort: htmlTooltipShort,
    htmlTooltipLong: htmlTooltipLong,
    holdTime: checklist.holdTime
      ? {
          ...checklist.holdTime,
          start: new DayPilot.Date(checklist.holdTime.start),
          end: new DayPilot.Date(checklist.holdTime.end),
          dates: getDatesInTimeRange(
            new DayPilot.Date(checklist.holdTime.start),
            new DayPilot.Date(checklist.holdTime.end)
          ),
        }
      : undefined,
    // TO DO: add assigned employee and bar color
  };
  return formattedChecklist;
}

// ====================================================================================================
// TOP LEVEL DATA FORMATTING
// ====================================================================================================

export function formatSystemDataForFactoryboard(
  systemData,
  leicaWorkHoursPerDay,
  overlapPermittedMaxBlockedPercent,
  resourceAbsenceDays,
  geparktResourceId,
  employeeMapping
) {
  // brings the system objects into the format expected by the factoryboard, i.e., a list of system objects with nested checklists
  // and production time blocks and their respective metadata -- those represent the events displayed on factoryboard

  const factoryBoardSystemData = {
    systems: [],
    productionTimeBlocks: { scheduled: [], unscheduled: [] },
  };

  systemData.forEach((system) => {
    const formattedSystem = {
      ...system,
      lastPossibleCompletionDate: new DayPilot.Date(
        system.lastPossibleCompletionDate
      ),
      customerExpectanceDate: new DayPilot.Date(system.customerExpectanceDate),
      checklistData: formatChecklistForFactoryboard(
        system,
        "SIT",
        employeeMapping,
        leicaWorkHoursPerDay,
        overlapPermittedMaxBlockedPercent
      ),
      srChecklistData: system.srChecklistData
        ? formatChecklistForFactoryboard(
            system,
            "SGR",
            employeeMapping,
            leicaWorkHoursPerDay,
            overlapPermittedMaxBlockedPercent
          )
        : undefined,
    };
    factoryBoardSystemData.systems.push(formattedSystem);
    const productionTimeBlocks = generateProductionTimeBlocks(
      formattedSystem,
      leicaWorkHoursPerDay,
      resourceAbsenceDays,
      geparktResourceId
    );

    factoryBoardSystemData.productionTimeBlocks.scheduled.push(
      ...productionTimeBlocks.filter((block) => block.isScheduled)
    );
    factoryBoardSystemData.productionTimeBlocks.unscheduled.push(
      ...productionTimeBlocks.filter((block) => !block.isScheduled)
    );
  });

  return factoryBoardSystemData;
}

// ====================================================================================================
// TOP LEVEL RESOURCE FORMATTING
// ====================================================================================================

export function formatResourceDataForFactoryboard(
  resources,
  resourceAbsenceDays
) {
  // organize resources by type and replace absence date objects with list of exact dates for easier access
  const standardWorkstations = resources
    .filter((resource) => resource.type === "SIT")
    .map((resource, idx) => ({
      ...resource,
      absenceTimes: resourceAbsenceDays[resource.id].flat(),
      idxWithinCategory: idx,
    }));
  const sonderraumWorkstations = resources
    .filter((resource) => resource.type === "SGR")
    .map((resource, idx) => ({
      ...resource,
      absenceTimes: resourceAbsenceDays[resource.id].flat(),
      idxWithinCategory: idx,
    }));
  const geparktWorkstations = resources
    .filter((resource) => resource.type === "GEPARKT")
    .map((resource, idx) => ({
      ...resource,
      absenceTimes: resourceAbsenceDays[resource.id].flat(),
      idxWithinCategory: idx,
    }));

  // bring into the right format for factoryboard display
  const factoryboardResources = [
    {
      name: "Standard Workstations",
      id: "standardWorkstations",
      expanded: true,
      parent: true,
      children: standardWorkstations,
    },
    {
      name: "Sonderraum Workstations",
      id: "sonderraumWorkstations",
      expanded: true,
      parent: true,
      children: sonderraumWorkstations,
    },
    {
      name: "Geparkt",
      id: "geparktWorkstations",
      expanded: true,
      parent: true,
      children: geparktWorkstations,
    },
  ];

  return factoryboardResources;
}
