const IssueTemplate = {
  instanceId: "",
  templateId: "c0ab1c843ca9473ead4554e246779060",
  title: "Störzeiten",
  imageUrl: "http://159.69.37.15:5010/db/static/assets/uploads/placeholder.jpg",
  identifier: "Seriennummer",
  text: "Arbeitspaket",
  properties: [
    {
      propertyName: "Datum",
      dataType: "DATE",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Materialnummer",
      dataType: "TEXT",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Seriennummer",
      dataType: "TEXT",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Arbeitspaket",
      dataType: "TEXT",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Step",
      dataType: "SINGLE_32dbcd64676a4725a317c71faa7f554e",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Fehlerbeschreibung",
      dataType: "TEXT",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Fehlerbehebung",
      dataType: "TEXT",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Bereich",
      dataType: "TEXT",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Kategorie",
      dataType: "TEXT",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Zeitverlust",
      dataType: "INTEGER",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Checklist",
      dataType: "SINGLE_021a22690f044896b9243e62a5c2e15a",
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
    {
      propertyName: "Bilder",
      dataType: "TEXT", // TO DO: verify data type here!
      propertyId: "c0ab1c843ca9473ead4554e246779060",
      propertyValue: "",
      formula: "",
      displayName: "",
      accessMode: "automaticw",
      index: "",
    },
  ],
};

export default IssueTemplate;
