const sonderraumChecklistTemplate = {
    instanceId: "",
    templateId: "bd27be8bf07e498cbecd9943a5c72ab5",
    title: "SonderraumChecklist",
    imageUrl: "http://159.69.37.15:5010/db/static/assets/uploads/placeholder.jpg",
    identifier: "AssignedResource",
    text: "AssignedResource",
    properties: [
      {
        propertyName: "Startdatum",
        dataType: "DATE",
        propertyId: "bd27be8bf07e498cbecd9943a5c72ab5",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "Steps",
        dataType: "LIST_OF_32dbcd64676a4725a317c71faa7f554e",
        propertyId: "bd27be8bf07e498cbecd9943a5c72ab5",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "HoldTimes",
        dataType: "LIST_OF_300c7bda0d7e42bfa59adcdc780aa6b8",
        propertyId: "bd27be8bf07e498cbecd9943a5c72ab5",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "Stoerzeiten",
        dataType: "LIST_OF_c0ab1c843ca9473ead4554e246779060",
        propertyId: "bd27be8bf07e498cbecd9943a5c72ab5",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "LastCompletedWorkpackage",
        dataType: "INTEGER",
        propertyId: "bd27be8bf07e498cbecd9943a5c72ab5",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "Workshares",
        dataType: "TEXT",
        propertyId: "bd27be8bf07e498cbecd9943a5c72ab5",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "AssignedResource",
        dataType: "SINGLE_1c10a7e19ce14d1fbaf3ae93ff0cec56",
        propertyId: "bd27be8bf07e498cbecd9943a5c72ab5",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "Enddatum",
        dataType: "DATE",
        propertyId: "bd27be8bf07e498cbecd9943a5c72ab5",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "AssignedEmployee",
        dataType: "SINGLE_7af105c5b5644688941283db3e54b117",
        propertyId: "bd27be8bf07e498cbecd9943a5c72ab5",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
      {
        propertyName: "Duration",
        dataType: "INTEGER",
        propertyId: "bd27be8bf07e498cbecd9943a5c72ab5",
        propertyValue: "",
        formula: "",
        displayName: "",
        accessMode: "automaticw",
        index: "",
      },
    ],
  };
  
  export default sonderraumChecklistTemplate;
  