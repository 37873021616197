import {
  LEICA_GET_ADMINDASHBOARD_SETTINGS,
  LEICA_GET_ADMINDASHBOARD_SETTINGS_SUCCESS,
  LEICA_GET_ADMINDASHBOARD_SETTINGS_ERROR,
  LEICA_SET_ADMINDASHBOARD_SETTINGS,
  LEICA_SET_ADMINDASHBOARD_SETTINGS_SUCCESS,
  LEICA_SET_ADMINDASHBOARD_SETTINGS_ERROR,
  LEICA_GET_PARTTEMPLATES,
  LEICA_GET_PARTTEMPLATES_ERROR,
  LEICA_GET_PARTTEMPLATES_SUCCESS,
  LEICA_CREATE_OR_UPDATE_PARTTEMPLATES,
  LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_ERROR,
  LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_SUCCESS,
  LEICA_DELETE_PARTTEMPLATES,
  LEICA_DELETE_PARTTEMPLATES_ERROR,
  LEICA_DELETE_PARTTEMPLATES_SUCCESS,
  LEICA_GET_SYSTEMS_FOR_SYSTEM_DB,
  LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_SUCCESS,
  LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_ERROR,
  LEICA_GET_RESOURCES,
  LEICA_GET_RESOURCES_SUCCESS,
  LEICA_GET_RESOURCES_ERROR,
  LEICA_GET_EMPLOYEES,
  LEICA_GET_EMPLOYEES_ERROR,
  LEICA_GET_EMPLOYEES_SUCCESS,
  LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA,
  LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_SUCCESS,
  LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_ERROR,
  LEICA_GET_FACTORYBOARD_DATA,
  LEICA_GET_FACTORYBOARD_DATA_ERROR,
  LEICA_GET_FACTORYBOARD_DATA_SUCCESS,
  LEICA_GET_DEPARTUREBOARD_DATA,
  LEICA_GET_DEPARTUREBOARD_DATA_ERROR,
  LEICA_GET_DEPARTUREBOARD_DATA_SUCCESS,
  LEICA_GET_SYSTEMLOADOUT,
  LEICA_GET_SYSTEMLOADOUT_SUCCESS,
  LEICA_GET_SYSTEMLOADOUT_ERROR,
  LEICA_GET_OVERVIEW,
  LEICA_GET_OVERVIEW_SUCCESS,
  LEICA_GET_OVERVIEW_ERROR,
  LEICA_AUTOSCHEDULE_NEW_SYSTEM,
  LEICA_AUTOSCHEDULE_NEW_SYSTEM_SUCCESS,
  LEICA_AUTOSCHEDULE_NEW_SYSTEM_ERROR,
  LEICA_RESCHEDULE_SYSTEMS,
  LEICA_RESCHEDULE_SYSTEMS_SUCCESS,
  LEICA_RESCHEDULE_SYSTEMS_ERROR,
  LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD,
  LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_SUCCESS,
  LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_ERROR,
  LEICA_SET_STEPCOMPLETE,
  LEICA_SET_STEPCOMPLETE_SUCCESS,
  LEICA_SET_STEPCOMPLETE_ERROR,
  LEICA_TOGGLE_WORKSTATUS,
  LEICA_TOGGLE_WORKSTATUS_SUCCESS,
  LEICA_TOGGLE_WORKSTATUS_ERROR,
  LEICA_GET_ISSUES_BY_ID,
  LEICA_GET_ISSUES_BY_ID_SUCCESS,
  LEICA_GET_ISSUES_BY_ID_ERROR,
  LEICA_GET_CHECKLIST_BY_STEPIDS,
  LEICA_GET_CHECKLIST_BY_STEPIDS_SUCCESS,
  LEICA_GET_CHECKLIST_BY_STEPIDS_ERROR,
  LEICA_GET_HOLD_TIMES,
  LEICA_GET_HOLD_TIMES_ERROR,
  LEICA_GET_HOLD_TIMES_SUCCESS,
  LEICA_PUT_SONDERNUMMERN,
  LEICA_PUT_SONDERNUMMERN_SUCCESS,
  LEICA_PUT_SONDERNUMMERN_ERROR,
  LEICA_PUT_ISSUE,
  LEICA_PUT_ISSUE_SUCCESS,
  LEICA_PUT_ISSUE_ERROR,
  LEICA_PUT_RESOURCE_ABSENCE_TIMES,
  LEICA_PUT_RESOURCE_ABSENCE_TIMES_SUCCESS,
  LEICA_PUT_RESOURCE_ABSENCE_TIMES_ERROR,
  // LEICA_PUT_HOLD_TIME,
  // LEICA_PUT_HOLD_TIME_ERROR,
  // LEICA_PUT_HOLD_TIME_SUCCESS,
  // LEICA_DELETE_HOLD_TIME,
  // LEICA_DELETE_HOLD_TIME_SUCCESS,
  // LEICA_DELETE_HOLD_TIME_ERROR,
  LEICA_DELETE_SYSTEM,
  LEICA_DELETE_SYSTEM_SUCCESS,
  LEICA_DELETE_SYSTEM_ERROR,
  LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS,
  LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_SUCCESS,
  LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_ERROR,
  LEICA_SUBMIT_NEW_SYSTEM,
  LEICA_SUBMIT_NEW_SYSTEM_SUCCESS,
  LEICA_SUBMIT_NEW_SYSTEM_ERROR,
  LEICA_UPDATE_SYSTEM_CONFIG,
  LEICA_UPDATE_SYSTEM_CONFIG_SUCCESS,
  LEICA_UPDATE_SYSTEM_CONFIG_ERROR,
  LEICA_GET_DOWNTIMEKPI,
  LEICA_GET_DOWNTIMEKPI_SUCCESS,
  LEICA_GET_DOWNTIMEKPI_ERROR,
  LEICA_UPDATE_CHECKLIST,
  LEICA_UPDATE_CHECKLIST_ERROR,
  LEICA_UPDATE_CHECKLIST_SUCCESS,
  LEICA_UPDATE_SONDERRAUM_CHECKLIST,
  LEICA_UPDATE_SONDERRAUM_CHECKLIST_ERROR,
  LEICA_UPDATE_SONDERRAUM_CHECKLIST_SUCCESS,
  LEICA_UPDATE_HOLDTIME,
  LEICA_UPDATE_HOLDTIME_ERROR,
  LEICA_UPDATE_HOLDTIME_SUCCESS,
  LEICA_CREATE_HOLDTIME,
  LEICA_CREATE_HOLDTIME_ERROR,
  LEICA_CREATE_HOLDTIME_SUCCESS,
  LEICA_DELETE_HOLDTIME,
  LEICA_DELETE_HOLDTIME_ERROR,
  LEICA_DELETE_HOLDTIME_SUCCESS
} from "../actions";

export const getAdminDashboardSettings = () => ({
  type: LEICA_GET_ADMINDASHBOARD_SETTINGS,
});

export const getAdminDashboardSettingsSuccess = (settings) => {
  return {
    type: LEICA_GET_ADMINDASHBOARD_SETTINGS_SUCCESS,
    payload: settings,
  };
};

export const getAdminDashboardSettingsError = (error) => ({
  type: LEICA_GET_ADMINDASHBOARD_SETTINGS_ERROR,
  payload: error,
});

export const setAdminDashboardSettings = (settingsToUpdate) => ({
  type: LEICA_SET_ADMINDASHBOARD_SETTINGS,
  payload: settingsToUpdate,
});

export const setAdminDashboardSettingsSuccess = (settingsToUpdate) => {
  return {
    type: LEICA_SET_ADMINDASHBOARD_SETTINGS_SUCCESS,
    payload: settingsToUpdate,
  };
};

export const setAdminDashboardSettingsError = (error) => ({
  type: LEICA_SET_ADMINDASHBOARD_SETTINGS_ERROR,
  payload: error,
});

export const getLeicaPartTemplates = () => {
  return {
    type: LEICA_GET_PARTTEMPLATES,
  };
};

export const getLeicaPartTemplatesSuccess = (parts) => {
  return {
    type: LEICA_GET_PARTTEMPLATES_SUCCESS,
    payload: parts,
  };
};

export const getLeicaPartTemplatesError = (error) => ({
  type: LEICA_GET_PARTTEMPLATES_ERROR,
  payload: error,
});

export const createOrUpdateLeicaPartTemplates = (partsToAdd, partsToUpdate) => {
  return {
    type: LEICA_CREATE_OR_UPDATE_PARTTEMPLATES,
    payload: { partsToAdd: partsToAdd, partsToUpdate: partsToUpdate },
  };
};

export const createOrUpdateLeicaPartTemplatesSuccess = () => {
  return {
    type: LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_SUCCESS,
  };
};

export const createOrUpdateLeicaPartTemplatesError = (error) => ({
  type: LEICA_CREATE_OR_UPDATE_PARTTEMPLATES_ERROR,
  payload: error,
});

export const deleteLeicaPartTemplates = (ids) => {
  return {
    type: LEICA_DELETE_PARTTEMPLATES,
    payload: ids,
  };
};

export const deleteLeicaPartTemplatesSuccess = () => {
  return {
    type: LEICA_DELETE_PARTTEMPLATES_SUCCESS,
  };
};

export const deleteLeicaPartTemplatesError = (error) => ({
  type: LEICA_DELETE_PARTTEMPLATES_ERROR,
  payload: error,
});

export const getLeicaSystemsForSystemDB = () => {
  return {
    type: LEICA_GET_SYSTEMS_FOR_SYSTEM_DB,
  };
};

export const getLeicaSystemsForSystemDBSuccess = (systems) => {
  return {
    type: LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_SUCCESS,
    payload: systems,
  };
};

export const getLeicaSystemsForSystemDBError = (error) => ({
  type: LEICA_GET_SYSTEMS_FOR_SYSTEM_DB_ERROR,
  payload: error,
});

export const getLeicaResources = () => ({
  type: LEICA_GET_RESOURCES,
});

export const getLeicaResourcesSuccess = (resources) => ({
  type: LEICA_GET_RESOURCES_SUCCESS,
  payload: resources,
});

export const getLeicaResourcesError = (error) => ({
  type: LEICA_GET_RESOURCES_ERROR,
  payload: error,
});

export const getLeicaEmployees = () => ({
  type: LEICA_GET_EMPLOYEES,
});

export const getLeicaEmployeesSuccess = (employees) => ({
  type: LEICA_GET_EMPLOYEES_SUCCESS,
  payload: employees,
});

export const getLeicaEmployeesError = (error) => ({
  type: LEICA_GET_EMPLOYEES_ERROR,
  payload: error,
});

export const getLeicaSystemsWithChecklistData = () => ({
  type: LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA,
});

export const getLeicaSystemsWithChecklistDataSuccess = (systemData) => ({
  type: LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_SUCCESS,
  payload: systemData,
});

export const getLeicaSystemsWithChecklistDataError = (error) => ({
  type: LEICA_GET_SYSTEMS_WITH_CHECKLIST_DATA_ERROR,
  payload: error,
});

export const getLeicaFactoryboardData = () => ({
  type: LEICA_GET_FACTORYBOARD_DATA,
});

export const getLeicaFactoryboardDataSuccess = (factoryboardData) => ({
  type: LEICA_GET_FACTORYBOARD_DATA_SUCCESS,
  payload: { ...factoryboardData },
});

export const getLeicaFactoryboardDataError = (error) => ({
  type: LEICA_GET_FACTORYBOARD_DATA_ERROR,
  payload: error,
});

export const updateLeicaChecklist = (newChecklistData) => ({
  type: LEICA_UPDATE_CHECKLIST,
  payload: {...newChecklistData}
});

export const updateLeicaChecklistSuccess = () => ({
  type: LEICA_UPDATE_CHECKLIST_SUCCESS
});

export const updateLeicaChecklistError = (error) => ({
  type: LEICA_UPDATE_CHECKLIST_ERROR,
  payload: error,
});

export const updateLeicaSonderraumChecklist = (newChecklistData) => ({
  type: LEICA_UPDATE_SONDERRAUM_CHECKLIST,
  payload: {...newChecklistData}
});

export const updateLeicaSonderraumChecklistSuccess = () => ({
  type: LEICA_UPDATE_SONDERRAUM_CHECKLIST_SUCCESS
});

export const updateLeicaSonderraumChecklistError = (error) => ({
  type: LEICA_UPDATE_SONDERRAUM_CHECKLIST_ERROR,
  payload: error,
});

export const updateLeicaHoldTime = (holdTimeData) => ({
  type: LEICA_UPDATE_HOLDTIME,
  payload: {...holdTimeData}
});

export const updateLeicaHoldTimeSuccess = () => ({
  type: LEICA_UPDATE_HOLDTIME_SUCCESS
});

export const updateLeicaHoldTimeError = (error) => ({
  type: LEICA_UPDATE_HOLDTIME_ERROR,
  payload: error,
});

export const createLeicaHoldTime = (holdTimeData) => ({
  type: LEICA_CREATE_HOLDTIME,
  payload: {...holdTimeData}
});

export const createLeicaHoldTimeSuccess = () => ({
  type: LEICA_CREATE_HOLDTIME_SUCCESS
});

export const createLeicaHoldTimeError = (error) => ({
  type: LEICA_CREATE_HOLDTIME_ERROR,
  payload: error,
});

export const deleteLeicaHoldTime = (holdTimeData) => ({
  type: LEICA_DELETE_HOLDTIME,
  payload: {...holdTimeData}
});

export const deleteLeicaHoldTimeSuccess = () => ({
  type: LEICA_DELETE_HOLDTIME_SUCCESS
});

export const deleteLeicaHoldTimeError = (error) => ({
  type: LEICA_DELETE_HOLDTIME_ERROR,
  payload: error,
});

export const getLeicaDepartureboardData = () => ({
  type: LEICA_GET_DEPARTUREBOARD_DATA,
});

export const getLeicaDepartureboardDataSuccess = (systemData) => ({
  type: LEICA_GET_DEPARTUREBOARD_DATA_SUCCESS,
  payload: systemData,
});

export const getLeicaDepartureboardDataError = (error) => ({
  type: LEICA_GET_DEPARTUREBOARD_DATA_ERROR,
  payload: error,
});

export const getLeicaSystemLoadout = (systemId) => ({
  type: LEICA_GET_SYSTEMLOADOUT,
  payload: { systemId },
});

export const getLeicaSystemLoadoutSuccess = (systemLoadout) => {
  return {
    type: LEICA_GET_SYSTEMLOADOUT_SUCCESS,
    payload: { systemLoadout },
  };
};

export const getLeicaSystemLoadoutError = (error) => ({
  type: LEICA_GET_SYSTEMLOADOUT_ERROR,
  payload: error,
});

export const getLeicaSystemOverview = (systemId) => ({
  type: LEICA_GET_OVERVIEW,
  payload: { systemId },
});

export const getLeicaSystemOverviewSuccess = (overview) => ({
  type: LEICA_GET_OVERVIEW_SUCCESS,
  payload: { overview },
});

export const getLeicaSystemOverviewError = (error) => ({
  type: LEICA_GET_OVERVIEW_ERROR,
  payload: error,
});

export const getLeicaIssuesById = (issueIdArray) => {
  return {
    type: LEICA_GET_ISSUES_BY_ID,
    payload: { issueIdArray },
  };
};

export const getLeicaIssuesByIdSuccess = (issues) => {
  return { type: LEICA_GET_ISSUES_BY_ID_SUCCESS, payload: { issues } };
};

export const getLeicaIssuesByIdError = (error) => ({
  type: LEICA_GET_ISSUES_BY_ID_ERROR,
  payload: error,
});

export const getLeicaChecklistByStepIds = (stepIdArray) => {
  return {
    type: LEICA_GET_CHECKLIST_BY_STEPIDS,
    payload: { stepIdArray },
  };
};

export const getLeicaChecklistByStepIdsSuccess = (checklist) => {
  return {
    type: LEICA_GET_CHECKLIST_BY_STEPIDS_SUCCESS,
    payload: { checklist },
  };
};

export const getLeicaChecklistByStepIdsError = (error) => {
  return {
    type: LEICA_GET_CHECKLIST_BY_STEPIDS_ERROR,
    payload: { error },
  };
};

export const getLeicaSystemHoldTimes = (holdTimeIdArray, isSonderraum) => {
  return {
    type: LEICA_GET_HOLD_TIMES,
    payload: { holdTimeIdArray, isSonderraum },
  };
};

export const getLeicaSystemHoldTimesSuccess = (holdTimes, isSonderraum) => {
  return {
    type: LEICA_GET_HOLD_TIMES_SUCCESS,
    payload: { holdTimes, isSonderraum },
  };
};

export const getLeicaSystemHoldTimesError = (error, isSonderraum) => {
  return {
    type: LEICA_GET_HOLD_TIMES_ERROR,
    payload: { error, isSonderraum },
  };
};

export const putLeicaResourceAbsenceTimes = ({
  resource,
  newAbsenceTimes,
}) => ({
  type: LEICA_PUT_RESOURCE_ABSENCE_TIMES,
  payload: { resource, newAbsenceTimes },
});

export const putLeicaResourceAbsenceTimesSuccess = (updatedResource) => {
  return {
    type: LEICA_PUT_RESOURCE_ABSENCE_TIMES_SUCCESS,
    payload: updatedResource,
  };
};

export const putLeicaResourceAbsenceTimesError = (error) => ({
  type: LEICA_PUT_RESOURCE_ABSENCE_TIMES_ERROR,
  payload: error,
});

// export const putLeicaHoldTime = ({
//   system,
//   newHoldTime,
//   existingHoldTimeId,
//   overrideExisting,
// }) => ({
//   type: LEICA_PUT_HOLD_TIME,
//   payload: { system, newHoldTime, existingHoldTimeId, overrideExisting },
// });

// export const putLeicaHoldTimeSuccess = (data) => {
//   return {
//     type: LEICA_PUT_HOLD_TIME_SUCCESS,
//     payload: data,
//   };
// };

// export const putLeicaHoldTimeError = (error) => ({
//   type: LEICA_PUT_HOLD_TIME_ERROR,
//   payload: error,
// });

// export const deleteLeicaHoldTime = ({ system, holdTimeId }) => ({
//   type: LEICA_DELETE_HOLD_TIME,
//   payload: { system, holdTimeId },
// });

// export const deleteLeicaHoldTimeSuccess = (data) => ({
//   type: LEICA_DELETE_HOLD_TIME_SUCCESS,
//   payload: data,
// });

// export const deleteLeicaHoldTimeError = (error) => ({
//   type: LEICA_DELETE_HOLD_TIME_ERROR,
//   payload: error,
// });

export const putLeicaSondernummern = (numbers, checklistId) => ({
  type: LEICA_PUT_SONDERNUMMERN,
  payload: { numbers, checklistId },
});

export const putLeicaSondernummernSuccess = () => ({
  type: LEICA_PUT_SONDERNUMMERN_SUCCESS,
});

export const putLeicaSondernummernError = (error) => {
  return {
    type: LEICA_PUT_SONDERNUMMERN_ERROR,
    payload: error,
  };
};

export const autoscheduleNewLeicaSystem = (systemNumber) => ({
  type: LEICA_AUTOSCHEDULE_NEW_SYSTEM,
  payload: systemNumber,
});

export const autoscheduleNewLeicaSystemSuccess = () => ({
  type: LEICA_AUTOSCHEDULE_NEW_SYSTEM_SUCCESS,
});

export const autoscheduleNewLeicaSystemError = (error) => ({
  type: LEICA_AUTOSCHEDULE_NEW_SYSTEM_ERROR,
  payload: error,
});

export const rescheduleLeicaSystems = (systems, sonderraumSystems) => ({
  type: LEICA_RESCHEDULE_SYSTEMS,
  payload: { systems, sonderraumSystems },
});

export const rescheduleLeicaSystemsSuccess = () => ({
  type: LEICA_RESCHEDULE_SYSTEMS_SUCCESS,
});

export const rescheduleLeicaSystemsError = (error) => ({
  type: LEICA_RESCHEDULE_SYSTEMS_ERROR,
  payload: error,
});

export const autoOrganizeSystemsOnFactoryboard = (systemData) => ({
  type: LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD,
  payload: systemData,
});

export const autoOrganizeSystemsOnFactoryboardSuccess = () => ({
  type: LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_SUCCESS,
});

export const autoOrganizeSystemsOnFactoryboardError = (error) => ({
  type: LEICA_AUTOORGANIZE_SYSTEMS_ON_FACTORYBOARD_ERROR,
  payload: error,
});

export const setLeicaStepComplete = (systemId, systemStatus) => ({
  type: LEICA_SET_STEPCOMPLETE,
  payload: { systemId, systemStatus },
});

export const setLeicaStepCompleteSuccess = (payload) => {
  return {
    type: LEICA_SET_STEPCOMPLETE_SUCCESS,
    payload: {
      ...payload,
    },
  };
};

export const setLeicaStepCompleteError = (error) => ({
  type: LEICA_SET_STEPCOMPLETE_ERROR,
  payload: error,
});

export const toggleLeicaWorkStatus = (
  checklistId,
  sonderraumChecklistId,
  systemStatus
) => ({
  type: LEICA_TOGGLE_WORKSTATUS,
  payload: { checklistId, sonderraumChecklistId, systemStatus },
});

export const toggleLeicaWorkStatusSuccess = (workStatus) => {
  return {
    type: LEICA_TOGGLE_WORKSTATUS_SUCCESS,
    payload: { workStatus },
  };
};

export const toggleLeicaWorkStatusError = (error) => ({
  type: LEICA_TOGGLE_WORKSTATUS_ERROR,
  payload: error,
});

export const putLeicaIssue = (issueData) => ({
  type: LEICA_PUT_ISSUE,
  payload: { issueData },
});

export const putLeicaIssueSuccess = (workStatus) => {
  return {
    type: LEICA_PUT_ISSUE_SUCCESS,
    payload: { workStatus },
  };
};

export const putLeicaIssueError = (error) => ({
  type: LEICA_PUT_ISSUE_ERROR,
  payload: error,
});

export const deleteLeicaSystem = (systemId) => ({
  type: LEICA_DELETE_SYSTEM,
  payload: { systemId },
});

export const deleteLeicaSystemSuccess = (systemId) => ({
  type: LEICA_DELETE_SYSTEM_SUCCESS,
  payload: { systemId },
});

export const deleteLeicaSystemError = (error) => ({
  type: LEICA_DELETE_SYSTEM_ERROR,
  payload: error,
});

export const submitExcelWithMappedHeaders = (formdata) => ({
  type: LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS,
  payload: { formdata },
});

export const submitExcelWithMappedHeadersSuccess = (response) => ({
  type: LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_SUCCESS,
  payload: { response },
});

export const submitExcelWithMappedHeadersError = (error) => ({
  type: LEICA_SUBMIT_EXCEL_WITH_MAPPED_HEADERS_ERROR,
  payload: error,
});

export const submitNewLeicaSystem = (postdata) => ({
  type: LEICA_SUBMIT_NEW_SYSTEM,
  payload: { postdata },
});

export const submitNewLeicaSystemSuccess = (sondernummern) => ({
  type: LEICA_SUBMIT_NEW_SYSTEM_SUCCESS,
  payload: { sondernummern },
});

export const submitNewLeicaSystemError = (error) => ({
  type: LEICA_SUBMIT_NEW_SYSTEM_ERROR,
  payload: { error },
});

export const updateLeicaSystemConfig = (data) => ({
  type: LEICA_UPDATE_SYSTEM_CONFIG,
  payload: { data },
});

export const updateLeicaSystemConfigSuccess = () => ({
  type: LEICA_UPDATE_SYSTEM_CONFIG_SUCCESS,
  payload: {},
});

export const updateLeicaSystemConfigError = (error) => ({
  type: LEICA_UPDATE_SYSTEM_CONFIG_ERROR,
  payload: { error },
});

export const getLeicaDowntimeKPIs = () => ({
  type: LEICA_GET_DOWNTIMEKPI,
});

export const getLeicaDowntimeKPIsSuccess = (issueKPI) => ({
  type: LEICA_GET_DOWNTIMEKPI_SUCCESS,
  payload: issueKPI,
});

export const getLeicaDowntimeKPIsError = (error) => ({
  type: LEICA_GET_DOWNTIMEKPI_ERROR,
  payload: error,
});
