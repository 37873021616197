const leicaServiceBaseUrl = process.env.REACT_APP_LEICA_SERVICE_BASE_URL;
const leicaServicePort = process.env.REACT_APP_LEICA_SERVICE_BASE_PORT;

if (!leicaServiceBaseUrl) {
  throw Error("No Leica Service endpoint provided.");
}

if (!leicaServicePort) {
  console.warn("No port defined for Leica Service backend.");
}

const baseUrlApi = leicaServicePort
  ? `${leicaServiceBaseUrl}:${leicaServicePort}`
  : `${leicaServiceBaseUrl}`;
const baseUrl = leicaServicePort ? `${leicaServiceBaseUrl}:${leicaServicePort}` : leicaServiceBaseUrl;

/**
 * Append path to currently used backend.
 * @param path
 * @returns {string}
 */
export function genPath(path) {
  return `${baseUrl}/${path}`;
}

/**
 * Return base backend path without port.
 */
export function getBaseUrl() {
  return baseUrlApi;
}

function obtainAuthToken() {
  const token = localStorage.getItem("access_token");

  return {
    Authorization: `Bearer ${token}`,
  };
}

/**
 * This method is used for session checks (user authentication)
 * and will refresh 'access_token', if the user is not authenticated.
 */
async function renewSessionIfRequired(originalRequest, res) {
  if (res.status === 401) {
    const newTokens = await postData(
      "/users/refresh",
      { refresh_token: localStorage.getItem("refresh_token") },
      true
    ).then((refreshRes) => {
      if (refreshRes.status === 200) {
        return refreshRes.json();
      } else {
        console.log("Could not authorize.");
      }
    });

    const newRefresh = await fetch(originalRequest);
  } else {
    return res;
  }
}

export function getData(path, opts) {
  const getHeaders = {
    "Content-Type": "application/json",
  };

  let url = `${baseUrlApi}${path}`;
  let noAuth = false;
  let headers = Object.assign(getHeaders, {}, obtainAuthToken());

  if (opts) {
    if (opts.base === "raw") {
      url = `${baseUrl}${path}`;
    }

    if (opts.noAuth) {
      noAuth = opts.noAuth;
    }

    if (opts.token) {
      headers["Authorization"] = "Bearer " + opts.token;
    }
  }

  const originalRequest = new Request(url, {
    method: "GET",
    mode: "cors",
    headers: headers,
  });

  return fetch(originalRequest).then(
    renewSessionIfRequired.bind(this, originalRequest)
  );
}

/**
 * Send POST request to api.
 * @param path
 * @param data
 * @param opts
 * @returns {Promise<Response>}
 */
export function postData(path, data, opts) {
  let url = `${baseUrlApi}${path}`;
  let noAuth = false;
  let isFormData = false;
  let postHeaders = new Object();

  if (opts) {
    if (opts.base === "raw") {
      url = `${baseUrl}${path}`;
    }

    if (opts.noAuth) {
      noAuth = opts.noAuth;
    }

    if (opts.isFormData) {
      isFormData = opts.isFormData;
    }
  }

  // headers configuration
  if (!isFormData) {
    postHeaders = {
      "Content-Type": "application/json",
    };
  }
  if (!noAuth) {
    postHeaders = Object.assign(postHeaders, {}, obtainAuthToken())
  }

  let config = {
    method: "POST",
    cors: "no-cors",
    body: isFormData ? data : JSON.stringify(data),
    headers: postHeaders
  }
  
  return fetch(url, config);
}

export function putData(path, data, noAuth) {
  const url = `${baseUrlApi}${path}`;

  const postHeaders = {
    "Content-Type": "application/json",
  };

  return fetch(url, {
    method: "PUT",
    cors: "no-cors",
    headers: noAuth
      ? postHeaders
      : Object.assign(postHeaders, {}, obtainAuthToken()),
    body: JSON.stringify(data),
  });
}

export function httpDelete(path, data, noAuth) {
  const url = `${baseUrlApi}${path}`;

  const deleteHeaders = {
    "Content-Type": "application/json",
  };

  return fetch(url, {
    method: "DELETE",
    cors: "no-cors",
    headers: noAuth
      ? deleteHeaders
      : Object.assign(deleteHeaders, {}, obtainAuthToken()),
    body: JSON.stringify(data),
  });
}

export function postForm(path, mimeType, formData) {
  const url = `${baseUrlApi}${path}`;

  const postFormHeaders = {
    // 'Content-Type': mimeType
  };

  if (!(formData instanceof FormData)) {
    return new Promise(() => {
      throw "postForm expects data of type 'FormData'";
    });
  }

  return fetch(url, {
    method: "POST",
    cors: "no-cors",
    headers: Object.assign(postFormHeaders, {}, obtainAuthToken()),
    body: formData,
  });
}

export function postMultipart(path, data) {
  const url = `${baseUrlApi}${path}`;

  return fetch(url, {
    method: "POST",
    cors: "cors",
    headers: Object.assign({}, {}, obtainAuthToken()),
    body: data,
  });
}

export function deleteData(path, opts) {
  let url = `${baseUrlApi}${path}`;
  let headers = Object.assign({}, obtainAuthToken());

  if (opts) {
    if (opts.base === "raw") {
      url = `${baseUrl}${path}`;
    }
  }

  const originalRequest = new Request(url, {
    method: "DELETE",
    mode: "cors",
    headers: headers,
  });

  return fetch(originalRequest).then(
    renewSessionIfRequired.bind(this, originalRequest)
  );
}
