const HoldDatesTemplate = {
  instanceId: "",
  templateId: "300c7bda0d7e42bfa59adcdc780aa6b8",
  imageUrl: "http://159.69.37.15:5010/db/static/assets/uploads/placeholder.jpg",
  title: "HoldDates",
  identifier: "Startdate",
  text: "Startdate",
  properties: [
    {
      propertyId: "300c7bda0d7e42bfa59adcdc780aa6b8",
      propertyName: "Startdate",
      propertyValue: "",
      dataType: "DATE",
      formula: "",
      displayName: "",
      index: "",
      accessMode: "automaticw",
    },
    {
      propertyId: "300c7bda0d7e42bfa59adcdc780aa6b8",
      propertyName: "Enddate",
      propertyValue: "",
      dataType: "DATE",
      formula: "",
      displayName: "",
      index: "",
      accessMode: "automaticw",
    },
    {
      propertyId: "f274e75f95ca469cb00e8285a368927e",
      propertyName: "ResourceDuringHold",
      propertyValue: "",
      dataType: "SINGLE_1c10a7e19ce14d1fbaf3ae93ff0cec56",
      formula: "",
      displayName: "",
      index: "",
      accessMode: "automaticw"
    },
    {
      propertyId: "d8b3f2c5d81c4f92aea074f5780c923f",
      propertyName: "ResourceAfterHold",
      propertyValue: "",
      dataType: "SINGLE_1c10a7e19ce14d1fbaf3ae93ff0cec56",
      formula: "",
      displayName: "",
      index: "",
      accessMode: "automaticw"
    },
  ],
};
export default HoldDatesTemplate;
