import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import leica from "./leica/reducer";

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  leica,
});

export default reducers;
