import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  GET_USERLIST,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_ERROR,
  DELETE_USER_BY_ID,
  DELETE_USER_BY_ID_SUCCESS,
  DELETE_USER_BY_ID_ERROR,
} from "../actions";

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history },
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: { user },
});
export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});
export const updateUserError = (message) => ({
  type: UPDATE_USER_ERROR,
  payload: { message },
});

export const updatePassword = (currentpassword, newpassword) => ({
  type: UPDATE_PASSWORD,
  payload: { currentpassword, newpassword },
});
export const updatePasswordSuccess = (message) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: { message },
});
export const updatePasswordError = (message) => ({
  type: UPDATE_PASSWORD_ERROR,
  payload: { message },
});
export const getUserList = () => ({
  type: GET_USERLIST,
  payload: {},
});
export const getUserListSuccess = (userList) => ({
  type: GET_USERLIST_SUCCESS,
  payload: [...userList],
});
export const getUserListError = (message) => ({
  type: GET_USERLIST_ERROR,
  payload: { message },
});

export const deleteUser = (userId) => ({
  type: DELETE_USER_BY_ID,
  payload: { userId },
});
export const deleteUserSuccess = (id) => ({
  type: DELETE_USER_BY_ID_SUCCESS,
  payload: { id },
});
export const deleteUserError = (message) => ({
  type: DELETE_USER_BY_ID_ERROR,
  payload: { message },
});
