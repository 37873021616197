import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const ListSystems = React.lazy(() =>
  import(/* webpackChunkName: "start" */ "./listsystemsShopfloor")
);
const Checklist = React.lazy(() =>
  import(/* webpackChunkName: "start" */ "./checklist")
);
const leica = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/listsystems`} />
      <Route
        path={`${match.url}/listsystems`}
        render={(props) => <ListSystems {...props} />}
      />
      <Route
        path={`${match.url}/checklist/:LeicaSystemNr`}
        render={(props) => <Checklist {...props} />}
        isExact
      />
      <Redirect to="/error" />
    </Switch>
  </Suspense>
);
export default leica;
